.avatar {
  /* height: 13rem; */
  margin: 0px;
  object-fit: cover;
}

.avatar img {
  border-radius: 10px 10px 0px 0px;
}

.card {
  cursor: pointer;
  margin: 0px;
  padding-bottom: 4px;
  /* width: 20rem;
  height: 21rem; */
}

.card .shadowSm {
  border-radius: 8px;
  box-shadow: 0 0 6px #0000001f !important;
}

.cardTitle {
  font-family: 'new_york_extra_largeregular';
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  color: #8a937f;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.cardText {
  font-size: 1rem;
  font-weight: 300;
  margin-left: 0.8em;
  color: #b25661;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 375px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.colStyle {
  color: var(--text-secondary, #3b8396);
  vertical-align: middle;
  padding-bottom: 0.5rem;
}

.rowDisplay {
  padding-bottom: 10px;
  /* display: flex; */
  /* justify-content: flex-start; */
}

.userDateDiv {
  justify-content: space-between;
  margin-left: 0.8em;
  margin-right: 0.8em;
  margin-top: 10px;
}

.userDateDiv .user {
  width: 100%;
  height: 40px;
  justify-content: space-between;
}

.userDateDiv .userImg {
  display: flex;
}

.userDateDiv .userImg img {
  width: 32px;
  height: 32px;
  border-radius: 100px;
}

.userDateDiv .userImg .userTitle {
  font-family: 'sf_pro_displayregular';
  margin-left: 8px;
  margin-top: 4px;
  font-size: 16px;
  color: #000000;
  opacity: 0.4;
}

.userDate {
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
  font-weight: 300;
  margin-top: 4px;
  color: #838d78;
  text-align: right;
}

@media (max-width: 420px) {
  .rowDisplay {
    display: flex;
    justify-content: center;
  }

  .cardText {
    font-size: 14px;
  }

  .userTitle {
    font-size: 16px !important;
  }
  .userDate {
    font-size: 12px;
    margin-top: 6px;
  }
}
