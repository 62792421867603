.horizontalCollectionCard {
  position: relative;
  cursor: pointer;
  /* padding-right: 10px; */
}

.horizontalCollectionCard .icon {
  object-fit: cover;
}

.horizontalCollectionCardtitlewinebymoments {
  position: absolute;
  bottom: 0.4em;
  width: 80%;
  height: 2em;
  margin: 0 10%;
  text-align: center;
  font: normal bold normal 20px/24px SF Pro Display;
  line-height: 2em;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.75 !important;
  color: #000;
  text-transform: uppercase;
  font-size: 16px !important;
}
