@mixin carousel-item-sommlike-picks-collection-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin carousel-item-sommlike-picks-collection-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin carousel-item-sommlike-picks-collection-md {
  @media screen and (max-width: 1399px) {
    @content;
  }
}
@mixin carousel-item-sommlike-picks-collection-lg {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

.carousel-item-sommlike-picks-collection-skeleton {
  @include carousel-item-sommlike-picks-collection-lg {
    width: 230px;
    margin-right: 24px;
  }
  @include carousel-item-sommlike-picks-collection-md {
    width: 210px;
    margin-right: 24px;
  }
  @include carousel-item-sommlike-picks-collection-sm {
    width: 180px;
    margin-right: 24px;
  }
  @include carousel-item-sommlike-picks-collection-sx {
    width: 160px;
    margin-right: 24px;
  }
}
