.wineTipsCollection {
  padding: 0px;
  margin: 10px !important;
}

.wineTipsCollection .header {
  font-family: 'new_york_extra_largemedium';
  font-size: 36px !important;
  color: #838d78;
  margin-top: 48px;
}

.wineTipsCollection .createBlog {
  background-color: #8a937f;
  color: #ffffff;
  margin-top: 48px;
  width: 150px;
}

@media (max-width: 760px) {
  .wineTipsCollection .header {
    font-size: 24px;
    margin-top: 24px;
  }
  .wineTipsCollection .createBlog {
    width: 120px;
    height: 50px;
    margin-top: 24px;
  }
}

@media (max-width: 576px) {
  .wineTipsCollection .headerrowSize {
    font-size: 18px;
    margin: 20px 0px;
  }
  .wineTipsCollection .createBlog {
    width: 100px;
    height: 40px;
    margin: 20px 0px;
  }
}
