.edit-img {
  color: #b25661;
  margin-top: 15px;
  position: absolute;
  z-index: 999999;
  right: 15px;
  background-color: aliceblue;
  width: max-content;
  height: max-content;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.selectedRegion {
  font-size: 16px;
  padding: 8px 16px;
  height: 40px;
  background-color: rgb(0, 0, 0, 0.3);
  border-radius: 20px;
  margin-right: 8px;
}

.common-form button.btn-primary {
  // height: 80px !important;
}

.editProfile input {
  color: #000 !important;
}

.errorMessage{
  width: 100%;
  font-size: 14px;
  line-height: 25px;
  max-height: 60px;
  color: red;
  text-align: center;
  margin-top: 18px;
}

@media (max-width: 820px) {
  .common-form label {
    font-size: 16px !important;
  }
  .editProfile input {
    height: 60px !important;
    font-size: 16px !important;
  }
  .common-form button.btn-primary {
    // height: 60px !important;
  }

  .editProfile textarea {
    font-size: 16px !important;
  }
}

@media (max-width: 480px) {
  .common-form label {
    font-size: 14px !important;
  }
  .editProfile input {
    height: 48px !important;
    font-size: 14px !important;
  }
  .common-form button.btn-primary {
    // height: 48px !important;
  }

  .editProfile textarea {
    font-size: 14px !important;
  }
}
