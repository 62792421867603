.btn-primary,
.btn.gmail:hover {
  color: #fff !important;
}

.forgetPassword {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: #b25661;
  cursor: pointer;
}
.borderline {
  border: 1px solid #f4efef;
  width: 100%;
  position: absolute;
  left: 0px;
}
.forget-description {
  font-size: 14px !important;
  color: black !important;
}
.forget-email {
  font-size: 16px !important;
  margin-bottom: 10px !important;
}
.errorMessage {
  font-size: 14px !important;
  color: #b25661 !important;
  font-weight: 600;
  text-align: left;
  margin: 0;
}

.successMessage {
  font-size: 14px !important;
  color: rgb(20 138 20) !important;
  font-weight: 600;
  text-align: left;
  margin: 0;
}
