@mixin card-wine-by-terroir-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin card-wine-by-terroir-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin card-wine-by-terroir-md {
  @media screen and (max-width: 1399px) {
    @content;
  }
}
@mixin card-wine-by-terroir-lg {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

@mixin card-img-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin card-img-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin card-img-md {
  @media screen and (max-width: 1399px) {
    @content;
  }
}
@mixin card-img-lg {
  @media screen and (min-width: 1400px) {
    @content;
  }
}
.card-wine-by-terroir {
  @include card-wine-by-terroir-lg {
    width: 198px !important;
  }
  @include card-wine-by-terroir-md {
    width: 198px !important;
  }
  @include card-wine-by-terroir-sm {
    width: 198px !important;
  }
  @include card-wine-by-terroir-sx {
    width: 180px !important;
  }
}

.card-wine-by-terroir-skeleton {
  @include card-wine-by-terroir-lg {
    width: 198px !important;
  }
  @include card-wine-by-terroir-md {
    width: 198px !important;
  }
  @include card-wine-by-terroir-sm {
    width: 198px !important;
  }
  @include card-wine-by-terroir-sx {
    width: 180px !important;
  }
}

.card-wine-by-terroir {
  .icon {
    @include card-img-lg {
      width: 198px !important;
      height: 170px !important;
      border-radius: 8px;
    }
    @include card-img-md {
      width: 198px !important;
      height: 170px !important;
      border-radius: 8px;
    }
    @include card-img-sm {
      width: 198px !important;
      height: 170px !important;
      border-radius: 8px;
    }
    @include card-img-sx {
      width: 180px !important;
      height: 160px !important;
      border-radius: 8px;
    }
  }
}
