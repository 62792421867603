.credit-card-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .credit-card-row {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    margin: 20px;
    border-radius: 5px;
    background-color: #fff;
    width: 300px;
    @media (min-width: 768px) {
      width: 400px; // Increase the width to 300px for screens wider than 768px (desktop)
    }
  }
  
  .credit-card-icon img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .card-number {
    flex-grow: 1;
    color: black;
  }
  .icon-span {
    align-self: center;
    padding: 0 9px;
  }