.sommelier-page {
  .nav-item {
    font-size: 20px;
    width: max-content !important;
  }

  .react-multiple-carousel__arrow {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px;
    min-height: 30px;
  }

  .react-multiple-carousel__arrow::before {
    font-size: 10px !important;
  }

  .som-tabs {
    .react-multi-carousel-list {
      // border-bottom: 1px solid #e6e6e6;
    }

    .react-multi-carousel-item {
      width: max-content !important;
    }
  }

  .bottom-border {
    border-bottom: 1px solid #e6e6e6;
  }
}

.sommelier-details-v1 .p-box .profile-box {
  position: absolute;
  z-index: 9;
  width: 100%;
}

.sommelier-details-v1 {
  .p-box-img img {
    width: 100%;
    // height: 300px;
    object-fit: cover;
  }
}

.chip-content {
  color: #212529;
  // background-color: #f8f9fa;
  // border-color: #f8f9fa;
  border: 1px solid var(--unnamed-color-0000000d);
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #0000000d;
  border-radius: 8px;
  opacity: 1;
}

.likeButton {
  width: 140px;
  height: 48px;
  margin-left: auto;
  text-align: center;
  background-color: #B25661;
  color: #fff;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
}

.likeButton h5 {
  font-family: 'sf_pro_displayregular';
}

.unLikeButton {
  width: 140px;
  height: 48px;
  margin-left: auto;
  text-align: center;
  background-color: rgb(0, 0, 0, 0.2);
  color: #000;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .likeButton {
    margin: 0;
  }

  .unLikeButton {
    margin: 0;
  }

  .follower-wine-count {
    padding: 0 !important;
  }
}

.unLikeButton h5 {
  font-family: 'sf_pro_displayregular';
}

@mixin cover-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin cover-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

@mixin cover-md {
  @media screen and (min-width: 1001px) {
    @content;
  }
}

@mixin cover-lg {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

.sommelier-details-v1 {
  .p-box-img .cover-img {
    width: 100%;
    object-fit: cover;

    @include cover-lg {
      height: 400px !important;
    }

    @include cover-md {
      height: 340px !important;
    }

    @include cover-sm {
      height: 240px !important;
    }

    @include cover-sx {
      height: 180px !important;
    }
  }

  .profile-box {
    @include cover-lg {
      height: 150px !important;

      bottom: -80px;
    }

    @include cover-md {
      height: 150px !important;
      bottom: -80px;
    }

    @include cover-sm {
      height: 150px !important;
      bottom: -80px;
    }

    @include cover-sx {
      height: 100px !important;
      bottom: -55px;
    }

    img {
      border-radius: 50%;

      @include cover-lg {
        height: 150px !important;
        width: 150px !important;
        min-width: 150px;
      }

      @include cover-md {
        height: 150px !important;
        width: 150px !important;
        min-width: 150px;
      }

      @include cover-sm {
        height: 150px !important;
        width: 150px !important;
        min-width: 150px;
      }

      @include cover-sx {
        height: 100px !important;
        width: 100px !important;
        min-width: 100px;
      }
    }
  }

  .empty-div-som {
    @include cover-lg {
      min-width: 165px;
    }

    @include cover-md {
      min-width: 165px;
    }

    @include cover-sm {
      min-width: 0px;
    }

    @include cover-sx {
      min-width: 0px;
    }
  }

  .profile-one-line-mobile {
    @include cover-lg {
      display: none;
    }

    @include cover-md {
      display: none;
    }

    @include cover-sm {
      display: block;
    }

    @include cover-sx {
      min-width: block;
    }
  }

  .profile-one-line-desktop {
    @include cover-lg {
      display: block;
    }

    @include cover-md {
      display: block;
    }

    @include cover-sm {
      display: none;
    }

    @include cover-sx {
      min-width: none;
    }
  }

  .follower-wine-count {
    padding-left: 4vw;
    padding-right: 1vw;

    @include cover-lg {
      margin-top: 120px;
    }

    @include cover-md {
      margin-top: 120px;
    }

    @include cover-sm {
      justify-content: center;
      margin-top: 75px;
    }

    @include cover-sx {
      justify-content: center;
      margin-top: 75px;
    }
  }
}

// skeleton

.cover-skeleton {
  @include cover-lg {
    height: 400px !important;
  }

  @include cover-md {
    height: 340px !important;
  }

  @include cover-sm {
    height: 240px !important;
  }

  @include cover-sx {
    height: 180px !important;
  }
}


.sommelier-page {
  .container-list-main {
    .__arrow__prev {
      left: 0px !important;
    }

    .__arrow__next {
      right: 0px !important;
    }

    button {
      margin: 0px !important;
    }
  }
}