.login-checkbox {
  width: max-content;
  //   margin: 15px auto;
  position: relative;
  display: block;
}

.login-checkbox input[type='checkbox'] {
  width: -webkit-fill-available;
  top: 5px;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  height: 24px;
  z-index: 99;
  cursor: pointer;
}
.login-checkbox label {
  position: relative;
}
.login-checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 22px;
  height: 22px;
  transition: transform 0.28s ease;
  border-radius: 3px;
  border: 2px solid #b25661;
}
.login-checkbox label:after {
  content: '';
  display: block;
  width: 10px;
  height: 5px;
  border-bottom: 2px solid #b25661;
  border-left: 2px solid #b25661;
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0.25s;
  will-change: transform;
  position: absolute;
  top: 12px;
  left: 10px;
}
.login-checkbox input[type='checkbox']:checked ~ label::before {
  color: #b25661;
}

.login-checkbox input[type='checkbox']:checked ~ label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.login-checkbox label {
  min-height: 34px;
  display: block;
  padding-left: 40px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  vertical-align: sub;
}
.login-checkbox label span {
  //   position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.login-checkbox input[type='checkbox']:focus + label::before {
  outline: 0;
}
.btn-primary,
.btn.gmail:hover {
  color: #fff !important;
}

.errorMessage {
  text-align: 'center';
  color: '#ff0000';
  margin: 16px 0px;
}

.forgetPassword {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: #b25661;
  cursor: pointer;
}
