@import 'icon-variables';

@font-face {
  font-family: '#{$somicon-font-family}';
  src: url('#{$somicon-font-path}/#{$somicon-font-family}.eot?ku2ymq');
  src: url('#{$somicon-font-path}/#{$somicon-font-family}.eot?ku2ymq#iefix') format('embedded-opentype'),
    url('#{$somicon-font-path}/#{$somicon-font-family}.ttf?ku2ymq') format('truetype'),
    url('#{$somicon-font-path}/#{$somicon-font-family}.woff?ku2ymq') format('woff'),
    url('#{$somicon-font-path}/#{$somicon-font-family}.svg?ku2ymq##{$somicon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i.sommicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$somicon-font-family}' !important;
  speak: never !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1 !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-size: 18px;
  color: #000 !important;
}
@media screen and (max-width: 768px) {
  i.sommicon {
    font-size: 16px;
  }
}

.icon-shopping-cart {
  &:before {
    content: $icon-shopping-cart;
  }
}
.icon-heart-o {
  &:before {
    content: $icon-heart-o;
  }
}
.icon-notifications {
  &:before {
    content: $icon-notifications;
  }
}
.icon-profile {
  &:before {
    content: $icon-profile;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
