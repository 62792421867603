.card-box {
  flex-direction: column;
  width: 239px;
  height: 65px;
  border-radius: 7px;
  border: 2px solid #dbdbdb;
  display: flex;
  box-shadow: 2px 2px 7px #dadada;
  padding: 1vw;
  margin: 0.2vw;
}
.ele {
  display: flex;
  // justify-content: space-around;
  flex-wrap: wrap;
  background: white;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 9999999;
  overflow: hidden;
  // padding-top: 13vh;
}
