.explore h1 {
  font-size: 36px !important;
  /* margin: 20px 0px !important; */
  color: #838d78 !important;
  font-weight: bold;
}

.rowSize {
  width: 100%;
  height: 100%;
  margin: auto;
}

.rowSize img {
  width: 100%;
  max-height: 550px;
  align-items: center;
  border-radius: 20px 0px 0px 20px !important;
}

.exploreCard {
  padding: 0px !important;
}

.sommelier {
  width: 100%;
  height: 100%;
}

.sommelier img {
  /* height: 320px; */
  object-fit: cover !important;
  border-radius: 8px;
}

.title {
  font-family: 'sf_pro_displaymedium';
  font-size: 20px;
  color: #000 !important;
}

.text {
  font-family: 'sf_pro_displayregular';
  font-size: 14px;
  color: rgb(0, 0, 0, 0.4) !important;
  margin: 0 0 8px !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .explore h1 {
    font-size: 30px !important;
  }

  .rowSize {
    margin: 0px 10px;
  }
  .rowSize img {
    max-height: 550px;
    align-items: center;
    border-radius: 5px 0px 0px 5px !important;
  }
  .sommelier img {
    /* height: 180px; */
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .rowSize img {
    max-height: 550px;
    align-items: center;
    border-radius: 10px 0px 0px 10px !important;
  }
  .sommelier img {
    /* height: 320px; */
  }
}

@media only screen and (min-width: 1224px) {
  /* Styles */
}

@media only screen and (min-width: 1824px) {
  /* Styles */
}
