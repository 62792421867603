.aboutUs p {
  white-space: pre-line;
}
.aboutUs {
  h2 {
    color: #838d78;
  }
}
.text-color {
  color: #838d78;
}
