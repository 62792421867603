.feedDetailCard {
  border-radius: 8px;
}

.feedDetailInside {
  padding: 0px;
}

.feedDetailInside h5.cardTitle {
  color: #000000;
  font-size: 22px;
  font-family: 'new_york_extra_largemedium';
  margin-bottom: 15px;
  margin-top: 30px;
}

.feedDetailInside p.cardText {
  font-family: 'sf_pro_displaymedium';
  color: #000000;
  line-height: 18px;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.feedDetailInsideBanner {
  height: 280px;
  text-align: center;
  background: #f6f0e2;
  overflow: hidden;
  align-items: center;
}

.feedDetailInsideBannerSelect {
  width: 100%;
  object-fit: cover;
  height: 280px;
  text-align: center;
  background: #f6f0e2;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
}

.feedDetailInsideBanner img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.feedDetailInside .submitButton {
  background-color: #8a937f;
  color: #ffffff;
  padding: 16px;
  height: 60px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.feedDetailInside .success {
  margin-bottom: 20px;
  color: green;
  text-align: center;
  font-size: 20px;
}

@media (max-width: 760px) {
  .feedDetailInside {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 576px) {
  .feedDetailInside {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.imguploadbutton {
  position: absolute;
  display: none;
  top: 0;

  cursor: pointer;
  width: 100%;
  height: inherit;
  align-content: center;
  background: #000000b1;
}
.imguploadbutton span {
  color: #ffffff;
  font-size: 20px;
  font-family: 'new_york_medium';
  display: block;
}
.imguploadbutton i {
  color: #ffffff;
}

.onhovercoverPicture:hover .imguploadbutton {
  display: grid;
}
