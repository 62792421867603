/***** My address Css  ****/

.custViewAddress h2{
    color:#000;
    
  }
  
.custViewAddress h3{
  
    font-size: 18px !important;
    padding-top: 15px;
  }
  .custViewAddress p{
    font-size: 18px !important;
    opacity: 0.9;
  }

  .custViewAddress span{
    opacity: 0.9;
  }
  .custViewAddress span svg{
        font-size: 26px;
  }
  .add-new{
    color: #F0C787;
    cursor: pointer;
    font-weight: bold;
  }
  

  .my-address{
    width: 100%;
    display: flex;
    border-bottom: 0px;
    margin-top: 20px;
  }
  .my-address .nav-item{
    padding: 0 0;
    width: 50% !important;
    text-align: center;
  }
  .my-address .nav-link{
    /* width: 100%; */
    padding: 14px 0;
    font-size: 18px;
    color: #000;
    font-weight: bold;
    border: 1px solid #e7e7e7;
    border-radius: 2px;
    box-shadow: none;
    background-color: #fff;
    opacity: .4;
    padding: 0 0;
    width: 100% !important;
    text-align: center;
  }

  .loding_spinner {
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 150px !important;
    flex-direction: column;
    text-align: center;
    height: 150px !important;
    position: absolute;
    top: 50%;
    border-radius: 50%;
    color: #fff;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999 ;
  }
  
  @keyframes ckw {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

  .gear {
    color: #000;
    width: 50px;
    height: 50px;
    animation-name: ckw;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  .my-address  .nav-link.active{
    background-color: #B25661 !important;
    border: 1px solid #B25661 !important;
    color: #fff;
    opacity: 1 !important;
  }
  