/******* font-face start ********/

@font-face {
  font-family: 'new_york_extra_largemedium';
  src: url('././assets/fonts/newyorkextralargemedium-webfont.woff2') format('woff2'),
    url('././assets/fonts/newyorkextralargemedium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'new_york_extra_largeregular';
  src: url('././assets/fonts/newyorkextralargeregular-webfont.woff2') format('woff2'),
    url('././assets/fonts/newyorkextralargeregular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sf_pro_displaymedium';
  src: url('././assets/fonts/sfprodisplay-medium-webfont.woff2') format('woff2'),
    url('././assets/fonts/sfprodisplay-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sf_pro_displaysemibold';
  src: url('././assets/fonts/sfprodisplay-semibold-webfont.woff2') format('woff2'),
    url('././assets/fonts/sfprodisplay-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sf_pro_displayregular';
  src: url('././assets/fonts/sfprodisplay-regular-webfont.woff2') format('woff2'),
    url('././assets/fonts/sfprodisplay-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sf_pro_text_light';
  src: url('././assets/fonts/sf-pro-text-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
* {
  -webkit-font-smoothing: antialiased;
}
