.avatar {
  /* width: 420px; */
  height: 460px;
  object-fit: cover;
  align-items: center;
  margin: auto;
  display: block;
}

.avatar img {
  border-radius: 10px;
}

.rowDisplay {
  display: block;
}

.tablerounededCorner {
  /* width: 420px; */
  background-color: #fafafa;
  border-radius: 0.8em;
  border: 0.5px solid #dee2e6;
  margin: auto;
  /* margin-top: 20px; */
  border-top: none;
}

.tablerounededCorner .tableTitle {
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}

.tablerounededCorner .tableText {
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
  color: #000000;
}

.cardTitle h2 {
  font-family: 'sf_pro_displayregular';
  font-size: 24px;
  color: #000000;
}

.cardTitle p {
  font-family: 'sf_pro_displayregular';
  color: #b25661 !important;
  /* margin: 10px 0px; */
}

.discountOff {
  font-family: 'sf_pro_displayregular';
  color: #b25661 !important;
  /* margin: 10px 0px; */
}
.cardPrice h2 {
  font-family: 'sf_pro_displayregular';
  font-size: 24px;
  color: #000000;
}

.cardSize p {
  font-family: 'sf_pro_displayregular';
  color: rgb(0, 0, 0);
  opacity: 0.4;
  margin: 8px 0px;
}

.cardQuantity p {
  font-family: 'sf_pro_displayregular';
  color: rgb(0, 0, 0);
  margin-top: 32px;
  opacity: 0.4;
}

.size {
  padding: 0;
}

.size .valuebutton {
  display: flex;
  border: 1px solid #ddd;
  margin: 0px;
  width: 24px;
  height: 24px;
  text-align: center;
  vertical-align: middle;
  /* padding-bottom: 5px; */
  padding: 0 0 2px 0;
  background: #eee;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 50%;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

.size .valuebutton:hover {
  cursor: pointer;
}

.size form {
  display: flex;
  align-items: center;
}

.size p {
  margin-bottom: 0vw;
}

.size .peragraphp {
  font-size: 20px;
  color: rgb(167, 167, 167);
  margin-bottom: 10px;
}

.size form .decrease {
  margin-right: 0;
}

.size form .increase {
  margin-left: 0;
  background: #838d78 !important;
  color: #fff;
}

.size .inputnumber {
  display: flex;
  text-align: center;
  border: none;
  border-top: none;
  border-bottom: none;
  margin: 0px;
  width: 60px;
  height: 50px;
  font-size: 18px;
  color: #838d78 !important;
  align-items: center;
  justify-content: center;
}
.sizeContainer p {
  background: #838d78 !important;
  width: max-content;
  padding: 5px 17px;
  border-radius: 20px;
  color: #ffffff;
  opacity: 1 !important;
}

.wishlistBtn {
  font-family: 'sf_pro_displayregular';
  color: #000 !important;
  border-radius: 5px;
  border: 1px solid #000;
  font-size: 20px;
  background-color: #fff;
  height: 48px !important;
  margin-right: 8px;
}

.addcartbtn {
  font-family: 'sf_pro_displayregular';
  background: #838d78;
  border-radius: 5px;
  color: #fff !important;
  font-size: 20px;
  height: 48px !important;
  margin-left: 8px;
}

.recmmendedproduct {
  height: 48px !important;
  background: #838d78;
  border-radius: 5px;
  width: 100%;
  margin: 20px auto;
  text-align: left;
  padding: 10px;
}

.recmmendedproduct p {
  color: #fff;
  margin-bottom: 0px;
  font-size: 18px;
}

.tastingnote {
  margin: 20px 0px;
}

.tastingnote p {
  color: rgb(0, 0, 0) !important;
}

.tastingnote h5 {
  font-family: 'sf_pro_displayregular' !important;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}

@media (max-width: 830px) {
  .avatar {
    width: 330px;
    height: 380px;
  }
  .cartCard {
    width: 100% !important;
  }
  .cartButtons {
    margin: 0;
  }

  .tablerounededCorner {
    width: 330px;
  }

  .tablerounededCorner .tableTitle {
    font-size: 14px;
  }

  .tablerounededCorner .tableText {
    font-size: 20px;
  }

  .cardTitle h2 {
    font-size: 24px;
  }

  .cardTitle p {
    font-size: 18px !important;
  }

  .cardPrice h2 {
    font-size: 24px;
  }

  .cardSize p {
    margin: 8px 0px;
  }

  .cardQuantity p {
    margin-top: 20px;
  }

  .size .valuebutton {
    width: 28px;
    height: 28px;
    padding-bottom: 5px;
    font-size: 22px;
  }

  .size .peragraphp {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .size .inputnumber {
    width: 50px;
    height: 55px;
    font-size: 26px;
  }

  .wishlistBtn {
    font-size: 18px;
    height: 44px !important;
    margin-right: 8px;
  }

  .addcartbtn {
    font-size: 18px !important;
    height: 44px !important;
    margin-left: 8px;
  }

  .recmmendedproduct {
    height: 44px !important;
    font-size: 18px !important;
    margin: 16px auto;
    padding: 8px;
  }

  .recmmendedproduct p {
    font-size: 18px;
  }

  .tastingnote {
    margin: 16px 0px;
  }
}

@media (max-width: 576px) {
  .avatar {
    width: 100%;
    height: 380px;
  }
  .cartCard {
    width: 100% !important;
    /* margin-top: 20px; */
  }
  .cartButtons {
    margin: 0;
  }

  .tablerounededCorner {
    width: 100%;
  }

  .tablerounededCorner .tableTitle {
    font-size: 12px;
  }

  .tablerounededCorner .tableText {
    font-size: 18px;
  }

  .cardTitle h2 {
    font-size: 22px;
  }

  .cardTitle p {
    font-size: 18px !important;
  }

  .cardPrice h2 {
    font-size: 22px;
  }

  .cardSize p {
    margin: 4px 0px;
  }

  .size .valuebutton {
    width: 28px;
    height: 28px;
    padding-bottom: 5px;
    font-size: 22px;
  }

  .size .peragraphp {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .size .inputnumber {
    width: 50px;
    height: 50px;
    font-size: 26px;
  }

  .wishlistBtn {
    font-size: 18px;
    height: 44px !important;
    margin-right: 8px;
  }

  .addcartbtn {
    font-size: 18px !important;
    height: 44px !important;
    margin-left: 8px;
  }

  .recmmendedproduct {
    height: 44px !important;
    font-size: 18px !important;
    margin: 16px auto;
    padding: 8px;
  }

  .recmmendedproduct p {
    font-size: 18px;
  }

  .tastingnote {
    margin: 16px 0px;
  }
}
