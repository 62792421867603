.card {
  height: 390px;
  margin-bottom: 20px;
  border: 0;
  box-shadow: 0px 0px 6px #0000001f;
}

.cardBody {
  overflow: hidden;
}

.cardText {
  font-size: 14px !important;
  color: #838d78;
}

.card-title h3 {
  color: #838d78 !important;
}
