@mixin section--padding-top-sx {
    @media screen and (max-width: 500px) {
      @content;
    }
  }
  
  @mixin card-top-sommelier-sx {
    @media screen and (max-width: 500px) {
      @content;
    }
  }
  @mixin card-top-sommelier-sm {
    @media screen and (max-width: 1000px) {
      @content;
    }
  }
  @mixin card-top-sommelier-md {
    @media screen and (max-width: 1399px) {
      @content;
    }
  }
  @mixin card-top-sommelier-lg {
    @media screen and (min-width: 1400px) {
      @content;
    }
  }
  .card-top-sommelier {
    @include card-top-sommelier-lg {
      width: 195px !important;
    }
    @include card-top-sommelier-md {
      width: 195px !important;
    }
    @include card-top-sommelier-sm {
      width: 180px !important;
    }
    @include card-top-sommelier-sx {
      width: 170px !important;
    }
  }
  
  .sommelier img {
    object-fit: cover !important;
    border-radius: 8px;
    width: 100%;
    @include card-top-sommelier-lg {
      height: 240px !important;
    }
    @include card-top-sommelier-md {
      height: 240px !important;
    }
    @include card-top-sommelier-sm {
      height: 220px !important;
    }
    @include card-top-sommelier-sx {
      height: 200px !important;
    }
  }
  
  .card-top-sommelier-skeleton {
    @include card-top-sommelier-lg {
      width: 230px;
      margin-right: 24px;
    }
    @include card-top-sommelier-md {
      width: 210px;
      margin-right: 24px;
    }
    @include card-top-sommelier-sm {
      width: 180px;
      margin-right: 24px;
    }
    @include card-top-sommelier-sx {
      width: 160px;
      margin-right: 24px;
    }
  }
  
  .text-ellipsis-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: sf_pro_displayregular !important;
    text-transform: capitalize;
  }
  
  .text-ellipsis-name-sub-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
  }
  .carousel-container {
    height: auto;
    width: 100%;
  }
  .carousel-container-wines-by-terroir {
    height: auto;
    width: 100%;
  }
  .carousel-container-wines-by-moment {
    height: auto;
    width: 100%;
  }
  
  .trending-collection {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000033;
    border-radius: 8px;
    opacity: 1;
  }
  
  .top-sommelier-collection-name {
    font-family: new_york_extra_largeregular;
    letter-spacing: 0.3px;
    color: #b25661;
    font-size: 16px;
  }
  
  .grid-wrapper-explore-sommilers {
    --auto-grid-min-size: 13rem;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 480px) {
    .grid-wrapper-explore-sommilers {
      --auto-grid-min-size: 8rem !important;
      grid-gap: 16px !important;
      grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr)) !important;
    }
  }