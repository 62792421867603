.sommelierProfile h3 {
  font-family: 'new_york_extra_largemedium';
  margin-top: 20px;
  color: #000 !important;
}

.sommelierProfile h5 {
  font-family: 'new_york_extra_largeregular';
}

.sommelierProfile p {
  font-family: 'sf_pro_displaymedium';
  font-size: 20px !important;
  color: #000 !important;
}

.sommelierProfile .description {
  font-family: 'sf_pro_displayregular';
  font-size: 20px !important;
  color: #000 !important;
  line-height: 45px;
}

.sommelierNameBox {
  margin-top: 70px !important;
}

.followersBox {
  margin-top: 24px;
}

.tags {
  margin-top: 40px;
}

.tags .icon {
  width: 30px;
  height: 30px;
}

.text {
  margin-left: 10px;
  font-size: 16px;
  font-family: 'sf_pro_displayregular';
}

.tags .item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px !important;
  text-align: center !important;
}

.social .socialIcons img {
  width: 32px;
  object-fit: contain;
}

.social .socialIcons {
  padding-right: 40px;
  display: inline-flex;
  align-items: center;
}

.sectionTitle h3 {
  color: #000 !important;
}

.sectionTitle p { 
  color: #000 !important; 
  opacity: 0.4 !important;
}

/***** Wine Talks ******/
.wineTips {
  padding: 0px;
}
.rowSize {
  width: 100%;
  height: 100%;
  margin: auto;
}

.rowSize img {
  max-height: 550px;
  align-items: center;
}

.sommelierProfile .profileImg {
  width: 200px !important;
  height: 200px !important;
  border-radius: 50%;
  max-width: unset !important;
}

.avatar {
  height: 12rem;
  margin: 0px;
  object-fit: cover;
}

.avatar img {
  border-radius: 10px 10px 0px 0px;
}

.card {
  cursor: pointer;
  margin: 0px;
  width: 20rem;
  height: 22rem !important;
}

.card .shadowSm {
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.09) !important;
}

.cardTitle {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.8em;
  margin-right: 0.4em;
  color: #8a937f;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
}

.cardText {
  font-size: 1rem;
  font-weight: 300;
  margin-left: 0.8em;
  color: #b25661;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.colStyle {
  color: var(--text-secondary, #3b8396);
  vertical-align: middle;
  padding-bottom: 0.5rem;
}

.rowDisplay {
  display: block;
}

.userDateDiv {
  justify-content: space-between;
  margin-left: 0.8em;
  margin-right: 0.8em;
  margin-top: 20px;
}

.userDateDiv .user {
  width: 100%;
  height: 40px;
  justify-content: space-between;
}

.userDateDiv .userImg {
  display: flex;
}

.userDateDiv .userImg img {
  width: 28px;
  height: 28px;
  border-radius: 100px;
}

.userDateDiv .userImg .userTitle {
  font-family: 'sf_pro_displayregular';
  margin-left: 8px;
  margin-top: 2px;
  font-size: 14px;
  color: #838d78;
}

.userDate {
  font-family: 'sf_pro_displayregular';
  font-size: 14px;
  font-weight: 300;
  margin-top: 4px;
  color: #838d78;
  text-align: right;
}

.activeTab {
  display: flex;
  border-bottom: 4px solid #b25661;
}

@media (max-width: 830px) {
  .followersBox {
    margin: 15px auto;
  }
  .rowSize img {
    max-height: 550px;
    align-items: center;
  }

  .sommelierProfile p {
    font-size: 20px !important;
  }
  .sommelierProfile .profileImg {
    margin-top: 25px;
    width: 130px !important;
    height: 130px !important;
    object-fit: cover;
  }
  .social .socialIcons img {
    width: 40px;
    object-fit: contain;
  }

  .sectionTitle h2 {
    font-size: 28px !important;
  }

  .sectionTitle h4 {
    font-size: 28px !important;
  }

  .tags .item {
    font-size: 18px !important;
    border-radius: 6px !important;
    height: 70px !important;
  }

  .tags .icon {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 576px) {
  .wineTips {
    margin: 0px 15px;
  }

  .rowSize img {
    max-height: 550px;
    align-items: center;
  }
  /* .sommelier img {
    height: 200px !important;
  } */
  .sommelierProfile p {
    font-size: 10px !important;
    padding-left: 10px;
  }
  .sommelierProfile .profileImg {
    margin-top: 0px;
    width: 85px !important;
    height: 85px !important;
  }

  .sommelierProfile .description {
    font-size: 15px !important;
    line-height: 30px;
  }
  .social .socialIcons img {
    width: 30px;
  }
  .social .socialIcons {
    padding-right: 20px;
  }
  .sectionTitle h2 {
    font-size: 24px !important;
  }

  .sectionTitle h4 {
    font-size: 24px !important;
  }

  .tags .item {
    font-size: 14px !important;
    border-radius: 4px !important;
    height: 50px !important;
    padding-left: 15px !important;
  }

  .tags .icon {
    width: 18px;
    height: 18px;
  }

  .sommelierNameBox {
    margin-top: 42px !important;
  }
}
