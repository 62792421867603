@mixin product-card-small-mobile {
  @media screen and (max-width: 320px) {
    @content;
  }
}
@mixin product-card-sx {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@mixin product-card-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin product-card-md {
  @media screen and (max-width: 1399px) {
    @content;
  }
}
@mixin product-card-lg {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

.product-card {
  cursor: pointer;
  justify-content: center;
  .text-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card {
    @include product-card-lg {
      width: 195px !important;
    }
    @include product-card-md {
      width: 195px !important;
    }
    @include product-card-sm {
      width: 180px !important;
    }
    @include product-card-sx {
      width: 150px !important;
      // width: 124px !important;
    }
    @include product-card-small-mobile {
      width: 130px !important;
    }

    .add-fav{
          position: absolute;
          top: 0;
          right: 0;
    }

    .top-rated-img-cont {
      position: relative;
      .share-icon {
        img {
          width: 24px !important;
          height: 24px !important;
          border: 0px;
        }
      }
      img {
        object-fit: contain;
        object-position: bottom;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border: 1px solid #dadad0;
        border-bottom: 0px;
        @include product-card-lg {
          width: 100%;
          height: 195px !important;
        }
        @include product-card-md {
          width: 100%;
          height: 195px !important;
        }
        @include product-card-sm {
          width: 100%;
          height: 180px !important;
        }
        @include product-card-sx {
          width: 100%;
          height: 150px !important;
        }
        @include product-card-small-mobile {
          width: 100%;
          height: 130px !important;
        }
      }
    }
  }
}
.displayAddto.badges {
  cursor: pointer;
}

.wine-categorires .top-rated-img-cont a {
  font-size: 16px;
}

.shop-wine-page,
.like-wine-page {
  .product-card {
    .card {
      width: 100% !important;

      .top-rated-img-cont {
        .share-icon {
          img {
            width: 24px !important;
            height: 24px !important;
            border: 0px;
          }
        }
        img {
          object-fit: contain;
          object-position: bottom;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border: 1px solid #dadad0;
          border-bottom: 0px;
          @include product-card-lg {
            width: 100%;
            height: 260px !important;
          }
          @include product-card-md {
            width: 100%;
            height: 230px !important;
          }
          @include product-card-sm {
            width: 100%;
            height: 200px !important;
          }
          @include product-card-sx {
            width: 100%;
            height: 150px !important;
          }
          @include product-card-small-mobile {
            width: 100%;
            height: 130px !important;
          }
        }
      }
    }
  }
}

.myCollection-page {
  .grid-wrapper-shop-wine {
    .product-card {
      .card {
        width: 100% !important;
        img {
          @include product-card-lg {
            width: 100%;
            height: 260px !important;
          }
          @include product-card-md {
            width: 100%;
            height: 230px !important;
          }
          @include product-card-sm {
            width: 100%;
            height: 200px !important;
          }
          @include product-card-sx {
            width: 100%;
            height: 150px !important;
          }
          @include product-card-small-mobile {
            width: 100%;
            height: 130px !important;
          }
        }
        .top-rated-img-cont .share-icon img {
          width: 24px !important;
          height: 24px !important;
          border: 0px;
        }
      }
    }
  }
}
