.sommelier-commission {
    margin-top: 36px;
    --elevation: 1;
    border: 1px solid #D9D9D9;
    --epx: calc(var(--elevation) * 1px);
    
    /* these 2 shadows serve as a border for  0-1 elevation    */
    --shadow1: 0 0 1px rgba(135, 72, 72, 0.1);
    --shadow2: 0 1px 2px rgba(149, 73, 73, 0.08);
    
    --offset-y: calc(var(--epx) + 1px);
    --blur: calc(var(--epx) * 2);
    --spread: calc(var(--epx) * .3);
    --shadow3: 
        0 var(--offset-y) 
        var(--blur) 
        var(--spread) 
        rgba(126, 63, 63, 0.2);
    
    box-shadow: 
      var(--shadow1),
      var(--shadow2),
      var(--shadow3);


      table{
        width: 100%;
        td {
            padding:16px;   
          }
          th {
            padding:16px;  
            color: #000; 
          }
      }

  }