.itemExpert {
  /* width: 200px !important; */
}

.sommelier {
  /* width: 100%;
    height: 100%; */
}

.sommelier img {
  /* width: 200px; */
  object-fit: cover !important;
  border-radius: 8px;
}
.cardBottom {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 8px 8px;
  /* padding: 0px; */
  box-shadow: 0px 0px 6px #0000001a;
  margin: 4px 2px;
}
.cardBottom .featureText {
  font-size: 12px !important;
}
.cardBottom p {
  font-size: 16px !important;
  margin-bottom: 0;
  padding-top: 4px;
}
@media (max-width: 830px) {
  .itemExpert {
    /* width: 180px !important; */
  }

  .sommelier {
    /* width: 100%;
        height: 100%; */
  }

  .sommelier img {
    /* width: 180px !important;
        height: 210px !important;
        object-fit: cover !important;
        border-radius: 8px; */
  }
  .cardBottom .featureText {
    font-size: 10px !important;
  }
  .cardBottom p {
    font-size: 12px !important;
  }
}

@media (max-width: 576px) {
  .itemExpert {
    /* width: 120px !important; */
  }

  .sommelier {
    /* width: 100%;
        height: 100%; */
  }

  .sommelier img {
    /* width: 120px !important;
        height: 140px !important;
        object-fit: cover !important;
        border-radius: 8px; */
  }

  .cardBottom .featureText {
    font-size: 10px !important;
  }
  .cardBottom p {
    font-size: 12px !important;
  }
}
