.orderHistory {
  font-family: 'new_york_extra_largemedium';
  font-size: 36px;
  color: #838d78;
}

.orderCard {
  padding: 0px;
}

.orderCard .date {
  color: #b25661 !important;
  font-family: 'sf_pro_displayregular';
  font-size: 20px;
}

.orderCard .orderId {
  color: #838d78;
  font-family: 'sf_pro_displayregular';
  font-size: 20px;
}

.orderCard .orderPrice {
  color: #838d78;
  font-family: 'sf_pro_displayregular';
  font-size: 24px !important;
}

.orderCard .contactUs {
  font-family: 'sf_pro_displayregular';
  font-size: 20px;
  background-color: #f2f2f2 !important;
  color: #838d78 !important;
}

.orderCard .goToHome {
  font-family: 'sf_pro_displayregular';
  font-size: 20px;
  background-color: #838d78 !important;
  color: #fff !important;
}

/*** Mobile ***/
@media (max-width: 480px) {
  .orderCard .date {
    color: #838d78;
    font-size: 14px;
  }

  .orderCard .orderId {
    font-size: 16px;
  }

  .orderCard .orderPrice {
    font-size: 16px !important;
  }

  .orderCard .contactUs {
    font-size: 16px !important;
  }

  .orderCard .goToHome {
    font-size: 16px !important;
  }
}

/*** Tablet ***/
@media (max-width: 820px) {
  .orderCard .date {
    color: #838d78;
    font-size: 16px;
  }

  .orderCard .orderId {
    font-size: 18px;
  }

  .orderCard .orderPrice {
    font-size: 18px !important;
  }

  .orderCard .contactUs {
    font-size: 18px;
  }

  .orderCard .goToHome {
    font-size: 18px;
  }
}