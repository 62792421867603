@mixin banner-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin banner-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin banner-md {
  @media screen and (min-width: 1000px) {
    @content;
  }
}
@mixin banner-lg {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

@mixin explore-som-user-img-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin explore-som-user-img-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin explore-som-user-img-md {
  @media screen and (max-width: 1280px) {
    @content;
  }
}
@mixin explore-som-user-img-lg {
  @media screen and (min-width: 1281px) {
    @content;
  }
}
@mixin explore-som-card-smallmobile {
  @media screen and (max-width: 320px) {
    @content;
  }
}

@mixin explore-som-card-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin explore-som-card-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin explore-som-card-md {
  @media screen and (max-width: 1280px) {
    @content;
  }
}
@mixin explore-som-card-lg {
  @media screen and (min-width: 1281px) {
    @content;
  }
}

.explore-som-card {
  margin: auto;
  width: 100%;
  // @include explore-som-card-lg {
  //   width: 256px !important;
  // }
  // @include explore-som-card-md {
  //   width: 220px !important;
  // }
  // @include explore-som-card-sm {
  //   width: 180px !important;
  // }
  // @include explore-som-card-sx {
  //   width: 160px !important;
  // }
  // @include explore-som-card-smallmobile {
  //   width: 140px !important;
  // }
}

.explore-som-user-img {
  img {
    border-radius: 8px;
    object-fit: cover;
    width: 100%;
    @include explore-som-user-img-lg {
      height: 320px !important;
    }
    @include explore-som-user-img-md {
      height: 280px !important;
    }
    @include explore-som-user-img-sm {
      height: 250px !important;
    }
    @include explore-som-user-img-sx {
      height: 230px !important;
    }
    @include explore-som-card-smallmobile {
      height: 200px !important;
    }
  }
}

.carousel-root {
  .carousel .slide img {
    // @include banner-lg {
    //   height: 400px !important;
    // }
    // @include banner-md {
    //   height: 320px !important;
    // }
    // @include banner-sm {
    //   height: 200px !important;
    // }
    // @include banner-sx {
    //   height: 140px !important;
    // }
  }
}

.banner-skeleton {
  @include banner-lg {
    height: 400px !important;
  }
  @include banner-md {
    height: 320px !important;
  }
  @include banner-sm {
    height: 200px !important;
  }
  @include banner-sx {
    height: 140px !important;
  }
}

.exploresommelier {
  cursor: pointer;
}
.grid-wrapper-explore-sommilers {
  --auto-grid-min-size: 13rem;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@media only screen and (max-width: 480px) {
  .grid-wrapper-explore-sommilers {
    --auto-grid-min-size: 8rem !important;
    grid-gap: 16px !important;
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr)) !important;
  }
}

.experts-div .card-body p {
  color: rgb(0, 0, 0, 0.4) !important;
}

.showCartEmpty {
  margin: auto;
  padding: 30px;
  text-align: center;
  .cartButtons button {
      height: 34px;
      font-size: 18px;
    }
    .text {
      font-family: 'sf_pro_displayregular';
      font-size: 30px !important;
      color: #838d78;
    }
    .btnPrimary {
      color: #fff;
      background-color: #838d78;
      border: 0;
      height: 48px;
      font-size: 14px;
    }
}