@mixin carousel-item-wine-tips-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin carousel-item-wine-tips-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin carousel-item-wine-tips-md {
  @media screen and (max-width: 1399px) {
    @content;
  }
}
@mixin carousel-item-wine-tips-lg {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

@mixin carousel-wine-tips-img-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin carousel-wine-tips-img-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin carousel-wine-tips-img-md {
  @media screen and (max-width: 1399px) {
    @content;
  }
}
@mixin carousel-wine-tips-img-lg {
  @media screen and (min-width: 1400px) {
    @content;
  }
}
.carousel-item-wine-tips {
  @include carousel-item-wine-tips-lg {
    width: 358px !important;
  }
  @include carousel-item-wine-tips-md {
    width: 300px !important;
  }
  @include carousel-item-wine-tips-sm {
    width: 280px !important;
  }
  @include carousel-item-wine-tips-sx {
    width: 280px !important;
  }
}
.wine-tips-page {
  .carousel-item-wine-tips {
    @include carousel-item-wine-tips-lg {
      width: 400px !important;
    }
    @include carousel-item-wine-tips-md {
      width: 350px !important;
    }
    @include carousel-item-wine-tips-sm {
      width: 280px !important;
    }
    @include carousel-item-wine-tips-sx {
      width: 280px !important;
    }
  }
}

.carousel-item-wine-tips-skeleton {
  @include carousel-item-wine-tips-lg {
    width: 400px !important;
  }
  @include carousel-item-wine-tips-md {
    width: 360px !important;
  }
  @include carousel-item-wine-tips-sm {
    width: 320px !important;
  }
  @include carousel-item-wine-tips-sx {
    width: 320px !important;
  }
}

.avatar {
  img {
    border-radius: 8px;
    object-fit: cover;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    @include carousel-wine-tips-img-lg {
      width: 100%;
      height: 200px !important;
      border-radius: 8px;
    }
    @include carousel-wine-tips-img-md {
      width: 100%;
      height: 180px !important;
      border-radius: 8px;
    }
    @include carousel-wine-tips-img-sm {
      width: 100%;
      height: 160px !important;
      border-radius: 8px;
    }
    @include carousel-wine-tips-img-sx {
      width: 100% !important;
      height: 164px !important;
    }
  }
}

.wine-tips-page-card {
  .carousel-item-wine-tips {
    @include carousel-item-wine-tips-sx {
      width: 100% !important;
    }
  }
  .react-loading-skeleton {
    line-height: unset;
  }
}
.blog-content {
  p {
    margin: 0 !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
  }
}
.cardBody {
  .ql-editor {
    padding: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .edit-hide-mobile {
    display: none !important;
  }
}
