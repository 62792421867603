.wine-tasting-title {
  color: #b25661 !important;
  font-weight: bold !important;
}
.wine-tasting-title-sub {
  color: #000 !important;
}
.alert-bg-color {
  background-color: rgba(178, 86, 97, 0.1);
}

.btn-contact-us {
  width: 100% !important;
  max-width: 100% !important;
}
