.card__payment {
    --elevation: 1;
    --epx: calc(var(--elevation) * 1px);
    
    /* these 2 shadows serve as a border for  0-1 elevation    */
    --shadow1: 0 0 1px rgba(135, 72, 72, 0.1);
    --shadow2: 0 1px 2px rgba(149, 73, 73, 0.08);
    
    --offset-y: calc(var(--epx) + 1px);
    --blur: calc(var(--epx) * 2);
    --spread: calc(var(--epx) * .3);
    --shadow3: 
        0 var(--offset-y) 
        var(--blur) 
        var(--spread) 
        rgba(126, 63, 63, 0.2);
    
    box-shadow: 
      var(--shadow1),
      var(--shadow2),
      var(--shadow3);

  }
  
   .card__payment {
    background: white;
    border-radius: 4px;
    padding: 8px;
    // cursor: pointer;
  }

  .card__payment_wallet{
    --elevation: 1;
    --epx: calc(var(--elevation) * 1px);
    
    /* these 2 shadows serve as a border for  0-1 elevation    */
    --shadow1: 0 0 1px rgba(135, 72, 72, 0.1);
    --shadow2: 0 1px 2px rgba(149, 73, 73, 0.08);
    
    --offset-y: calc(var(--epx) + 1px);
    --blur: calc(var(--epx) * 2);
    --spread: calc(var(--epx) * .3);
    --shadow3: 
        0 var(--offset-y) 
        var(--blur) 
        var(--spread) 
        rgba(126, 63, 63, 0.2);
    
    box-shadow: 
      var(--shadow1),
      var(--shadow2),
      var(--shadow3);

  }
  
   .card__payment_wallet {
    background: #f0c787;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
  }
  
  
   .cart__title {
    font-family: 'sf_pro_displaysemibold';
    font-size: 14px !important;
    color: #b25661 !important;
    text-transform: uppercase;
    margin: 0;
  }

  .item__title {
    font-size: 16px !important;
    color: #555555 !important;
    font-weight: 400;
  }
  .coupon_remove{
    color: #b25661 !important;
    background: #FFF;
    cursor: pointer;
  }

  .coupon_sub_msg{
    color: #555555 !important;
    font-size: 12px;
  }
  .coupon_msg{
    color: #b25661 !important;
    font-size: 16px;
  }