.card {
  height: 300px;
  border: 0;
}

.card .cardBody {
  text-align: center;
}

.card .cardBody h5 {
  font-family: 'sf_pro_displaymedium';
  font-size: 20px !important;
  margin: 0px;
  color: #838d78;
}

.card .cardBody p {
  font-family: 'sf_pro_displayregular';
  font-size: 16px !important;
  color: #b25661 !important;
}

.cardImage {
  object-fit: cover;
  margin: 0 auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
