.add_address_from{
    .inline{
        label{
                font-size: 14px;
                margin-bottom: 2px;
            }
            .my-2{
                margin: 0 !important;
                input{
                    font-size: 1rem;
                }
            }
    }

   
}
.cartButtons .btn .btnPrimary {
    font-size: 14px;
  }
  
  .btnPrimary {
    color: #fff;
    background-color: #b25661;
    border: 0;
    height: 48px;
    font-size: 14px;
    width: 100%;
    border-radius: 0;
  }

  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc !important;
    color: #666666 !important;
  }