.desktopCartLeft {
  border-right: 1px solid #e5e5e5;
  height: 100%;
}

.desktopCartLeftShadowSm {
  box-shadow: 0;
}
.items {
  font-family: 'sf_pro_displaysemibold';
  font-size: 20px;
  color: #000;
}
.itemImg {
  flex: 0 0 180px;
}
.itemImg img {
  width: 180px;
  height: 180px;
  border-radius: 8px;
  object-fit: contain;
  border: 1px solid #e5e5e5;
}

.itemTitle {
  height: 28px;
  font-family: 'sf_pro_displayregular';
  font-size: 20px;
  line-height: 26px;
  color: #838d78;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.itemTitleSub {
  font-family: 'sf_pro_displayregular';
  font-size: 18px;
  display: flex;
  color: #b25661;
}
.itemPrice {
  font-family: 'sf_pro_displayregular';
  color: #838d78;
  font-size: 20px;
  color: #000;
}

.itemSize {
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
  display: flex;
  color: #838d78;
}

.desktopSelect {
  width: 150px;
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
  background: #838d78;
  color: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 4px;
}

.desktopSelect span {
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
  color: #fff;
}

.desktopSelect select {
  background: #838d78;
  color: #fff;
  border: 0px;
}

.cartCard {
  padding: 0px;
}
.card {
  border-radius: 8px;
}

.cartCard .title {
  font-family: 'sf_pro_displaysemibold';
  font-size: 20px;
  color: #b25661 !important;
  text-transform: uppercase;
}

.cartCard .subTitle {
  font-family: 'sf_pro_displayregular';
  font-size: 18px;
  color: rgb(0, 0, 0, 0.4);
}

.cartCard .subTitleTwo {
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
  color: rgb(0, 0, 0, 0.4);
}

.cartCard .total {
  font-family: 'sf_pro_displayregular';
  font-size: 18px;
  color: #b25661;
}

.cartCard .deliveryAddress a {
  font-size: 16px;
}

.cartCard .orderReceived .cartButtons button .btnDefault {
  color: #838d78;
}

.shadowSm {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.09) !important;
}

.deliveryAddress {
  align-items: center;
}

.deliveryAddress .label {
  color: #000 !important;
  font-family: 'sf_pro_displaysemibold';
  font-size: 16px;
}

.prices {
  display: flex;
  justify-content: space-between;
  color: rgb(175, 175, 175);
}

.prices div {
  font-size: 16px;
}

.deliveryAddressCont {
  font-size: 16px;
  color: #000 !important;
}

.deliveryAddressCont a {
  color: #000 !important;
}

.cartButtons .total {
  font-size: 18px;
  font-family: 'sf_pro_displaymedium';
}

.cartButtons {
  justify-content: space-between;
}

.cartButtons button {
  max-width: 500px;
  width: 100%;
  /* height: 60px; */
  border-radius: 5px;
  font-size: 16px;
}

.cartButtons button:disabled {
  max-width: 500px;
  width: 100%;
  /* height: 60px; */
  border-radius: 5px;
  font-size: 16px;
  background-color: rgb(221, 221, 221);
}

.cartButtons .btn .btnPrimary {
  font-size: 14px;
}

.btnPrimary {
  color: #fff;
  background-color: #b25661;
  border: 0;
  height: 48px;
  font-size: 14px;
}

.offerButtonSelected {
  background: #F0C787;
  font-size: 16px;
  border: 0;
  height: 48px;
  color: #000000;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.offerButton {
  background: #ffffff;
  font-size: 16px;
  height: 48px;
  color: #969696;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.offerButtonSelected .offerButton :hover {
  cursor: default;
}

.loding_spinner {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 150px !important;
  flex-direction: column;
  text-align: center;
  height: 150px !important;
  position: absolute;
  top: 50%;
  border-radius: 50%;
  color: #fff;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999 ;
}

.availableOfferCard {
  margin-bottom: 20px;
}

@keyframes ckw {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gear {
  color: #000;
  width: 50px;
  height: 50px;
  animation-name: ckw;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.spinnerLoaderParent{
  height: 100vh;
}
.showCartEmpty {
  margin: auto;
  padding: 30px;
  text-align: center;
}

.showCartEmpty .text {
  font-family: 'sf_pro_displayregular';
  font-size: 30px !important;
  color: #838d78;
}

.desktopSelect select {
  outline: none;
}

.removeItem {
  cursor: pointer;
}

.InfoIcon {
  padding-top: 12px;
  color: #838d78;
  font-size: 15px;
}

.InfoIcon img {
  padding-right: 10px;
  /* padding-top: 11px; */
  /* position: absolute; */
  position: relative;
  top: -2px;
}
/********** My Cart Page End *********/
@media (max-width: 820px) {
  .itemTitle {
    line-height: 24px;
    font-size: 18px;
  }
  .itemTitleSub {
    font-size: 14px;
  }

  .itemSize {
    font-size: 16px;
  }

  .itemImg {
    flex: 0 0 120px;
  }

  .itemImg img {
    width: 120px !important;
    height: 120px !important;
    border-radius: 8px;
    object-fit: contain;
  }

  .itemPrice {
    font-size: 18px;
  }

  .desktopSelect {
    width: 100px;
    font-size: 12px;
  }

  .desktopSelect span {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .offerButtonFontSize {
    font-size: 14px;
    height: 36px;
  }
  .items {
    font-size: 18px;
  }
  .itemTitle {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
  }
  .itemTitleSub {
    font-size: 14px;
  }

  .itemSize {
    font-size: 14px;
  }

  .itemImg {
    flex: 0 0 80px;
  }

  .itemImg img {
    width: 80px !important;
    height: 80px !important;
    border-radius: 8px;
    object-fit: contain;
  }

  .itemPrice {
    font-size: 16px;
  }

  .desktopSelect {
    width: 86px;
    font-size: 12px;
    height: 36px;
  }

  .desktopSelect span {
    font-size: 12px;
    text-align: center;
  }

  .desktopCartLeft {
    border-right: 0;
  }

  .desktopCartLeftShadowSm {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.09) !important;
    border-radius: 5px;
  }

  .cartCard .title {
    font-size: 18px;
  }

  .cartCard .subTitle {
    font-size: 16px;
  }

  .cartCard .subTitleTwo {
    font-size: 14px;
  }

  .cartCard .total {
    font-size: 16px;
  }
  .cartButtons button {
    height: 34px;
  }
  .showCartEmpty .text {
    font-family: 'sf_pro_displayregular';
    font-size: 18px !important;
    color: #838d78;
  }

  .cartButtons button {
    font-size: 18px;
  }
}

@media (max-width: 320px) {
  .offersButtonFontSize {
    font-size: 12px;
    height: 36px;
  }
}

.cardQuantity p {
  font-family: 'sf_pro_displayregular';
  color: rgb(0, 0, 0);
  margin-top: 32px;
  opacity: 0.4;
}

.size {
  padding: 0;
}

.size .valuebutton {
  display: flex;
  border: 1px solid #ddd;
  margin: 0px;
  width: 24px;
  height: 24px;
  text-align: center;
  vertical-align: middle;
  /* padding-bottom: 5px; */
  padding: 0 0 2px 0;
  background: #eee;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 50%;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

.size .valuebutton:hover {
  cursor: pointer;
}

.size form {
  display: flex;
  align-items: center;
}

.size p {
  margin-bottom: 0vw;
}

.size .peragraphp {
  font-size: 20px;
  color: rgb(167, 167, 167);
  margin-bottom: 10px;
}

.size form .decrease {
  margin-right: 0;
}

.size form .increase {
  margin-left: 0;
  background: #838d78 !important;
  color: #fff;
}
.size form .increase[disabled] {
  margin-left: 0;
  background: #bababa !important;
  color: #fff;
}
.size .inputnumber {
  display: flex;
  text-align: center;
  border: none;
  border-top: none;
  border-bottom: none;
  margin: 0px;
  width: 60px;
  height: 50px;
  font-size: 18px !important;
  color: #838d78 !important;
  align-items: center;
  justify-content: center;
}
.sizeContainer p {
  background: #838d78 !important;
  width: max-content;
  padding: 5px 17px;
  border-radius: 20px;
  color: #ffffff;
}
