:root {
  --user-icon-size: 36px;
  --event-icon-width: 50%;
  --event-icon-height: 150px;
  /* --item-name-max-width: 22ch; */
}

.eventItemIcon {
  width: var(--event-icon-width);
  max-height: var(--event-icon-height);
  min-width: var(--event-icon-width);
  height: var(--event-icon-height);
  opacity: 1;
  border-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  object-fit: cover;
}

.eventItemName {
  /* max-width: var(--item-name-max-width); */
  min-height: 24px;
  color: #838d78;
  display: inline;
  text-align: left;
  font-family: 'new_york_extra_largeregular';
  letter-spacing: 0px;
  opacity: 1;
  float: left;
  margin: 1rem 0;
}

.eventItemInfo {
  align-self: center;
  width: -webkit-fill-available;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  padding-left: 21px;
}

.verticleUserCollectionItem {
  display: inline;
}

.userCard {
  justify-content: center;
  float: left;
  clear: left;
  display: flex;
  align-items: center;
}

.userIcon {
  margin-top: 4px;
  display: inline;
  width: var(--user-icon-size);
  height: var(--user-icon-size);
  border-radius: 50%;
  object-fit: cover;
  opacity: 1;
  margin-right: 0.5em;
}

.userName {
  /* height: 43px; */
  display: inline;
  vertical-align: middle;
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
  letter-spacing: 0px;
  /* line-height: 43px; */
  text-align: left;
  color: #b25661;
  opacity: 1;
}

.verticleUserCollection,
.eventItemCard {
  display: flex;
  gap: 0.5em;
  height: 100%;
}

.eventItemCard {
  cursor: pointer;
}

.verticleUserCollection {
  flex-direction: row;
  flex-wrap: wrap;
}

.verticleUserCollectionItem {
  flex: 45% 1 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000033;
  border-radius: 8px;
  opacity: 1;
  position: relative;
  min-height: var(--event-icon-height);
}

.verticleUserCollection .verticleUserCollectionButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.verticleUserCollection .collectionHeading {
  width: 100%;
  height: 40px;
  text-align: left;
  font: normal normal normal 36px/43px New York Extra Large;
  letter-spacing: 0.72px;
  color: #838d78;
  opacity: 1;
  margin-bottom: 0;
}

@media (max-width: 830px) {
  :root {
    --user-icon-size: 30px;
    --event-icon-width: 50%;
    --event-icon-height: 100px;
    /* --item-name-max-width: 22ch; */
  }
  .collectionItemName {
    margin: 0.5rem 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .userIcon {
    display: inline;
    width: var(--user-icon-size);
    height: var(--user-icon-size);
    border-radius: 50%;
    object-fit: cover;
    opacity: 1;
    margin-right: 0.5em;
  }
  .userName {
    font-size: 12px;
    /* line-height: 30px; */
  }
  .verticleUserCollectionItem {
    flex: 45% 1 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000033;
    border-radius: 8px;
    opacity: 1;
    position: relative;
    min-height: var(--event-icon-height);
  }
}

@media (max-width: 576px) {
  :root {
    --user-icon-size: 30px;
    --event-icon-width: 50%;
    --event-icon-height: 100px;
    /* --item-name-max-width: 22ch; */
  }
  .eventItemName {
    margin: 0.5rem 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .userIcon {
    display: inline;
    width: var(--user-icon-size);
    height: var(--user-icon-size);
    border-radius: 50%;
    object-fit: cover;
    opacity: 1;
    margin-right: 0.5em;
  }
  .userName {
    font-size: 12px;
    /* line-height: 30px; */
  }
  .verticleUserCollectionItem {
    flex: 50% 1 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000033;
    border-radius: 8px;
    opacity: 1;
    position: relative;
    min-height: var(--event-icon-height);
  }
  .verticleUserCollection {
    margin: 20px;
  }
  .verticleUserCollection,
  .collectionItemCard {
    height: 96%;
  }
}
.eventItemName {
  margin: 0.5rem 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.showColections {
  min-height: 600px;
}
