@media (min-width: 0px) and (max-width: 480px) {

    h3{
        font-size: 20px !important;
        color: #000 !important;
    }
    
    h4 {
        font-size: 18px !important;
    }

    h5{
        font-size: 16px !important;
    }

    p {
        font-size: 14px !important;
        color: rgb(0, 0, 0, 0.4) !important;
    }

    .headerTabs {
        color: #838d78 !important;
        font-size: 16px !important;
        text-transform: none !important;
        font-family: 'new_york_extra_largeregular' !important;
    }

    .productDetail h3 {
        font-size: 18px !important;
    }

    .productDetail p {
        font-size: 14px !important;
    }

    .profileTab li a{ 
        font-size: 18px !important;
    }

    .editProfile input{
        height: 48px !important;
        color: #000 !important;
    }
      
    .editProfile button{
        height: 48px !important;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    h3{
        font-size: 24px !important;
        color: #000 !important;
    }

    h4 {
        font-size: 20px !important;
    }

    h5 {
        font-size: 18px !important;
    }

    p {
        font-size: 16px !important;
        color: rgb(0, 0, 0, 0.4) !important;
    }

    .headerTabs {
        color: #838d78 !important;
        font-size: 18px !important;
        text-transform: none !important;
        font-family: 'new_york_extra_largeregular' !important;
    }

    .productDetail h3 {
        font-size: 22px !important;
    }

    .productDetail p {
        font-size: 16px !important;
    }

    .profileTab li a{ 
        font-size: 20px !important;
    }

    .editProfile input{
        height: 60px !important;
        color: #000 !important;
    }
      
    .editProfile button{
        height: 60px !important;
    }
}

@media (min-width: 769px) {
    h3{
        font-size: 32px !important;
        color: #000 !important;
    }

    h4 {
        font-size: 20px !important;
    }

    h5 {
        font-size: 18px !important;
    }

    p {
        font-size: 18px !important;
        color: rgb(0, 0, 0, 0.5) !important;
    }

    .headerTabs {
        color: #838d78 !important;
        font-size: 20px !important;
        text-transform: none !important;
        font-family: 'new_york_extra_largeregular' !important;
    }

    .productDetail h3 {
        font-size: 24px !important;
    }

    .productDetail p {
        font-size: 18px !important;
    }

    .productDetail button {
        font-size: 18px !important;
    }

    .profileTab li {
        margin-top: 28px !important;
        padding: 0px !important;
        padding-right: 32px !important;
    }

    .profileTab li a{
        font-size: 22px !important;
        height: 72px !important;
    }

    .editProfile input{
        // height: 72px !important;
        color: #000 !important;
    }
      
    .editProfile button{
        height: 72px !important;
    }
}