.react-datepicker__week {
  .react-datepicker__day {
    color: #b25661 !important;
  }
  .react-datepicker__day--selected {
    color: #fff !important;
    background-color: #b25661 !important;
  }
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #b25661 !important;
}
.react-datepicker__day-names {
  .react-datepicker__day-name {
    color: #b25661 !important;
  }
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  color: #b25661 !important;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  left: -119px !important;
}
.react-datepicker-wrapper {
  ::placeholder {
    color: #ccc !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc !important;
  }
}
// .profile-edit-icon {
//   display: none;
// }
// .profile-img {
//   &:hover {
//     .profile-edit-icon {
//       position: absolute;
//       width: inherit;
//       text-align: center;
//       bottom: 0px;
//       height: inherit;
//       border-radius: 50%;
//       background: #4a4a4acc;
//       display: flex;
//       flex-direction: column-reverse;
//     }
//   }
// }

/*** Edit Profile ***/
.editProfile h4{
  font-family: "new_york_extra_largeregular" !important;
}

.pro-details h3{
  font-family: 'sf_pro_displayregular' !important;
}

.pro-details p {
  font-family: 'sf_pro_displayregular' !important;
  color: rgb(0, 0, 0, 0.4) !important;
}

.popup p{
  text-align: center;
  padding: 10px;
}
.errorMessage{
  width: 100%;
  font-size: 14px;
  line-height: 25px;
  max-height: 60px;
  color: red;
  text-align: center;
  margin-top: 18px;
}

