/* Popup style */

.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999;
}

.box {
    position: relative;
    width: 600px;
    margin: 0 auto;
    height: 300px;
    max-height: 70vh;
    margin-top: calc(15vh - 24px);
    background: #fff;
    border-radius: 4px;
    padding: 25px;
    border: 1px solid #999;
    overflow: auto;
    border-radius: 10px;
}

.box .title {
    font-size: 26px;
    color: #000;
}

.box .content button{
    margin: 0px !important;
}

.title h4{
padding: 15px 0px;
}

.shareText {
    height: 80px;
    font-family: 'new_york_extra_largemedium';
    font-size: 26px;
    text-align: center;
    border-bottom-width:1px;
    border-bottom-color:rgb(0, 0, 0, 0.1);
    border-bottom-style: solid;
    padding-top: 22px;
}

@media screen and (max-width: 480px) {
    .shareText {
        height: 60px;
        font-size: 20px; 
        padding-top: 16px;
    }
}