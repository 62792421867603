.orderHeader .leftTitle {
  font-size: 30px;
  font-family: 'new_york_extra_largemedium';
  color: #838d78;
}

.orderHeader .processing {
  background: #f0c787;
  color: #000 !important;
  height: 50px;
  width: 200px;
  font-size: 18px;
  padding: 10px 4px;
  border-radius: 2px;
  text-align: center;
}

.orderHeader .delivered {
  background: #58b100;
  color: #fff !important;
  height: 50px;
  width: 200px;
  font-size: 18px;
  padding: 10px 4px;
  border-radius: 2px;
  text-align: center;
}
.items {
  font-family: 'sf_pro_displaysemibold';
  font-size: 20px;
  color: #000;
}
.cardTitle {
  font-family: 'sf_pro_displaysemibold';
  font-size: 20px;
  color: #000 !important;
}

.text {
  font-family: 'sf_pro_displayregular';
  font-size: 18px !important;
  color: #838d78 !important;
}

.shadowLine {
  border-right: 1px solid #e5e5e5;
  height: 100%;
}

.boxShadow {
  box-shadow: none !important;
  border: 0 !important;
}

.contactUs {
  background-color: #f2f2f2 !important;
  color: #838d78 !important;
}

.goToHome {
  background-color: #838d78 !important;
  color: #fff !important;
}

.itemImg {
  flex: 0 0 180px;
}
.itemImg img {
  width: 180px;
  height: 180px;
  border-radius: 8px;
  object-fit: contain;
  border: 1px solid #e5e5e5;
}

.itemTitle {
  font-family: 'sf_pro_displayregular';
  font-size: 22px;
  line-height: 26px;
  color: #838d78;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.itemTitleSub {
  font-family: 'sf_pro_displayregular';
  font-size: 18px;
  display: flex;
  color: rgb(0, 0, 0, 0.4);
}
.itemPrice {
  font-family: 'sf_pro_displayregular';
  color: #000;
  font-size: 22px;
}

.itemSize {
  font-family: 'sf_pro_displayregular';
  font-size: 18px;
  display: flex;
  color: #b25661;
}
.paymentVia {
  font-family: 'sf_pro_displayregular';
  font-size: 16px !important;
  color: #b25661 !important;
  padding-top: 4px;
}
/********** My Cart Page End *********/
@media (max-width: 820px) {
  .cardTitle {
    font-family: 'sf_pro_displaysemibold';
    font-size: 20px;
    color: #838d78;
  }

  .text {
    font-family: 'sf_pro_displayregular';
    font-size: 18px !important;
    color: #838d78 !important;
  }

  .shadowLine {
    border-right: 1px solid #e5e5e5;
    height: 100%;
  }

  .boxShadow {
    box-shadow: none !important;
    border: 0 !important;
  }

  .contactUs {
    background-color: #f2f2f2 !important;
    color: #838d78 !important;
  }

  .goToHome {
    background-color: #838d78 !important;
    color: #fff !important;
  }

  .itemTitle {
    line-height: 24px;
    font-size: 18px;
  }
  .itemTitleSub {
    font-size: 14px;
  }

  .itemSize {
    font-size: 16px;
  }

  .itemImg {
    flex: 0 0 120px;
  }

  .itemImg img {
    width: 120px !important;
    height: 120px !important;
    border-radius: 8px;
    object-fit: contain;
  }

  .itemPrice {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .orderHeader .leftTitle {
    font-size: 24px;
  }

  .orderHeader .processing {
    height: 40px;
    width: 120px;
    font-size: 14px;
  }

  .orderHeader .delivered {
    height: 40px;
    width: 120px;
    font-size: 14px;
  }

  .items {
    font-size: 16px !important;
  }

  .cardTitle {
    font-size: 16px;
  }

  .text {
    font-size: 16px !important;
  }

  .items {
    font-size: 18px;
  }
  .itemTitle {
    line-height: 24px;
    font-size: 16px;
  }
  .itemTitleSub {
    font-size: 14px;
  }

  .itemSize {
    font-size: 16px;
  }

  .itemImg {
    flex: 0 0 80px;
  }

  .itemImg img {
    width: 80px !important;
    height: 80px !important;
    border-radius: 8px;
    object-fit: contain;
  }

  .itemPrice {
    font-size: 16px;
  }

  .desktopCartLeft {
    border-right: 0;
  }

  .desktopCartLeftShadowSm {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.09) !important;
    border-radius: 5px;
  }
}
