.qrcode img {
  width: 100%;
}

.downloadbutton {
  background-color: #838d78 !important;
  color: #e5e0e0;
  font-weight: 600;
  border-radius: 6px;
  font-size: 16px !important;
}
