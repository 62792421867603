.horizontalCollectionCard {
  cursor: pointer;
}

.horizontalCollectionCard .icon {
  object-fit: cover;
}

.title {
  font-family: "sf_pro_displayregular";
  color: #b25661 !important;
  opacity: 0.6;
  margin-top: 10px;
}

.collectionCard {
  padding: 0px;
}
