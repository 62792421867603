.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product {
  padding: 0px;
}

.discountOff {
  font-family: 'sf_pro_displayregular';
  color: #b25661 !important;
}
.onAddCartRetailerTitle{
  width: 32em;
  font-family: 'sf_pro_displayregular';
}

.onAddCartPopup{
  width: 600px !important;
}

/* h5 {
 font-size: 18px !important;
} */

/* @media (max-width: 760px) {
    .product .item {
        width: 160px !important;
    }
} */

/* @media (max-width: 576px) {
    .product .item img {
        width: 160px !important;
        height: 160px !important;
    }
} */
