.shop-wine-page {
  // height: max-content;
  .nav-item {
    font-size: 20px;
    width: max-content !important;
  }
  .react-multiple-carousel__arrow {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px;
    min-height: 30px;
  }
  .react-multiple-carousel__arrow::before {
    font-size: 10px !important;
  }

  .som-tabs {
    .react-multi-carousel-list {
      // border-bottom: 1px solid #e6e6e6;
    }
    .react-multi-carousel-item {
      width: max-content !important;
    }
  }

  .bottom-border {
    border-bottom: 1px solid #e6e6e6;
  }
}
.shop-wine-page-skeleton {
  .card {
    width: 15vw;
  }
}

.shopWineTab {
  color: '#838d78';
  font-size: '24px';
  text-transform: 'none';
  font-family: 'new_york_extra_largemedium';
}

@media only screen and (max-width: 400px) {
  .shopWineTab {
    font-size: 18px;
  }
}

.grid-wrapper-shop-wine {
  --auto-grid-min-size: 13rem;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@media only screen and (max-width: 480px) {
  .grid-wrapper-shop-wine {
    --auto-grid-min-size: 8rem !important;
    grid-gap: 16px !important;
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr)) !important;
  }
}
.form-group:nth-last-child(1) {
  border-bottom: 0px !important;
}

.col-12.filter__heading {
  padding: 0px 8px;
  padding-top: 8px;
  .filter__heading__text {
    font-size: 20px;
    font-family: 'new_york_extra_largemedium';
    color: #000000;
  }
  .filter__heading__btn {
    color: #f0c787;
    font-size: 16px;
    font-family: 'sf_pro_displayregular';
    align-self: center;
    cursor: pointer;
  }
}
.form-group {
  .checkbox:checked:before {
    background-color: green;
  }
  .form-radio-label {
    font-weight: 400 !important;
  }
  // padding-right: 15px !important;
  input[type='checkbox']:checked {
    background-color: var(--bg-secondary, #b25661) !important;
    accent-color: #b25661;
    cursor: pointer;
  }
  input[type='radio']:checked {
    background-color: var(--bg-secondary, #b25661);
    cursor: pointer;
    accent-color: #b25661;
  }
  label.mb-1 {
    font-size: 20px;
    font-family: 'new_york_extra_largemedium';
    color: #838d78;
  }
}

.catalogues-filter {
  width: 250px;
  max-width: 250px;
  min-width: 100px;
  position: relative;
  transition: all 0.8s ease;
  height: max-content;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  .slider {
    position: relative;
    width: 100%;
    height: 50px;
  }

  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }

  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 5px;
  }

  .slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
  }

  .slider__range {
    background-color: #9fe5e1;
    z-index: 2;
  }

  .slider__left-value,
  .slider__right-value {
    color: #dee2e6;
    font-size: 12px;
    margin-top: 20px;
  }

  .slider__left-value {
    left: 6px;
  }

  .slider__right-value {
    right: -4px;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
  }

  .thumb--zindex-3 {
    z-index: 3;
  }

  .thumb--zindex-4 {
    z-index: 4;
  }

  .thumb--zindex-5 {
    z-index: 5;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
}
