@import '../node_modules/font-awesome/css/font-awesome.min.css';
@import './App-custom.scss';
@import './fonts.scss';
@import './scss/icon-style.scss';
@import './fontSize.scss';
@import 'react-phone-input-2/lib/style.css';
.isFavColor {
  color: #ad282b;
}

iframe {
  display: none;
}

#payment-form {
  width: 440px;
  align-items: center;
  position: relative;
  #card-button {
    position: absolute;
    bottom: 44px;
  }
  button {
    background: #b25661;
    font-family: Arial, sans-serif;
    color: #fff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0 4px 5.5px 0 rgb(0 0 0 / 7%);
    width: 100%;
  }
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }

  .spinner {
    color: #ffffff;
    font-size: 18px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 18px;
    height: 18px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }

  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }

  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  iframe {
    display: block !important;
    #card-number-wrapper {
      display: flex;
      flex-direction: column !important;
      height: inherit !important;
      justify-content: space-around !important;
    }
  }
  .sq-card-iframe-container {
    height: auto !important;
    iframe {
      height: 100% !important;
    }
  }
}

.FeedCard-module_userDateDiv__1kGfn {
  display: none !important;
}

@media (min-width: 576px) {
  .container-main-app {
    padding-top: 60px;
  }
}

.event-page .carousel .slide img {
  height: 19vw;
  object-fit: cover;
}

@media only screen and (max-width: 991px) {
  .container-main-app {
    padding-top: 75px;
  }
}
.container-main-app {
  padding-top: 90px;
  // min-height: 100vh;
}
.carousel .slide img {
  // height: 23vh;
  height: auto;
}

@media (max-width: 576px) {
  .event-page .carousel .slide img {
    height: 29vw;
    object-fit: cover;
 }
}

@media (max-width: 1024px) {
  .event-page .carousel .slide img {
    height: 28vw;
    object-fit: cover;
 }
}

@media only screen and (max-width: 800px) {
  .carousel .slide img {
    // height: 23vh;
    height: auto;
  }
  .in-desktop {
    display: none !important;
  }
  .in-mobile {
    display: block !important;
  }
}
.in-desktop {
  display: block;
}
.in-mobile {
  display: none;
}
element.style {
  flex: 1 1 auto;
  position: relative;
  width: auto;
}
.react-multi-carousel-item {
  // width: auto !important;
}
.wineTipsCollection {
  padding: 0px;
  // margin: 10px !important;
}
::-webkit-scrollbar {
  width: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
.react-multiple-carousel__arrow {
  z-index: 10 !important;
  position: absolute;
  right: 1px !important;
  background: #ffffff !important;
  height: 105px !important;
  color: #000 !important;
  width: 67px;
  border-radius: 100px 0 0 100px !important;
  box-shadow: -3px 0 5px rgb(0 0 0 / 30%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-multiple-carousel__arrow::before {
  font-size: 2.5vw !important;
  font-weight: bold !important;
  color: #000 !important;
}

.react-multiple-carousel__arrow--left {
  display: none;
}
footer {
  position: sticky;
  bottom: 0;
  width: 100%;
}
.footer-links li a {
  font-family: sf_pro_text_light !important;
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

input ~ .checkmark {
  background-color: #e2e2e2;
}
.customcheckbox:hover input ~ .checkmark {
  background-color: #e2e2e2;
}

// @media (min-width: 992px) {
//   .shop-wine-page {
//     .col-lg-3 {
//       -webkit-box-flex: 0;
//       -ms-flex: 0 0 25%;
//       flex: 0 0 20% !important;
//       max-width: 20% !important;
//     }
//   }
// }
.wine-categorires .top-rated-img-cont a {
  border: 0px !important;
}
.popup-close-btn {
  position: absolute;
  right: -15px;
  top: -14px;
  width: 30px;
  height: 30px;
  background: black;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  text-align: center;
  display: flex;
  cursor: pointer;
  padding: 5px;
}
.common-prompt {
  .lb-title {
    color: #000000;
  }
}
.react-loading-skeleton {
  line-height: unset !important;
}

.top-sommelier-profileOneLine {
  font-size: 12px !important;
  font-weight: normal !important;
}

.footer-links li a {
  color: #ffffff;
  font-size: 14px !important;
  font-weight: lighter;
}
.copy-right {
  font-size: 12px !important;
  color: #ffffff;
}

.nav-icon img {
  width: 20px !important;
  height: 20px !important;
}

.nav-icon-user img {
  width: 24px !important;
  height: 24px !important;
}
#dynamicForm {
  margin-bottom: 50px !important;
  height: auto !important;
}
