.contactUs {
  padding: 0px;
}

.contactUs .header {
  font-family: 'new_york_extra_largeregular';
  color: #838d78;
}

.contactUs .tabs a {
  width: 120px !important;
  font-family: 'new_york_extra_largeregular' !important;
  font-size: 22px !important;
  text-align: center;
}

.contactUs .tabs::after {
  background-color: #b25661 !important;
}

.commonForm {
  padding: 0px;
}

.commonForm .label {
  font-family: 'new_york_extra_largeregular';
  font-size: 20px;
  color: #838d78;
  line-height: 24px;
}

/* .commonForm input {
  height: 80px !important;
} */

.commonForm .submitButton {
  color: #ffffff;
  background-color: #838d78;
  margin-top: 0px !important;
  height: 60px !important;
}
.faqSection {
  font-family: 'new_york_extra_largeregular';
  font-size: 24px;
  color: #838d78;
  font-weight: bold;
}

.divider {
  /* margin-bottom: 80px; */
}

.faqCard {
  padding: 0px;
  background-color: #fff !important;
}

.faqCard .cardHeader {
  color: #000000 !important;
  font-family: 'sf_pro_displayregular' !important;
  font-size: 20px !important;
}

.faqCard .cardBody {
  color: #838d78 !important;
  font-family: 'sf_pro_displayregular' !important;
  font-size: 20px !important;
  line-height: 30px !important;
}

.commonForm .success {
  margin-bottom: 20px;
  color: green;
  text-align: center;
  font-size: 20px;
}

.newLine {
  white-space: pre-line;
}

@media (max-width: 840px) {
  .contactUs .tabs a {
    font-size: 22px !important;
  }

  .commonForm input {
    height: 60px !important;
  }
  .faqSection {
    font-size: 20px !important;
    margin-bottom: 16px;
  }

  .faqCard .cardHeader {
    font-size: 18px !important;
  }

  .faqCard .cardBody {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .commonForm .submitButton {
    height: 60px !important;
  }
}

@media (max-width: 580px) {
  .contactUs .header h2 {
    color: #838d78;
  }

  .contactUs .tabs a {
    font-size: 18px !important;
  }

  .commonForm input {
    font-size: 16px !important;
    height: 48px !important;
  }
  .faqSection {
    font-size: 18px !important;
    margin-bottom: 16px;
  }

  .faqCard .cardHeader {
    font-size: 16px !important;
  }

  .faqCard .cardBody {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  .commonForm .submitButton {
    height: 48px !important;
  }
}
