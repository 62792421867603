.search-page {
  top: 0;
  .desktop-search-bar-main-search {
    width: 100%;
    background: rgb(255, 255, 255);
    top: 0;
    display: flex;
    padding: 20px 0px 0px;
  }
  a:link {
    color: #838d78;
    background-color: transparent;
    text-decoration: none;
  }

  a:visited {
    color: #838d78;
    background-color: transparent;
    text-decoration: none;
  }
  .serach-results-div {
    position: absolute;
    width: 100%;
    .serach-results {
      width: 100%;
      background: #fff;
      overflow-y: auto;
      z-index: 99;
      -webkit-box-shadow: 0px 15px 39px 0px rgba(194, 194, 194, 0.5);
      -moz-box-shadow: 0px 15px 39px 0px rgba(194, 194, 194, 0.5);
      box-shadow: 0px 15px 39px 0px rgba(194, 194, 194, 0.5);
    }
  }
  // .grid-wrapper {
  //   --auto-grid-min-size: 12rem;
  //   display: grid;
  //   grid-gap: 24px;
  //   grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  //   margin: 0;
  //   padding: 0;
  //   box-sizing: border-box;
  // }
  // @media only screen and (max-width: 480px) {
  //   .grid-wrapper {
  //     --auto-grid-min-size: 7rem !important;
  //     grid-gap: 16px !important;
  //     grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr)) !important;
  //   }
  // }
}
.serach-results-list {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  overflow-y: auto;
  max-height: 400px;
}

.discountOff {
  font-family: 'sf_pro_displayregular';
  color: #b25661 !important;
}

@media (max-width: 768px) {
  .desktop-search-bar {
    height: 45px !important;
    width: 430px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }
  .desktop-search-bar input[type='text'] {
    width: calc(100% - 18px);
    border: 0;
    font-size: 16px !important;
    font-family: 'sf_pro_displaymedium';
    background: transparent;
  }
}
