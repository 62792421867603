@mixin banner-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin banner-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin banner-md {
  @media screen and (min-width: 1000px) {
    @content;
  }
}
@mixin banner-lg {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

.home-page-banner {
  @include banner-sx {
    // margin: 0px -15px;
  }

  .carousel-root {
    .carousel .slide img {
    }
  }
  //   @include banner-lg {
  //     height: 400px !important;
  //   }
  //   @include banner-md {
  //     height: 320px !important;
  //   }
  //   @include banner-sm {
  //     height: 200px !important;
  //   }
  //   @include banner-sx {
  //     height: 160px !important;
  //   }
  // }

  .banner-skeleton {
    @include banner-lg {
      height: 400px !important;
    }
    @include banner-md {
      height: 320px;
    }
    @include banner-sm {
      height: 200px !important;
    }
    @include banner-sx {
      height: 160px !important;
    }
  }
}
h3 {
  letter-spacing: 1px !important;
  font-family: new_york_extra_largeregular !important;
}
