.cartButtons .btn .btnPrimary {
    font-size: 14px;
  }
  
  .btnPrimary {
    color: #fff;
    background-color: #b25661;
    border: 0;
    height: 48px;
    font-size: 14px;
    width: 100%;
    border-radius: 0;
  }

  .__border_right{
    border-right: 1px solid #dee2e6;
  }
  @media screen and (max-width: 768px) {
    /* Your mobile-specific CSS styles go here */
    .__border_right{
   border-right: 0px;
    }
    // .button-bar {
    //   position: fixed;
    //   bottom: -16px;
    //   right: 0;
    //   left: 0;
    //   z-index: 99999;
    //   width: -webkit-fill-available;
    // }
    
  }