// * {
//   font-size: 2.933333333333333vw;
//   font-family: 'Times New Roman', Times, serif;
// }
#main .nav-link {
  padding: 0.7em;
}

.nav-link .fa {
  color: black;
  font-size: large;
}

.nav_img {
  height: 5.333333333333334vw;
}

#sommlikenavsm {
  width: 10.666666666666668vw;
}

/* side menu styling */

#sideMContainer {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 0;
  position: fixed;
  /* Stay in place */
  z-index: 99;
  /* Sit on top */
  right: 0;
  top: 0;
  background-color: rgb(0, 0, 0);
  /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.6);
  /* Black w/opacity */
  overflow-x: hidden;
  /* Disable horizontal scroll */
  /* transition: 0.1s ease-out; 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.4s;
  padding-top: 2.666666666666667vw;
}

#sideMenu_icon {
  width: 10.666666666666668vw;
  margin: 5.333333333333334vw;
}

.sideM_icon {
  width: 7.199999999999999vw;
  height: 6.666666666666667vw;
  padding: 0.8vw 1.3333333333333335vw;
  display: inline-block;
  border-radius: 0.8vw;
  background-color: #f2f2f2;
  margin-right: 0.8vw;
}

.sidenav-brand {
  width: 11.466666666666667vw;
  margin: 5.333333333333334vw;
}

#sideMenu_icon {
  width: 10.666666666666668vw;
  margin: 5.333333333333334vw;
}

.sideM_icon {
  width: 7.199999999999999vw;
  height: 6.666666666666667vw;
  padding: 0.8vw 1.3333333333333335vw;
  display: inline-block;
  border-radius: 0.8vw;
  background-color: #f2f2f2;
  margin-right: 0.8vw;
}

.sidenav a {
  padding: 2.1333333333333333vw 2.1333333333333333vw 2.1333333333333333vw 5.333333333333334vw;
  text-decoration: none;
  font-size: 4vw;
  color: #000;
  display: block;
  transition: 0.3s ease-in;
}

.sidenav a:hover,
.sidenav a:active,
.sidenav a:visited {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 6.666666666666667vw;
  font-size: 9.6vw;
  margin-left: 13.333333333333334vw;
}

/* side menu style ends here */

#featured_top {
  /* min-height: 170px; */
  background-color: rgb(30 30 30);
  margin: 1.3333333333333335vw 0;
  color: #fff;
  /* padding:1.3333333333333335vw 0; */
}

.top-gap {
  margin-top: 5.333333333333334vw;
}

.padding-top-bottom {
  padding: 1.866666666666667vw 0;
}

.padding-sides {
  padding: 1.3333333333333335vw 2.666666666666667vw;
}

.full-width-image {
  padding-right: 0 !important;
}

/* carousel style */

.crsl-text-container {
  padding: 1.3333333333333335vw;
}

.crsl-text-container h4 {
  font-weight: 600;
}

.crsl-desc {
  display: block;
  color: rgb(109, 109, 109);
  margin-bottom: 2.666666666666667vw;
}

.card-bottom {
  padding: 1.3333333333333335vw;
  background-color: #fdf8ec;
}

.sml-txt {
  display: block;
  font-size: 2.666666666666667vw;
  color: rgb(109, 109, 109);
}

.card-bottom .goldstar {
  color: #f0c787;
}

.owl-carousel .item a {
  text-decoration: none;
  color: #000;
}

.crsl-txt {
  display: block;
  font-size: 4vw;
}

.trending-collections {
  margin: 4.266666666666667vw;
  position: relative;
  background-color: #fdf8ec;
  border-radius: 2.666666666666667vw;
  overflow: hidden;
}

.bar-text {
  display: inline;
  clear: right;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.bar-text h3 {
  font-size: 5.866666666666666vw;
}

.img-trending-collection {
  margin-right: 4.533333333333333vw;
  width: 26.666666666666668vw;
}

.person-img-collection {
  margin-right: 1.3333333333333335vw;
  width: 9.333333333333334vw;
}

.name-trnd-collection {
  font-size: 3.733333333333334vw;
  font-weight: 600;
}

.black-back {
  background-color: black;
  margin: 2.666666666666667vw 0;
  color: #fff;
}

.black-back h2 {
  color: #ceb277;
}

.top-rated-desc {
  display: block;
  color: rgb(109, 109, 109);
  font-size: 2.666666666666667vw;
}

.top-rated-text-container h4 {
  font-weight: 500;
  padding: 1.3333333333333335vw 0 0 0;
  margin: 0;
  font-size: 3.733333333333334vw;
}

.top-rated-text-container {
  margin-bottom: 4vw;
}

.img-container {
  overflow: hidden;
  border-radius: 1.866666666666667vw 1.866666666666667vw 0 0;
  border: 1px solid #d0d0c1;
  border-bottom: none;
}

.top-rated-images {
  width: 100%;
}

.add-cart {
  cursor: pointer;
  text-align: center;
  width: 100%;
  background-color: #ceb277;
  color: black;
  padding: 2.666666666666667vw;
  border-radius: 0 0 1.866666666666667vw 1.866666666666667vw;
  font-weight: 600;
  font-size: 4.266666666666667vw;
}

.add-cart-black {
  cursor: pointer;
  text-align: center;
  width: 100%;
  background-color: #000;
  color: #ceb277;
  padding: 2.666666666666667vw;
  border-radius: 0 0 1.866666666666667vw 1.866666666666667vw;
  font-weight: 600;
  font-size: 4.266666666666667vw;
}

.wine-mood-img {
  position: relative;
}

.wine-mood-text-container {
  position: absolute;
  width: 80%;
  bottom: 2.666666666666667vw;
  padding: 1.3333333333333335vw 2.666666666666667vw;
  background-color: #fff;
  font-size: 3.2vw;
  display: inline-block;
  text-align: center;
  transform: translateX(-50%);
  left: 50%;
  font-weight: 700;
}

.terroir-text-container {
  color: rgb(112, 112, 112);
  padding-top: 0.8vw;
}

table #wineTips tr td:nth-child(1) {
  width: 12vw;
}

#wineTipsImg {
  width: 9.866666666666667vw;
  margin-right: 2.1333333333333333vw;
}

.add-fav {
  font-size: 18px;
}

/* sommelier */

.sommelier {
  padding: 0;
}

.header-sommelier {
  font-size: 6.933333333333333vw;
  text-transform: capitalize;
  color: #000;
  font-family: 'new_york_extra_largeregular';
}

.sommelier .two-tab {
  padding: 0px;
}

.sommelier .two-tab ul {
  padding: 0 2.666666666666667vw;
  border-bottom: 0.8vw solid #ccc;
  margin-bottom: 0px;
}

.sommelier .two-tab ul li {
  display: inline-block;
}

.sommelier .two-tab ul li a {
  display: block;
  color: #000;
  opacity: 0.4;
  font-size: 4.8vw;
  padding: 4vw 4vw 4vw 0px;
  position: relative;
  top: 0.8vw;
  font-family: 'new_york_extra_largeregular';
}

.sommelier .two-tab ul li a.active {
  color: #000;
  border-bottom: 0.8vw solid #f0c787;
  opacity: 1;
}

.sommelier .two-tab ul li a:hover {
  text-decoration: none;
}

.bg-banner-color {
  background-color: rgb(30 30 30) !important;
}

.banner-bottom-items {
  padding: 2.666666666666667vw 4vw;
}

.banner-bottom-items .padding-right {
  padding-right: 2vw;
}

.banner-bottom-items .padding-left {
  padding-left: 2vw;
}

.banner-bottom-items .item {
  margin-bottom: 4vw;
}

.banner-bottom-items .item img {
  margin-bottom: 2.666666666666667vw;
}

.banner-bottom-items .item h4 {
  font-size: 4.266666666666667vw;
  line-height: 5.066666666666666vw;
  font-family: 'sf_pro_displayregular';
}

.banner-bottom-items .item p {
  font-size: 3.2vw;
  line-height: 3.733333333333334vw;
  text-align: left;
  font-family: 'sf_pro_displayregular';
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.4;
}

/*sommelier ends*/

.search_sommelier .header h2 {
  font-family: 'new_york_extra_largemedium';
  font-size: 5.866666666666666vw;
  margin-bottom: 4vw;
}

.search_sommelier .search-bar {
  margin-bottom: 1.6vw;
}

.search_sommelier .search-bar .form-control {
  font-family: sf_pro_displaymedium;
  border-radius: 1.0666666666666667vw;
  opacity: 1;
  font-size: 4.8vw;
  color: #000 !important;
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  height: 12.8vw;
}

.search_sommelier .search-bar .input-group-text {
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  height: 12.8vw;
  cursor: pointer;
}

.search_sommelier .search-bar .form-control::placeholder {
  color: #000;
  opacity: 1;
}

.search_sommelier .search-bar .form-control:-ms-input-placeholder {
  color: #000;
}

.search_sommelier .search-bar .form-control::-ms-input-placeholder {
  color: #000;
}

/*sommelier ends*/

.search_sommelier_filter {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.search_sommelier_filter .fiter {
  height: 93.33333333333333vw;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #fff;
  border-radius: 2.666666666666667vw 2.666666666666667vw 0 0;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.search_sommelier_filter .fiter .header {
  padding: 4vw 4vw;
  border-bottom: 0.5333333333333333vw solid #f7f7f7;
  display: flex;
  justify-content: space-between;
}

.search_sommelier_filter .fiter .header h2 {
  font-size: 6.933333333333333vw;
  font-family: 'new_york_extra_largemedium';
  line-height: 9.066666666666666vw;
  margin-bottom: 0px;
}

.search_sommelier_filter .fiter .header a {
  font-size: 4.8vw;
  color: #f0c787;
  line-height: 9.066666666666666vw;
}

.search_sommelier_filter .fiter .content {
  display: flex;
  justify-content: flex-start;
}

.search_sommelier_filter .fiter .content .flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  border-right: 0.5333333333333333vw solid #f7f7f7;
}

.search_sommelier_filter .fiter .content .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #f0c787;
  background-color: #fff;
}

.search_sommelier_filter .fiter .content .nav-pills .nav-link {
  color: #000;
  font-size: 4.266666666666667vw;
}

.search_sommelier_filter .fiter .content ul {
  padding-left: 4vw;
  margin: 0px;
}

.search_sommelier_filter .fiter .content ul li {
  list-style: none;
}

.search_sommelier_filter .fiter .content ul li a {
  color: #000;
  font-size: 4.266666666666667vw;
  text-decoration: none;
  display: inline-block;
  padding: 0.5rem 1rem;
  width: 100%;
}

.search_sommelier_filter .fiter .content ul li a.active {
  color: #f0c787;
}

.search_sommelier_filter .fiter .content ul li span {
  float: right;
  margin-top: 0;
  background: #f0c787;
  height: 5.333333333333334vw;
  width: 5.333333333333334vw;
  border-radius: 50%;
  color: #fff;
  padding: 0;
  position: absolute;
  right: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sommelier_explore {
  padding: 2.666666666666667vw 4vw;
}

.sommelier_explore .product-desc {
  padding: 2.666666666666667vw 2.666666666666667vw 2.666666666666667vw 2.666666666666667vw;
}

.sommelier_explore .product-desc h4 {
  margin: 0px;
  font-size: 4.266666666666667vw;
  color: #000;
}

.sommelier_explore .profile {
  display: flex;
  justify-content: space-around;
}

.sommelier_explore .profile .tab1 {
  width: 13.333333333333334vw;
}

.sommelier_explore .profile .tab1 img {
  width: 10.666666666666668vw;
  border-radius: 50%;
}

.sommelier_explore .profile .tab2 {
  width: 66.66666666666666vw;
}

.sommelier_explore .profile .tab2 a {
  color: #000;
  text-decoration: none;
}

.sommelier_explore .profile .tab2 h4 {
  margin: 0px;
  font-size: 4.266666666666667vw;
}

.sommelier_explore .profile .tab2 p {
  margin: 0px;
  font-size: 3.2vw;
  color: #332f2f;
  opacity: 0.4;
}

.sommelier_explore .profile .tab3 {
  text-align: right;
  float: right;
}

.sommelier_explore .profile .tab3 h4 {
  color: #000000;
  opacity: 0.4;
  font-size: 3.733333333333334vw;
}

.sommelier_explore .post h4 {
  font-size: 4.266666666666667vw;
  margin-bottom: 0;
}

.sommelier_explore .post p {
  font-size: 3.733333333333334vw;
  color: #000000;
  opacity: 0.4;
}

.sommelier_explore .post img {
  border: 1.3333333333333335vw;
  width: 100%;
  margin-bottom: 2.666666666666667vw;
}

.nav-link {
  display: block;
  /* padding: .5rem .7rem !important;
      line-height: 6.666666666666667vw; */
  position: relative;
}

/* .nav-link span {
  background: #F0C787;
  color: #000;
  width: 5.333333333333334vw;
  height: 5.333333333333334vw;
  border-radius: 50%;
  padding: 0.8vw 1.866666666666667vw;
  line-height: 3.4666666666666663vw;
  position: absolute;
  top: 0px;
  left: 5.866666666666666vw;
} */

.p-right {
  padding-right: 2vw !important;
}

.p-left {
  padding-left: 2vw !important;
}

/* #featured_top {
  background-image: url("assets/banner.png");
  background-position: left bottom;
  background-position-y: 0;
  background-size: cover;
} */

#featured_top h4 {
  font-size: 4.8vw;
  font-family: 'new_york_extra_largeregular';
  margin-bottom: 1.3333333333333335vw;
}

#featured_top p {
  margin-bottom: 1.3333333333333335vw;
}

#featured_top .btn-group-sm > .btn {
  padding: 1.0666666666666667vw 2.666666666666667vw !important;
  font-size: 3.2vw !important;
  line-height: 5.866666666666666vw !important;
  border-radius: 1.3333333333333335vw !important;
}

/*SommelierDetailVersion-1*/

.sommelier-details-v1 {
  padding: 0px;
  margin: 0px;
  // margin-bottom: 5.333333333333334vw;
}

.sommelier-details-v1 .p-box .bg {
  background-image: url('assets/MaskGroup28.png');
  background-size: cover;
  height: 40vw;
  padding-top: 120px;
  margin-bottom: 21.333333333333336vw;
}

.sommelier-details-v1 .p-box .profile-box {
  display: flex;
  justify-content: flex-start;
  padding: 0 4vw;
  /* position: relative; */
  /* padding-top: 110px; */
}

.sommelier-details-v1 .p-box .profile-box .left {
  width: 26.666666666666668vw;
  height: 26.666666666666668vw;
}

.sommelier-details-v1 .p-box .profile-box .left img {
  width: 24vw;
  height: 24vw;
}

.sommelier-details-v1 .p-box .profile-box .right {
  padding-top: 10.666666666666668vw;
}

.sommelier-details-v1 .p-box .profile-box .right h4 {
  font-size: 4.8vw;
  font-family: 'new_york_extra_largemedium';
  color: #000;
  margin-bottom: 0px;
}

.sommelier-details-v1 .p-box .profile-box .right p {
  font-size: 3.2vw;
  color: #000;
  opacity: 0.4;
}

.sommelier-details-v1 .p-box .follower-box {
  padding: 0;
  display: flex;
  // box-shadow: -1px 0px 4.8vw 0.8vw rgba(204, 204, 204, 0.75);
  // -webkit-box-shadow: -1px 0px 1.0666666666666667vw 0px rgb(204 204 204 / 75%);
  // -moz-box-shadow: -1px 0px 4.8vw 0.8vw rgba(204, 204, 204, 0.75);
  // border: 1px solid #f7f7f7;
}

.sommelier-details-v1 .p-box .follower-box .numbers {
  padding: 4vw;
  text-align: center;
}

.sommelier-details-v1 .p-box .follower-box .numbers h4 {
  font-family: 'new_york_extra_largemedium';
  margin-bottom: 0px;
}

.sommelier-details-v1 .p-box .follower-box .numbers h5 {
  font-family: 'new_york_extra_largemedium';
  margin-bottom: 0px;
  font-size: 4.266666666666667vw;
}

.sommelier-details-v1 .p-box .btn-follow {
  background: black;
  color: #f0c787;
  font-size: 4.266666666666667vw;
  width: 100%;
  display: block;
  padding: 3.733333333333334vw;
  text-align: center;
  margin-top: 5.333333333333334vw;
  border-radius: 1.3333333333333335vw;
}

.sommelier-details-v1 .about {
  padding-top: 4vw;
}

.sommelier-details-v1 .about h4 {
  font-size: 4.8vw;
  font-family: 'new_york_extra_largemedium';
  margin-bottom: 1.3333333333333335vw;
}

.sommelier-details-v1 .about p {
  font-size: 3.733333333333334vw;
  opacity: 0.8;
}

.sommelier-details-v1 .tags {
  padding: 0px;
}

.sommelier-details-v1 .tags .btn-light {
  background: #fafafa;
  color: #797979;
  border: 1px solid #d2d2d2;
  border-radius: 2.1333333333333333vw;
  opacity: 1;
  margin-bottom: 2.666666666666667vw;
  margin-right: 2.1333333333333333vw;
  font-size: 3.733333333333334vw;
}

.sommelier-details-v1 .social {
  padding: 2.666666666666667vw 0;
  display: flex;
  justify-content: flex-start;
}

.sommelier-details-v1 .social .social-icons {
  padding-right: 2.666666666666667vw;
}

.sommelier-details-content h2 {
  font-size: 4.8vw;
  font-family: 'new_york_extra_largemedium';
}

.wine-header {
  font-size: 4.8vw;
  margin-top: 1.3333333333333335vw;
}

.black-back2 {
  background-color: black;
  margin: 2.666666666666667vw 0;
  color: #fff;
}

.black-back2 h2 {
  color: #ffffff;
  padding: 5.333333333333334vw 0 0 0;
}

.black-back2 .sommelier {
  margin-bottom: 4vw;
}

.black-back2 .sommelier .two-tab ul li a {
  display: block;
  color: #fff;
  opacity: 0.4;
  font-size: 4.8vw;
  padding: 4vw 4vw 4vw 0px;
  position: relative;
  top: 0.8vw;
  font-family: 'sf_pro_displaymedium';
}

.black-back2 .sommelier .two-tab ul li a.active {
  color: #f2f2f2;
  border-bottom: 0.8vw solid #f0c787;
  opacity: 1;
}

.sommeliers-details-banner .text {
  padding-left: 4vw;
  padding-top: 4vw;
  padding-bottom: 4vw;
  color: #fff;
}

.sommeliers-details-banner .text h4 {
  font-size: 3.2vw;
  color: #f2f2f2;
  margin-bottom: 1.3333333333333335vw;
}

.sommeliers-details-banner .text h2 {
  margin-bottom: 0px;
  font-size: 3.4666666666666663vw;
  color: #f0c787;
}

.sommeliers-details-banner .text h5 {
  margin-bottom: 1.3333333333333335vw;
  font-size: 3.2vw;
}

.sommeliers-details-banner .text p {
  font-size: 2.666666666666667vw;
  color: #f2f2f2;
  margin-bottom: 1.3333333333333335vw;
}

.sommeliers-details-banner .text span {
  font-size: 4.266666666666667vw;
  color: #fff;
}

.sommeliers-details-banner .text button {
  color: #f0c787;
  width: 10.666666666666668vw;
  border: none;
  font-size: 5.866666666666666vw !important;
  margin-right: 2.666666666666667vw;
  margin-top: 0.8vw;
  padding: 0.8vw 0.8vw !important;
}

.sommeliers-details-banner .text {
  background: #1e1e1e;
  min-height: 38.13333333333333vw;
}

.sommeliers-details-banner .img2 {
  background: #1e1e1e;
  min-height: 38.13333333333333vw;
}

.sommeliers-details-banner .img2 img {
  width: 53.333333333333336vw;
  min-height: 48vw;
  float: right;
}

.my-collection-sommeliers-details .header {
  display: flex;
  justify-content: flex-start;
  padding: 4vw;
  padding-bottom: 0px;
}

.my-collection-sommeliers-details .header .left {
  width: 10.666666666666668vw;
}

.my-collection-sommeliers-details .header h2 {
  font-size: 5.866666666666666vw;
  font-family: 'new_york_extra_largemedium';
  margin-bottom: 0px;
}

.my-collection-sommeliers-details .header p {
  font-size: 4.266666666666667vw;
  font-family: 'sf_pro_displayregular';
  color: rgb(109, 109, 109);
}

.my-collection-sommeliers-details .header-2 {
  padding: 4vw;
  padding-bottom: 1.3333333333333335vw;
}

.my-collection-sommeliers-details .header-2 span {
  margin-right: 1.3333333333333335vw;
  color: #ccc;
}

.my-collection-sommeliers-details .header-2 h4 {
  font-size: 4.8vw;
  font-family: 'new_york_extra_largeregular';
}

.wine-tips-box {
  padding: 4vw;
}

.wine-tips-box .content {
  -webkit-box-shadow: 0px 0px 4vw 1.3333333333333335vw rgb(0 0 0 / 18%);
  box-shadow: 0.5333333333333333vw 0.5333333333333333vw 0.5333333333333333vw 0px rgb(0 0 0 / 6%);
  border-radius: 1.6vw;
  border: 1px solid #f2f2f2;
}

.wine-tips-box .content .desc {
  padding: 4vw;
}

.wine-tips-box .content .desc h4 {
  font-size: 4.266666666666667vw;
}

.wine-tips-box .content .desc p {
  color: #000000;
  opacity: 0.4;
  font-size: 3.733333333333334vw;
}

.wine-tips-box .content .desc .date {
  font-size: 3.2vw;
  color: rgb(109, 109, 109);
  text-align: right;
  padding-top: 2.1333333333333333vw;
}

.wine-tip-owl-img {
  width: 6.666666666666667vw !important;
  display: inline-block !important;
}

.heart-active {
  color: #ad2828 !important;
}

/*Produc Screen*/

.product-sceen {
  padding: 0px;
  margin: 0px;
}

.product-sceen .slider {
  position: relative;
}

.product-sceen .slider .owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  bottom: 5.333333333333334vw;
  left: 40%;
}

.product-sceen .slider .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #f0c787;
}

.product-sceen .slider .owl-theme .owl-dots .owl-dot span {
  width: 2.666666666666667vw;
  height: 2.666666666666667vw;
  margin: 1.3333333333333335vw 1.866666666666667vw;
  background: #ffffff66;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 8vw;
}

.product-sceen .owl-theme .owl-nav {
  margin-top: 2.666666666666667vw;
  display: none;
}

.product-sceen .heart {
  width: 12vw;
  height: 12vw;
  font-size: 6.933333333333333vw;
  background: #fdf8ec96;
  color: #fff;
  position: absolute;
  bottom: 5.333333333333334vw;
  right: 5.333333333333334vw;
  z-index: 1;
  border-radius: 50%;
  padding: 1.3333333333333335vw 2.4vw;
}

.product-sceen .active {
  color: red !important;
}

.product-sceen .description {
  padding: 4vw;
}

.product-sceen .description h2 {
  font-size: 5.333333333333334vw;
  line-height: 6.4vw;
}

.product-sceen .description p {
  font-size: 4.266666666666667vw;
  line-height: 4.266666666666667vw;
}

.product-sceen .choose-size {
  padding: 0 4vw;
  padding-bottom: 4vw;
}

.peragraph-p {
  font-size: 3.733333333333334vw;
  color: #ccc;
}

.product-sceen .choose-size .btn-select {
  width: 21.333333333333336vw;
  height: 10.666666666666668vw;
  border-radius: 6.666666666666667vw;
  border: none;
  font-size: 3.733333333333334vw;
  margin-right: 2.666666666666667vw;
}

.product-sceen .choose-size .btn-light {
  background: #f7f7f7 !important;
  color: #c6c6c6 !important;
}

.product-sceen .choose-size .out-stock {
  color: red;
  font-size: 2.1333333333333333vw;
  margin-top: 0.8vw;
  display: block;
  padding-left: 2.666666666666667vw;
}

.product-sceen .choose-size .btn-active {
  background: #000 !important;
  color: #f0c787 !important;
}

.product-sceen .details {
  padding: 0 4vw;
}

.recmmended-product {
  padding: 2.666666666666667vw 4vw;
  background: #000;
  color: #f0c787;
  font-size: 3.733333333333334vw;
  margin: 0 -5.333333333333334vw;
}

.recmmended-product p {
  margin-bottom: 0px;
  font-size: 3.733333333333334vw;
}

.product-sceen .size {
  padding: 0 4vw;
  padding-top: 4vw;
}

.product-sceen .tasting-note {
  padding: 0 4vw;
  margin-bottom: 5.333333333333334vw;
}

.product-sceen .tasting-note h4 {
  font-size: 4.8vw;
}

.product-sceen .size .value-button {
  display: flex;
  border: 1px solid #ddd;
  margin: 0px;
  width: 6.4vw;
  height: 6.4vw;
  text-align: center;
  vertical-align: middle;
  padding: 0 0;
  background: #eee;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 50%;
  font-size: 4.266666666666667vw;
  align-items: center;
  justify-content: center;
}

.product-sceen .size .value-button:hover {
  cursor: pointer;
}

.product-sceen .size form {
  display: flex;
  align-items: center;
}

.product-sceen .size form #decrease {
  margin-right: 0;
}

.product-sceen .size form #increase {
  margin-left: 0;
  background: #000;
  color: #f0c787;
}

.product-sceen .size form #input-wrap {
  margin: 0px;
  padding: 0px;
}

.product-sceen .size input#number {
  text-align: center;
  border: none;
  border-top: none;
  border-bottom: none;
  margin: 0px;
  width: 10.666666666666668vw;
  height: 6.4vw;
  font-size: 4.8vw;
}

.product-sceen .size input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add-cart-btn {
  height: 12.533333333333333vw;
  background: #000;
  color: #f0c787;
  font-size: 4.266666666666667vw;
  text-align: center;
  border: none;
  padding: 2.666666666666667vw 4vw;
  border-radius: 1.0666666666666667vw;
  display: block;
  width: 100%;
  margin-top: 5.333333333333334vw;
}

.add-cart-btn:hover,
add-cart-btn:focus {
  color: #f0c787;
  text-decoration: none;
}

.go_to_cart {
  height: 12.533333333333333vw;
  background: #000;
  color: #f0c787;
  font-size: 4.266666666666667vw;
  text-align: center;
  border: none;
  padding: 2.666666666666667vw 4vw;
  border-radius: 1.0666666666666667vw;
  display: block;
  width: 100%;
  margin-top: 5.333333333333334vw;
}

.go_to_cart:hover,
.go_to_cart:focus {
  color: #f0c787;
  text-decoration: none;
}

.margine-zero {
  margin-bottom: 0px;
}

.product-recommended {
  padding: 2.666666666666667vw 4vw;
}

.product-recommended .flex {
  padding-top: 1.3333333333333335vw;
}

.product-recommended .flex .left {
  margin-right: 2.666666666666667vw;
  float: left;
  width: 10.666666666666668vw;
  height: 10.666666666666668vw;
}

.product-recommended .flex .right {
  float: left;
}

.product-recommended .flex .right span {
  margin-left: 1.3333333333333335vw;
}

.product-recommended .flex .right h4 {
  margin-bottom: 0.5333333333333333vw;
  font-size: 4.8vw;
}

.product-recommended .flex .right p {
  font-size: 3.733333333333334vw;
  color: #000000;
  opacity: 0.4;
}

.height-330 {
  height: 109.33333333333333vw !important;
}

.pp-padding-left {
  padding-left: 0px !important;
}

.pp-padding-right {
  padding-right: 0px !important;
}

.mm-margin {
  margin: 0px !important;
}

.show-filter {
  display: none;
  transition-delay: 5s;
}

.color-red {
  color: #ad2828;
}

.tablerounededCorner {
  border: 1px solid #dee2e6;
  border-radius: 1.2em;
  background: rgba(0, 0, 0, 0.03);
  margin-bottom: 6.666666666666667vw;
}

.tablerounededCorner p {
  font-size: 3.2vw;
  margin: 0px;
  color: #ccc;
}

.tablerounededCorner h4 {
  font-size: 4.266666666666667vw;
  margin: 0px;
}

.roundedTable {
  border-collapse: collapse;
  border-radius: 1.2em;
  overflow: hidden;
  width: 100%;
  margin: 0;
}

.cart-fixed {
  position: sticky;
  bottom: 15px;
  width: 100%;
  background: #fff;
  padding: 0px 05.333333333333334vw 0;
}

.cart-fixed:before {
  content: '';
  position: absolute;
  top: 100%;
  right: 0;
  bottom: -15px;
  z-index: 1;
  left: 0;
  background-color: #ffffff;
}

.liked-wine-title {
  font-size: 5.866666666666666vw;
  font-family: 'new_york_extra_largemedium';
  color: #282f39;
}

.winter-special-owl .owl-carousel .owl-nav button span {
  font-size: 5.333333333333334vw !important;
  line-height: 4.533333333333333vw;
  display: inline-block;
  position: relative;
  top: -1px;
}

.winter-special-owl .owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  background: #000;
  color: #f0c787;
  height: 5.333333333333334vw;
  width: 5.333333333333334vw;
  border-radius: 50%;
  right: 2.666666666666667vw;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.winter-special-owl .owl-theme .owl-nav {
  top: -12.8vw;
  position: absolute;
  width: 100%;
  z-index: -9;
}

.winter-special-owl .owl-carousel .owl-nav button.owl-prev {
  display: none;
}

.fa-heart {
  color: #ad2828;
  cursor: pointer;
}

#size {
  color: rgb(0, 0, 0);
}

#size.color {
  background: #000 !important;
  color: #f0c787 !important;
}

#size1 {
  color: rgb(0, 0, 0);
}

#size1.color {
  background: #000 !important;
  color: #f0c787 !important;
}

#size2 {
  color: rgb(0, 0, 0);
}

#size2.owl-nav {
  background: #000 !important;
  color: #f0c787 !important;
}

#size-list {
  list-style: none;
  padding: 0px;
  display: flex;
  margin-bottom: 0px;
}

#size-list li {
  display: inline-block;
}

#size-list li a {
  width: 21.333333333333336vw;
  height: 10.666666666666668vw;
  border-radius: 6.666666666666667vw;
  border: none;
  font-size: 3.733333333333334vw;
  margin-right: 2.666666666666667vw;
  background-color: rgb(239, 239, 239);
  color: #000;
  padding: 9px 5px;
  display: block;
  text-align: center;
  text-decoration: none;
}

#size-list li a.active {
  background: #000 !important;
  color: #f0c787 !important;
  text-decoration: none;
}

.cart-counter {
  display: inline-block;
  width: 20px;
  opacity: 0;
  height: 20px;
  background: #ddd;
  text-align: center;
  border-radius: 50%;
  margin-left: 10px;
}

#cart-count {
  background: #f0c787;
  color: #000;
  width: 5.333333333333334vw;
  height: 5.333333333333334vw;
  border-radius: 50%;
  padding: 0.8vw 1.866666666666667vw;
  line-height: 3.4666666666666663vw;
  position: absolute;
  top: 0px;
  left: 5.866666666666666vw;
  display: none;
}

.bell-span {
  background: #f0c787;
  color: #000;
  width: 5.333333333333334vw;
  height: 5.333333333333334vw;
  border-radius: 50%;
  padding: 0.8vw 1.866666666666667vw;
  line-height: 3.4666666666666663vw;
  position: absolute;
  top: 0px;
  left: 5.866666666666666vw;
}
