@font-face {
  font-family: newyork extralarge;
  src: url(../../fonts/newyorkextralargemedium-webfont.woff);
}

@mixin section--padding-top-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin card-top-sommelier-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin card-top-sommelier-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin card-top-sommelier-md {
  @media screen and (max-width: 1399px) {
    @content;
  }
}
@mixin card-top-sommelier-lg {
  @media screen and (min-width: 1400px) {
    @content;
  }
}
.card-top-sommelier {
  @include card-top-sommelier-lg {
    width: 195px !important;
  }
  @include card-top-sommelier-md {
    width: 195px !important;
  }
  @include card-top-sommelier-sm {
    width: 180px !important;
  }
  @include card-top-sommelier-sx {
    width: 160px !important;
  }
}

.sommelier img {
  object-fit: cover !important;
  border-radius: 8px;
  width: 100%;
  @include card-top-sommelier-lg {
    height: 240px !important;
  }
  @include card-top-sommelier-md {
    height: 240px !important;
  }
  @include card-top-sommelier-sm {
    height: 220px !important;
  }
  @include card-top-sommelier-sx {
    height: 200px !important;
  }
}

.card-top-sommelier-skeleton {
  @include card-top-sommelier-lg {
    width: 230px;
    margin-right: 24px;
  }
  @include card-top-sommelier-md {
    width: 210px;
    margin-right: 24px;
  }
  @include card-top-sommelier-sm {
    width: 180px;
    margin-right: 24px;
  }
  @include card-top-sommelier-sx {
    width: 160px;
    margin-right: 24px;
  }
}

.text-ellipsis-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: sf_pro_displayregular !important;
  text-transform: capitalize;
}

.text-ellipsis-name-sub-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}
.carousel-container {
  height: auto;
  width: 100%;
}
.carousel-container-wines-by-terroir {
  height: auto;
  width: 100%;
}
.carousel-container-wines-by-moment {
  height: auto;
  width: 100%;
}

.trending-collection {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000033;
  border-radius: 8px;
  opacity: 1;
}

.top-sommelier-collection-name {
  font-family: new_york_extra_largeregular;
  letter-spacing: 0.3px;
  color: #b25661;
  font-size: 16px;
}

.landing-modal {
	.modal-content {
		width: 84%;
		margin: 0 auto;
	}
	select {
		option {
			position: relative;
		}
		border: none;
		width: 100%;
		padding: 12px 10px;
		border-radius: 4px;
		background-color: #F2F2F2;
		color: #707070;
		font-family: sf_pro_displayregular !important;
		border-right: 10px solid transparent;
		&:focus {
			background-color: #F2F2F2;
			color: #000;
		}
		&:valid {
			color: #9a9a9a !important;
			padding: 12px 6px;
			letter-spacing: .03em;
			font-weight: inherit;
			opacity: .8;
			cursor: pointer;
		}
		&:focus-visible {
			outline: none;
		}
	}
	input {
		&::placeholder {
			color: #9a9a9a !important;
			font-weight: 500;
			opacity: 1 !important;
		}
		border: none;
		width: 100%;
		padding: 12px 10px;
		border-radius: 4px;
		background-color: #F2F2F2;
		color: #707070;
		font-family: sf_pro_displayregular !important;
		&:focus {
			background-color: #F2F2F2;
			color: #000;
		}
	}
	.modal-body {
		text-align: center;
		padding-top:22px;
	}
	.modal-footer {
		display: none;
	}
	.modal-header {
		text-align: center;
		padding: 20px 0;
		.btn-close {
			right: -16px;
			position: absolute;
			border-radius: 50%;
			font-size: 36px;
			display: block;
			background: #000;
			padding: 0px 12px 0 14px;
			border: none;
			top: -18px;
			color: #EFC68B;
			width: 36px;
			height: 36px;
			&:after {
				content: "\00d7";
				position: absolute;
				top: -12px;
				right: 6px;
				font-weight: 100;
			}
		}
	}
	.modal-title {
		width: 100%;
		font-family: newyork extralarge;
	}
	h4 {
		font-family: newyork extralarge;
		font-size: 28px;
	}
}
.get-started-btn {
	width: 100%;
	padding: 0 0;
	margin: 22px 0 16px 0;
	background-color: #838D78;
	font-size: 16px;
	font-family: sf_pro_displayregular !important;
}
.existing-login {
	color: #b25661;
	font-size: 16px;
	font-family: sf_pro_displayregular !important;
	border: none;
	background-color: transparent;
	button {
		color: #b25661;
		font-size: 16px;
		font-family: sf_pro_displayregular !important;
		border: none;
		background-color: transparent;
	}
	&:hover {
		button {
			font-weight: 600;
		}
	}
}
