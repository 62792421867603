/********** feed Page Inside Start *********/

.feedDetailCard {
}
.feedDetailCard .user {
  display: flex;
  cursor: pointer;
}

.feedDetailCard .user img {
  border-radius: 100px;
  height: 50px;
  width: 50px;
}

.feedDetailCard .text {
  font-family: 'sf_pro_displaymedium';
  font-size: 16px;
  padding-left: 16px;
  color: #8a937f;
  padding-top: 8px;
  width: 160px;
}
.feedDetailCard small {
  font-size: 12px;
  color: #000000;
  opacity: 0.4;
}
.feedDetailInside {
}

.feedDetailInside h5.cardTitle {
  color: #8a937f;
  font-size: 28px !important;
  font-family: 'new_york_extra_largemedium';
  /* margin-bottom: 32px; */
  /* margin-top: 32px; */
}

.feedDetailInside div.cardText {
  font-family: 'sf_pro_displaymedium';
  color: #000000;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 16px;
  /* opacity: 0.4; */
}

.feedDetailInsideBanner {
  max-height: 300px;
  text-align: center;
}

.feedDetailInsideBanner img {
  width: 100% !important;
  height: 300px;
  object-fit: scale-down;
}

.wineBlock {
  background: #fafafa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
}

.wineBlockCont {
  overflow: hidden;
  padding-left: 15px;
}

.wineBlockImg {
  width: 90px;
  height: 90px;
  float: left;
  overflow: hidden;
  border-radius: 8px;
}

.wineBlockCont p,
.wineBlockCont a {
  font-size: 16px;
  margin-bottom: 0;
}

.wineBlockCont p {
  color: #b25661;
}

.wineBlockCont a {
  word-break: break-all;
  line-height: 15px;
}

.wineBlockContTitle {
  font-size: 16px;
  font-family: 'sf_pro_displayregular';
}

.wineBlockCont h5 {
  font-family: 'sf_pro_displayregular';
  font-size: 14px;
  margin-top: 5px;
}

/********** feed Page Inside End *********/

.wineTipsAvatar {
  height: 12rem;
  margin: 0px;
  object-fit: cover;
}

.wineTipsAvatar img {
  border-radius: 10px 10px 0px 0px;
}

.wineTipsCard {
  cursor: pointer;
  margin: 0px;
  width: 20rem;
  height: 22rem !important;
}

.wineTipsCard .shadowSm {
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.09) !important;
}

.wineTipsCardTitle {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.8em;
  margin-right: 0.4em;
  color: #8a937f;
  /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
  max-width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 48px;
}

.wineTipsCardText {
  font-size: 1rem;
  font-weight: 300;
  margin-left: 0.8em;
  color: #b25661;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.colStyle {
  color: var(--text-secondary, #3b8396);
  vertical-align: middle;
  padding-bottom: 0.5rem;
}

.wineTipsRowDisplay {
  display: block;
}

.wineTipsUserDateDiv {
  justify-content: space-between;
  margin-left: 0.8em;
  margin-right: 0.8em;
  margin-top: 20px;
}

.wineTipsUserDateDiv .wineTipsUser {
  width: 100%;
  height: 40px;
  justify-content: space-between;
}

.wineTipsUserDateDiv .wineTipsUserImg {
  display: flex;
}

.wineTipsUserDateDiv .wineTipsUserImg img {
  width: 28px;
  height: 28px;
  border-radius: 100px;
}

.wineTipsUserDateDiv .wineTipsUserImg .wineTipsUserTitle {
  font-family: 'sf_pro_displayregular';
  margin-left: 8px;
  margin-top: 2px;
  font-size: 14px;
  color: #838d78;
}

.wineTipsUserDate {
  font-family: 'sf_pro_displayregular';
  font-size: 14px;
  font-weight: 300;
  margin-top: 4px;
  color: #838d78;
  text-align: right;
}
