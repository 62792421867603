.profile-edit-icon {
  display: none;
}
.profile-img {
  div {
    margin-bottom: 0;
  }
  &:hover {
    .profile-edit-icon {
      position: sticky;
      width: inherit;
      text-align: center;
      bottom: 0px;
      height: inherit;
      border-radius: 50%;
      background: #4a4a4acc;
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.updating-text {
  position: absolute;
  width: inherit;
  text-align: center;
  bottom: 0px;
  height: inherit;
  border-radius: 50%;
  background: #4a4a4acc;
  display: flex;
  flex-direction: column-reverse;
  font-size: 10px;
  .center-icon {
    position: relative;
    top: -32%;
    color: #b25661;
  }
}
.updating-text-coverPicture {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 10%;
  padding: 5px;
  .center-icon {
    position: relative;
    top: -32%;
    color: #b25661;
  }
}
