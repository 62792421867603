.myCollection-page {
  .nav-item {
    font-size: 20px;
    width: max-content !important;
  }

  .som-tab {
    .react-multi-carousel-item {
      width: max-content !important;
    }
  }
  .bottom-border {
    border-bottom: 1px solid #e6e6e6;
  }
}

// .subTitle div {
//   font-size: 20px !important;
// }

.button-empty {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0px;
  z-index: 1;
}

.padding-top-bottom {
  padding-top: 20px;
  padding-bottom: 80px;
}

@media screen and (max-width: 769px) {
  .common-prompt {
    padding: 10px !important;
    width: 280px !important;
    .lb-title {
      h5 {
        font-size: 16px !important;
      }
    }
  }
}
.szh-menu__item {
  font-size: 14px !important;
}
.fa-ellipsis-v {
  cursor: pointer;
}

.my-collection {
  .container-list-main {
    .__arrow__prev {
      left: 0px !important;
    }
    .__arrow__next {
      right: 0px !important;
    }
    button {
      margin: 0px !important;
    }
  }
}
