.collection-img {
  width: 100%;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.031372549);
}
.collection-edit-icon-on-hover {
  display: none;
}
.collection-img {
  div {
    margin-bottom: 0;
  }
  &:hover {
    .collection-edit-icon-on-hover {
      position: absolute;
      width: inherit;
      text-align: center;
      bottom: 0px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
}

.collection-edit-icon {
  position: absolute;
  width: inherit;
  text-align: center;
  bottom: 0px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collection-updating-text {
  position: absolute;
  width: inherit;
  text-align: center;
  bottom: 0px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  .center-icon {
    position: relative;
    top: -32%;
    color: #b25661;
  }
}
