.desktop-search-bar-result {
  position: absolute;
  width: 430px;
  background-color: white;
  z-index: 99;
  top: 104px;
  height: 150px;
  overflow: scroll;
  box-shadow: 0px 3px 5px 0px rgba(74, 74, 74, 0.75);
  -webkit-box-shadow: 0px 3px 5px 0px rgba(74, 74, 74, 0.75);
  -moz-box-shadow: 0px 3px 5px 0px rgba(74, 74, 74, 0.75);
  border-radius: 10px;
}
.search-result-item {
  display: flex;
  padding: 10px;
  cursor: pointer;
  .search-result-item-img {
    margin-left: 10px;
  }
  .search-result-item-name {
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.mobile-search-bar {
  height: 58px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);
  display: flex;
  align-items: center;
  padding: 20px;
  img {
    width: 20px;
  }
  input[type='text'] {
    width: calc(100% - 43px);
    margin-left: 25px;
    border: 0;
    font-size: 14px;
    font-family: 'sf_pro_displaymedium';
  }
  .mobile-search-bar-result {
    position: absolute;
    background-color: white;
    z-index: 99;
    top: 60px;
    height: 190px;
    overflow: scroll;
    box-shadow: 0px 3px 5px 0px rgb(74 74 74 / 75%);
    -webkit-box-shadow: 0px 3px 5px 0px rgb(74 74 74 / 75%);
    -moz-box-shadow: 0px 3px 5px 0px rgba(74, 74, 74, 0.75);
    border-radius: 10px;
  }
}

.searchBar {
  height: 58px !important;
}

@media (max-width: 830px) {
  .searchBar {
    height: 40px !important;
  }
}

@media (max-width: 576px) {
  .searchBar {
    height: 40px !important;
  }
}

.fixed-header {
  position: fixed;
  z-index: 99;
  background: white;
  width: 100%;
  // max-height: 165px;
}

.fadein,
.fadeout {
  opacity: 0;
  -moz-transition: opacity 0.9s ease-in-out;
  -o-transition: opacity 0.9s ease-in-out;
  -webkit-transition: opacity 0.9s ease-in-out;
  transition: opacity 0.9s ease-in-out;
}
.fadein {
  opacity: 1;
}

.search-hide {
  max-height: 0;
  transition: max-height 0.1s;
  overflow: hidden;
  margin-top: 0;
  transition-timing-function: ease-out;
}

.search-show {
  max-height: 500px;
  transition: max-height 1s;
  transition-timing-function: ease-in;
}
.active {
  .nav-link {
    color: #b25661 !important;
  }
}

.badge {
  position: absolute;
  display: inline-block;
  padding: 5px;
  font-size: 68%;
  font-weight: 100;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  margin-left: -7px;
  top: 7px;
  width: 20px;
  height: 20px;
}
@media only screen and (max-width: 800px) {
  .badge {
    width: 20px !important;
    height: 20px !important;
  }
}

#search-container {
  height: 0px;
  transition: all 0.4s ease-in;
}
#main ul.nav.nav-menu .nav-link {
  letter-spacing: 0.75px !important;
}
#main ul.nav.nav-menu .nav-link {
  font-family: sf_pro_text_light !important;
}
