@mixin product-details-img-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin product-details-img-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin product-details-img-md {
  @media screen and (max-width: 1200px) {
    @content;
  }
}
@mixin product-details-img-lg {
  @media screen and (min-width: 1201px) {
    @content;
  }
}

.product-details-img {
  img {
    object-fit: contain;
    object-position: bottom;
    border-radius: 8px;
    //   border-top-right-radius: 8px;
    border: 1px solid #dadad0;
    // height: 420px !important;
    width: 100% !important;
    //   border-bottom: 0px;
    @include product-details-img-lg {
      width: 100% !important;
      height: 380px !important;
    }
    @include product-details-img-md {
      width: 100% !important;
      height: 380px !important;
    }
    @include product-details-img-sm {
      border-radius: 0px;
      width: 100% !important;
      height: 380px !important;
    }
    @include product-details-img-sx {
      border-radius: 0px;
      width: 100% !important;
      height: 380px !important;
    }
  }
}

.product-info-div {
  background-color: #fafafa;
  border-radius: 0.8em;
  border: 1px solid #dee2e6;
  margin: auto;

  .tableTitle {
    font-family: 'sf_pro_displayregular';
    font-size: 16px;
    color:#838D78;
  }
  .tableTitle {
    font-size: 12px;
  }
  .tableText {
    font-family: 'sf_pro_displayregular';
    font-size: 16px;
    color: #000000;
  }
}
