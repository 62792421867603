html,
body {
  font-family: 'sf_pro_displayregular';
  color: #838d78;
  font-size: 16px;
  overflow-x: hidden;
}

input:focus {
  outline: none;
}

a {
  color: #838d78;
}

a:hover {
  color: #838d78;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.btn-primary {
  background: #838d78;
  color: #ffffff;
  border: 0;
  height: 48px;
  font-size: 16px;
}

#submitClick {
  font-size: 14px !important;
  background: #838d78 !important;
}
#resetClick {
  font-size: 14px !important;
  background: #f2f2f2 !important;
  color: #000000 !important  ;
}

.btn-primary:hover {
  background: #838d78;
}

.font-14 {
  font-size: 14px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.btn {
  font-family: 'sf_pro_displayregular';
}

button:focus {
  outline: 0;
}

::placeholder {
  color: #b25661;
  opacity: 0.75;
}

:-ms-input-placeholder {
  color: #b25661;
  opacity: 0.75;
}

::placeholder {
  color: #b25661;
  opacity: 0.75;
}

input[type='email'].form-control::placeholder,
.form-control::placeholder,
.form-control::focus,
textarea::placeholder {
  color: #000 !important;
  opacity: 0.75;
}

.form-control::placeholder {
  color: #000 !important;
  opacity: 0.75;
}

.form-control {
  color: #000 !important;
  opacity: 0.75;
}

.form-control:focus {
  box-shadow: none;
  border-color: #b25661;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'new_york_extra_largemedium';
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h4 {
  font-size: 18px;
}

html,
body {
  // height: 100vh;
  width: 100%;
}

#main ul.nav.nav-menu {
  height: 65px;
  align-items: center;
}

/* #main ul.nav .logo img{
    width: 38px;
} */

#main ul.nav .nav-link {
  padding: 12px;
}

#sideMContainer {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-x: hidden;
}

.sidenav-brand {
  /* width: 43px;
    margin: 20px; */
  margin: 0 8px;
  width: 100%;
}

.sidenav a {
  padding: 8px 8px 8px 20px;
  text-decoration: none;
  font-size: 16px;
  color: #838d78 !important;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in;
  font-family: 'new_york_extra_largemedium';
}

.sidenav span {
  height: 40px;
  width: 40px;
  background: #f7f7f7;
  display: flex;
  margin-right: 15px;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
}

.sidenav span img {
  max-width: 80%;
}

.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 100;
  top: 0;
  right: -250px;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.4s;
  padding-top: 10px;
}

/**** Custom check box start */

.customcheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'sf_pro_displayregular';
}

/* Hide the browser's default checkbox */

.customcheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  /* background-color: #cacaca; */
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */

.customcheckbox:hover input ~ .checkmark {
  background-color: #f7f7f7;
}

/* When the checkbox is checked, add a blue background */

.customcheckbox input:checked ~ .checkmark {
  background-color: #b25661;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.customcheckbox input:checked ~ .checkmark:after {
  display: block;
}

.customcheckbox input:checked ~ span.label {
  color: #b25661;
}

/* Style the checkmark/indicator */

.customcheckbox .checkmark:after {
  left: 9px;
  top: 4px;
  width: 6px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type='radio']:checked + label {
  font-weight: bold;
}

input[type='checkbox'] + label:checked {
  font-weight: bold;
}

/**** Custom check box end */

img {
  max-width: 100%;
}

/********** Home Page Start *********/

.home-banner {
  background-color: #1e1e1e;
  color: #fff;
  min-height: 200px;
  background-size: cover;
  background-position: center center;
}

.home-banner-cont-main {
  display: flex;
  font-family: 'new_york_extra_largeregular';
  padding: 9px 0 13px;
}

.home-banner-cont {
  max-width: 177px;
}

.home-banner-cont span,
.home-banner-cont p {
  font-size: 10px;
}

.home-banner-cont h4 {
  font-size: 16px;
  margin-bottom: 8px;
}

.home-banner-cont p {
  margin-bottom: 9px;
  line-height: 13px;
}

.home-banner-cont button {
  height: 24px;
  width: 85px;
  font-size: 10px;
  padding: 0;
  font-family: 'sf_pro_displaymedium';
}

.home-banner-img {
  position: absolute;
  right: 0;
  top: 0;
  bottom: -2px;
  overflow: hidden;
  border-radius: 100px 0 0 100px;
  width: 180px;
}

.explore-top-sommelier .owl-carousel {
  margin-left: 10px;
}

.product-card .card {
  border: 0;
  // padding: 0 5px 10px;
}

.product-card .card-body {
  padding: 10px 0 0 0;
}

.product-card .card-body p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.product-card h5 {
  font-family: 'sf_pro_displayregular';
  color: #838d78;
  margin-bottom: 0;
}

.exploresommelier h5 {
  position: relative;
  padding-right: 20px;
}

.product-card p {
  font-size: 14px;
  color: #b25661;
  margin-bottom: 8px;
  height: 25px;
  display: flex;
  display: -webkit-box;
  font-size: 14px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.experts-div .card {
  border: 0;
  padding: 0 5px 10px;
}

.experts-div .card-body {
  padding: 10px 0 0 0;
}

.experts-div .card-body p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.experts-div h5 {
  font-family: 'sf_pro_displaymedium';
  color: #838d78 !important;
  margin-bottom: 0;
}
.experts-div span {
  font-family: 'sf_pro_displayregular';
  color: #838d78 !important;
  margin-bottom: 0;
  font-size: 14px;
}
.exploresommelier h5 {
  position: relative;
  padding-right: 20px;
}

.experts-div p {
  font-size: 14px;
  color: #b25661;
  margin-bottom: 8px;
  height: 25px;
  display: flex;
  display: -webkit-box;
  font-size: 14px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.experts-div .explore-img {
  border-radius: 8px;
  overflow: hidden;
}

.experts-div .card-bottom {
  padding: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 8px rgb(0, 0, 0, 0.2);
  border-radius: 8px;
}

.experts-div .card-bottom div {
  font-size: 11px;
  padding-left: 8px;
}

.experts-div .card-bottom p {
  font-size: 14px;
  font-family: 'new_york_extra_largemedium';
  line-height: 15px;
  margin-top: 5px;
  margin-bottom: 0;
  padding-right: 10px;
  height: 40px;
}

.experts-div .goldstar {
  color: #f0c787;
  margin-right: 3px;
  position: relative;
}
.goldstar {
  color: #f0c787;
  margin-right: 3px;
  position: relative;
}
.experts-div .goldstar:before {
  /* background: url(./assets/icons/star.png) no-repeat left; */
  /* content: ''; */
  height: 8px;
  width: 8px;
  position: absolute;
  /* background-size: cover; */
  left: -10px;
  top: -9px;
  font-size: 10px;
}

.trending-collection-inner {
  margin-bottom: 15px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 0 8px rgb(0 0 0 / 20%);
  border-radius: 8px;
  overflow: hidden;
  height: 80px;
}

.trending-collection-inner > img {
  height: 80px;
}

.trending-collection-inner .ct-content {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  margin-left: 20px;
  top: 50%;
  margin-top: -30px;
}

.ct-right {
  display: flex;
  align-items: center;
}

.user-img {
  margin-right: 5px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.ct-right span {
  font-size: 14px;
  color: #b25661;
}

.top-rated-main {
  padding: 18px 0 0;
}

.wine-categorires .card {
  background: none;
  border: 0;
}

.wine-categorires .card-body {
  padding: 12px 0;
}

// .wine-categorires .top-rated-img-cont {
//   border-radius: 10px;
//   overflow: hidden;
//   border: 1px solid #dadad0;
// }

.wine-categorires .top-rated-img-cont a {
  cursor: pointer;
  text-align: center;
  background-color: #838d78;
  color: #ffffff;
  padding: 8px;
  display: block;
  border-radius: 0 0 4px 4px;
  border: 1px solid #dadad0;
  border-top: 0px;
}

.wine-categorires .card-body h5 {
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
  margin-bottom: 5px;
}

.wine-categorires .card-body h5:last-child {
  font-size: 14px;
}

.wine-categorires .card-body span {
  color: #b25661;
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
}

.wine-categorires .card-body .cart-title {
  padding-right: 30px;
  position: relative;
}

.wine-categorires .card-body .cart-title a.btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 16px;
  width: 16px;
  color: #999999;
  /* background: url(./assets/icons/like-gray-border.png) no-repeat */
}

.wine-categorires .card-body .cart-title a.btn i {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.wine-box-border .img-container {
  border: 1px solid #d0d0c1;
  border-bottom: 0;
}

a.btn i.fa.fa-heart {
  color: #ad2828;
}

.top-rated-text-container {
  position: relative;
}

.top-rated-text-container a.btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 16px;
  width: 16px;
  color: #999999;
}

.top-rated-text-container a.btn i.fa {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.top-rated-main .owl-carousel,
.wines-moments .owl-carousel,
.explore-wines .owl-carousel,
.wine-tips .owl-carousel {
  margin-left: 15px;
}

.wines-moments .owl-carousel .item {
  position: relative;
}

.wines-moments .owl-carousel .item .box-title {
  position: absolute;
  bottom: 15px;
  left: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 14px;
  padding: 2px;
  color: #b25661;
}

.valentines-collection {
  padding: 50px 0 20px;
  margin: 0 -15px;
}

.explore-wines .img-box {
  border-radius: 10px;
  overflow: hidden;
}

.explore-wines .box-title {
  color: #b25661;
  margin-top: 5px;
}

.wine-tips .section-title {
  padding-left: 75px;
  position: relative;
}

.wine-tips .section-title img {
  position: absolute;
  left: 0;
  top: -12px;
}

.wine-tips .section-title span {
  display: block;
  font-size: 14px;
  color: #b25661;
  font-family: 'sf_pro_displayregular';
  margin-top: 8px;
}

.wine-tips .explore-img img {
  height: 100%;
}

.wine-tips .blog-card h5.card-title {
  font-family: 'sf_pro_displaymedium';
  font-size: 17px;
}

/********** Home Page End *********/

/********** Collection with Picuter Start *********/

/* .cwp-banner {
    background-repeat: no-repeat;
    height: 180px;
    position: relative;
    background-position: center center;
    background-size: cover;
} */

.cwp-banner h2 {
  color: #fff;
  position: absolute;
  left: 15px;
  bottom: 0;
  font-size: 22px;
  font-family: 'new_york_extra_largeregular';
}

.intro {
  font-size: 16px;
  color: #b25661;
  line-height: 20px;
  padding: 20px 0;
}

.collectin-pic .user-date-div {
  font-family: 'sf_pro_displaymedium';
  font-size: 14px;
  margin-bottom: 10px;
}

.collectin-pic .user-date-div .user {
  align-items: center;
}

.collectin-pic .col-xs-6,
.sommelier_explore .col-xs-6,
.banner-bottom-items .col-xs-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}

.collectin-pic .col-xs-6:nth-child(even) {
  padding-right: 8px;
}

.collectin-pic .col-xs-6:nth-child(odd) {
  padding-left: 8px;
}

.sommelier_explore .col-xs-6:nth-child(even) {
  padding-left: 8px;
}

.sommelier_explore .col-xs-6:nth-child(odd) {
  padding-right: 8px;
}

// .banner-bottom-items .col-xs-6:nth-child(even) {
//   padding-left: 3px;
// }

// .banner-bottom-items .col-xs-6:nth-child(odd) {
//   padding-right: 3px;
// }

/* .collectin-pic .top-rated-img-cont a {
    background: #000;
    color: #F0C787;
} */

/* .collectin-pic .card-body {
    color: #000;
} */

.collectin-pic .card {
  border: 0;
}

.winter-wine-collection {
  padding-bottom: 60px;
}

.winter-wine-collection .page-title h2 {
  margin-bottom: 6px;
}

/********** Collection with Picuter End *********/

/********** My Cart Page Start *********/

.page-title h2 {
  margin-bottom: 16px;
  font-size: 25px;
  font-family: 'new_york_extra_largemedium';
}

.page-title.with-btn a.btn {
  background: #f0c787;
  color: #000000;
  height: 30px;
  width: 70px;
  font-size: 12px;
  padding: 4px;
  border-radius: 2px;
}

.card {
  border-radius: 8px;
}

.cart-card .card {
  border: 0;
}

.shadow-sm {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.08) !important;
}

.cart-card .card-title {
  font-size: 16px;
  /* color: #000000; */
  font-family: 'sf_pro_displaysemibold';
  margin-bottom: 8px;
}

.cart-card .card-body {
  padding: 17px;
}

.item-img {
  height: 80px;
  width: 80px;
  border-radius: 8px;
  margin-right: 12px;
  overflow: hidden;
}

.item-content-main {
  display: flex;
  justify-content: space-between;
}

.item-title {
  font-size: 16px;
  line-height: 14px;
}

.item-title {
  display: flex;
}

.item-content span {
  font-size: 12px;
  color: #b25661;
}

.item-price {
  color: #00c569;
  position: relative;
  top: -2px;
  font-size: 16px;
}

.qty-div {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.qty-box {
  position: relative;
  font-size: 14px;
}

.product-qty {
  appearance: textfield;
  appearance: none;
  margin: 0;
}

.qty-input {
  margin-top: 10px;
}

.qty-box button {
  position: absolute;
  top: 11px;
  font-style: normal;
  color: #4d4d4d;
  padding: 0 5px;
  font-size: 8px;
  border: 0;
  background: none;
}

.qty-box button.icon-minus {
  top: 3px;
  left: 0;
  height: 25px;
  width: 20px;
}

.qty-box button.icon-minus:before {
  content: '';
  height: 1px;
  width: 11px;
  background: #000000;
  position: absolute;
  top: 12px;
  left: 8px;
}

.qty-box button.icon-plus {
  right: 13px;
  top: 3px;
  height: 25px;
  width: 20px;
}

.qty-box button.icon-plus:before {
  content: '';
  height: 1px;
  width: 11px;
  background: #000000;
  position: absolute;
  top: 11px;
  left: 1px;
}

.qty-box button.icon-plus:after {
  content: '';
  height: 10px;
  width: 1px;
  background: #000000;
  position: absolute;
  left: 6px;
  top: 8px;
}

.qty-text {
  font-size: 13px;
  color: #9d9d9d;
  padding-right: 10px;
}

.qty-input input {
  background: #f3f3f3;
  width: 95px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  transition: 0.5s;
  border: 0;
  padding-left: 23px;
  padding-right: 23px;
}

.coupon-code {
  background: #fafafa;
  padding: 6px 15px;
  align-items: center;
}

.coupon-code a {
  font-size: 16px;
}

.coupon-code span {
  color: #f0c787;
}

.coupon-code b {
  color: #f0c787;
  font-weight: normal;
}

.coupon-code .disabled {
  color: #cccccc;
}

.coupon-code .form-control {
  border: 0;
  background: none;
  margin-right: 10px;
  color: #f0c787;
  padding-left: 0;
}

.delivery-address {
  align-items: center;
  margin-bottom: 8px;
}

.cart-card .delivery-address .card-title {
  margin-bottom: 0 !important;
}

.cart-card .delivery-address a {
  font-size: 16px;
}

.prices {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: #b25661;
}

.prices div {
  font-size: 16px;
}

.gtotal {
  display: flex;
  justify-content: space-between;
}

.gtotal div {
  font-size: 16px;
  font-family: 'sf_pro_displaymedium';
}

.cart-buttons .total {
  font-size: 18px;
  /* color: #000000; */
  font-family: 'sf_pro_displaymedium';
}

.cart-buttons .total span {
  display: block;
  color: #b25661;
  font-size: 14px;
}

.cart-buttons {
  justify-content: space-between;
  margin-top: 80px;
  // padding-bottom: 15px;
}

.cart-buttons button {
  max-width: 185px;
  width: 100%;
  height: 48px;
}

.cart-card.order-received .cart-buttons {
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 0 -2px 3px rgb(0, 0, 0, 0.1);
  padding: 15px;
  margin: 0 -8px;
}

.cart-card.order-received .cart-buttons button {
  max-width: 165px;
  width: 100%;
  height: 48px;
  margin: 0 8px;
}

.cart-card.order-received .cart-buttons button.btn-default {
  color: #838d78;
}

.delivery-address-cont {
  font-size: 16px;
  color: #b25661;
}

.paid-via {
  color: #75be00;
  font-size: 14px;
  text-align: right;
}

.cart-card.order-received {
  padding-bottom: 80px;
}

.cart-card.order-received .item-title,
.cart-card.order-received .item-price {
  font-family: 'sf_pro_displaysemibold';
}

.order-details span {
  font-size: 16px;
  color: #b25661;
}

.billing-address {
  border-top: 1px solid #f2f2f2;
  padding: 15px;
  margin-top: 10px;
  margin-left: -15px;
  margin-right: -15px;
  padding-bottom: 0;
}

.billing-address div {
  font-size: 16px;
  padding-bottom: 5px;
}

.billing-address span {
  font-size: 16px;
  color: #b25661;
}

.get-started-box {
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 15px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  /* display: none; */
}

.get-started-box .lb-title {
  // display: flex;
  // justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.get-started-box .lb-title a {
  font-size: 16px;
}

.get-started-box .lb-title h5 {
  font-size: 25px;
  font-family: 'new_york_extra_largemedium';
  margin-bottom: 0;
  text-align: center;
}

.get-started-box .btn {
  height: 48px;
  width: 100%;
  font-size: 14px;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
}

.get-started-box .btn.btn-primary {
  background: #838d78;
  color: #fff;
}

.get-started-box .btn img {
  max-width: 18px;
  margin-right: 15px;
}

.get-started-box .btn.apple {
  border: 1px solid #000;
  color: #000000;
}

.get-started-box .btn.gmail {
  color: #fff;
  background: #838d78;
}

.login-mask-layer {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  /* display: none; */
}

.mask-layer {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: none;
}

/********** My Cart Page End *********/

/********** Profile Page Start *********/

.register-div {
  padding-bottom: 15px;
}

.register-div .form-control::placeholder {
  color: #b25661;
  opacity: 0.75;
}

.register-div .form-control:-ms-input-placeholder {
  color: #b25661;
  opacity: 0.75;
}

.register-div .form-control::placeholder {
  color: #b25661;
  opacity: 0.75;
}

.register-div input[type='email'].form-control::placeholder {
  color: #666;
  opacity: 1;
}

.profile-pic div {
  font-size: 14px;
  font-family: 'new_york_extra_largeregular';
  max-width: 220px;
}

.profile-img {
  width: 90px;
  height: 90px;
  margin-top: 5px;
  background: #f7f7f7;
  border-radius: 100px;
  overflow: hidden;
}

.common-form {
  margin-top: 16px;
}

.form-sm-inline {
  display: flex;
  justify-content: space-between;
}

.form-sm-inline div {
  width: 48%;
}

.common-form label {
  /* color:#999999; */
  font-size: 12px;
  font-family: 'new_york_extra_largeregular';
  padding: 0 30px 0 0;
}

.common-form .form-control {
  border: 0;
  background-color: #00000008;
  border-radius: 4px;
  height: 48px;
  /* color: #000; */
  /* font-family: 'sf_pro_displaymedium'; */
  font-family: 'sf_pro_displayregular';
  font-size: 18px;
}

.phone-nmbr {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

.phone-nmbr input:first-child {
  margin-right: 10px;
  width: 20%;
}

.phone-nmbr input:last-child {
  width: 80%;
}

.phone-nmbr input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.common-form .checkmark {
  right: 0;
  left: auto;
}

.common-form .customcheckbox .checkmark:after {
  left: 8px;
  top: 4px;
  width: 6px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.common-form .customcheckbox {
  font-family: 'sf_pro_displaymedium';
  /* color: #666666; */
}

.common-form .customcheckbox.round {
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
  /* color: #000;  */
}

.common-form .customcheckbox.round .checkmark {
  border-radius: 100px;
  width: 20px;
  height: 20px;
}

.common-form .customcheckbox.round .checkmark:after {
  left: 8px;
  top: 6px;
  width: 4px;
  height: 8px;
}

.addtext-box-row .con {
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.addtext-box-row .con .btnRemove {
  background: url(./assets/icons/remove.png) no-repeat center center;
  height: 22px;
  width: 22px;
  margin-left: 10px;
}

.term-condition {
  text-align: center;
  font-size: 12px;
  max-width: 300px;
  margin: 100px auto 0;
}

.term-condition a {
  color: #282f39;
}

.common-form button.btn-primary {
  height: 48px;
  margin-top: 12px;
}

/********** Profile Page End *********/

/********** Pay Now Page Start *********/

.pay-now-form label {
  color: #999999;
  font-size: 10px;
  font-family: 'sf_pro_displaymedium';
  padding: 0;
  margin-bottom: 0;
  text-transform: uppercase;
}

.pay-now-form .form-group {
  margin-bottom: 2.5rem;
  width: 85%;
}

.pay-now-form .form-control {
  border: 0;
  background: none;
  height: 40px;
  border-bottom: 1px solid #ebebeb;
  padding: 0;
  font-family: 'sf_pro_displaymedium';
  font-size: 20px;
}

.date-cvv {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

.date-cvv input:first-child {
  margin-right: 10px;
  width: 20%;
}

.date-cvv input:last-child {
  width: 80%;
}

.pay-now-form .checkmark {
  right: 0;
  left: auto;
}

.pay-now-form .customcheckbox .checkmark:after {
  left: 9px;
  top: 4px;
  width: 6px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pay-now-form .customcheckbox {
  font-family: 'sf_pro_displaymedium';
  color: #666666;
  text-transform: capitalize;
  font-size: 15px;
}

.pay-now-form button.btn-primary {
  height: 48px;
  font-size: 14px;
  margin-top: 100px;
}

/********** Pay Now Page End *********/

/********** Profile Page Start *********/

/* .my-profile-link {
    color: #000000;
} */

.my-profile-link span img {
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

/********** Profile Page End *********/

/********** Notificatin Page Start *********/

.notification-div {
  margin-bottom: 25px;
}

.noti-img {
  border-radius: 100px;
  height: 40px;
  width: 40px;
  margin-right: 15px;
  float: left;
}

.noti-cont {
  float: none;
  overflow: hidden;
}

.noti-title {
  justify-content: space-between;
}

.noti-title b {
  font-size: 16px;
  font-family: 'sf_pro_displaymedium';
  font-weight: normal;
}

.noti-cont span {
  font-size: 14px;
  color: #b25661;
  line-height: 10px;
}

.noti-title span {
  font-size: 10px;
  color: #838d78;
}

/********** Notificatin Page End *********/

/********** Blog Page Start *********/
.nav-item {
  cursor: pointer;
}
#main .nav.nav-menu .nav-item .page-title h2 {
  margin-bottom: 0;
  padding-left: 12px;
}

/* .blog-tabs {
     margin-top: -10px;
 } */

.common-tabs .nav-tabs {
  border-bottom: 3px solid #f2f2f2;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
}

.common-tabs .nav-tabs .nav-link {
  border: 0;
  font-family: 'new_york_extra_largeregular' !important;
  color: #cdd1c9;
  padding: 0 0 5px !important;
  margin: 0 8px;
  position: relative;
}

.common-tabs .nav-tabs .nav-link:first-child {
  margin-left: 15px !important;
}

.common-tabs .nav-tabs .nav-item.show .nav-link,
.common-tabs .nav-tabs .nav-link.active {
  color: #838d78 !important;
  font-weight: bold;
}

.common-tabs .nav-tabs .nav-item.show .nav-link::after,
.common-tabs .nav-tabs .nav-link.active::after {
  background: #b25661;
  position: absolute;
  content: '';
  height: 5px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: -2px;
}

.blog-card {
  margin-bottom: 15px;
  overflow: hidden;
}

.blog-card h5.card-title {
  font-size: 18px;
  font-family: 'new_york_extra_largemedium';
  margin-bottom: 3px;
}

.blog-card p.card-text {
  color: #b25661;
  line-height: 18px;
  font-size: 15px;
}

.blog-card .card-body {
  padding: 15px;
}

.user-date-div {
  justify-content: space-between;
  align-items: center;
}

.blog-card .user-date-div .user-img {
  width: 28px;
  height: 28px;
  border-radius: 100px;
  overflow: hidden;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-card .user-date-div .user {
  font-size: 14px;
  font-family: 'sf_pro_displaymedium';
  align-items: center;
}

.blog-card .user-date {
  font-size: 12px;
  /* color: #999999; */
}

/********** Blog Page End *********/

/********** Blog Page Inside Start *********/

.blog-card-inside {
  margin-left: -15px;
  margin-right: -15px;
}

.blog-card-inside h5.card-title {
  font-size: 22px;
  font-family: 'new_york_extra_largemedium';
  margin-bottom: 3px;
}

.blog-card-inside p.card-text {
  color: #b25661;
  line-height: 18px;
  font-size: 16px;
}

.blog-card-inside-banner {
  height: 400px;
  text-align: center;
  background: #f6f0e2;
  margin-left: -15px;
  margin-right: -15px;
}

.blog-card-inside-banner img {
  max-width: 670px;
}

.wine-block {
  background: #fafafa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
}

.wine-block-cont {
  overflow: hidden;
  padding-left: 15px;
}

.wine-block-img {
  width: 90px;
  height: 90px;
  float: left;
  overflow: hidden;
  border-radius: 8px;
}

.wine-block-cont p,
.wine-block-cont a {
  font-size: 16px;
  margin-bottom: 0;
}

.wine-block-cont p {
  color: #b25661;
}

.wine-block-cont a {
  word-break: break-all;
  line-height: 15px;
}

.wine-block-cont-title {
  font-size: 16px;
  font-family: 'sf_pro_displayregular';
}

.wine-block-cont h5 {
  font-family: 'sf_pro_displayregular';
  font-size: 14px;
  margin-top: 5px;
}

/********** Blog Page Inside End *********/

/********** Contact us and FAQ page Start *********/

.common-form select.form-control:not([size]):not([multiple]) {
  height: 48px;
}

.common-form select option {
  font-size: 16px;
}

.common-form select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  position: relative;
}

select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, #acacac 50%),
    linear-gradient(135deg, #acacac 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 8px), calc(100% - 15px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image: linear-gradient(45deg, #acacac 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #acacac 50%);
  background-position: calc(100% - 15px) calc(1em + 8px), calc(100% - 20px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: #acacac;
  outline: 0;
}

#faq {
  margin: 0px 0px;
}

#faq .card {
  border: 0;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0;
}

#faq .card .card-header {
  border: 0;
  padding: 0;
}

#faq .card .card-header .btn-header-link {
  display: block;
  text-align: left;
  background: #fafafa;
  /* color: #222; */
  white-space: initial;
  position: relative;
  font-size: 16px;
  font-family: 'sf_pro_displayregular';
  padding: 15px 15px 0;
  line-height: 20px;
}

#faq .card .card-header .btn-header-link.collapsed {
  padding: 17px 30px 17px 15px;
}

#faq .card .card-header .btn-header-link:after {
  border: solid #838d78;
  border-width: 0 2px 2px 0;
  padding: 4px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  content: '';
  float: right;
  top: 40%;
  position: absolute;
  right: 15px;
}

#faq .card .card-header .btn-header-link.collapsed {
  background: #fff;
}

#faq .card .card-header .btn-header-link.collapsed:after {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#faq .card .collapsing {
  background: #fafafa;
  line-height: 30px;
}

#faq .card .collapse {
  border: 0;
}

#faq .card .collapse.show {
  background: #fafafa;
  line-height: 30px;
  color: #222;
}

#faq .card .card-body {
  font-size: 16px;
  color: #b25661;
  // padding-top: 5px;
  line-height: 20px;
}

/********** Contact us and FAQ page End *********/

/********** Notification page Start *********/

.notifications-settings-div {
  padding-bottom: 63px;
}

.notifications-settings-div .checkmark {
  left: auto;
  right: 0;
}

.notifications-settings-div .rmr {
  font-size: 12px;
  font-family: 'sf_pro_displaymedium';
  color: #b25661;
}

.notifications-settings-div .customcheckbox {
  font-size: 16px;
  font-family: 'sf_pro_displaymedium';
  padding-left: 0;
  margin-top: 24px;
}

.notifications-settings-save {
  position: absolute;
  bottom: 15px;
}

.notifications-settings-save .btn {
  height: 48px;
  font-size: 16px;
}

/********** Notification page End *********/

/********** My Address page Start *********/

.address-card .card {
  border: 0;
}

.address-card .card-title h5 {
  font-size: 16px;
  /* color: #000000; */
  font-family: 'sf_pro_displaymedium';
  margin-bottom: 8px;
}

.address-card .card-title a {
  font-size: 14px;
}

.address-card .card-title b {
  font-size: 14px;
  font-weight: normal;
  color: #999999;
}

.address-card .card-body {
  padding: 17px;
}

.address-card .card p {
  font-size: 16px;
  color: #b25661;
}

.address-card .card-title {
  font-size: 16px;
  font-family: 'sf_pro_displaymedium';
  margin-bottom: 0;
}

.address-card .card-title span {
  color: #ff0000;
  font-size: 16px;
}

.address-card .btn.btn-default {
  height: 48px;
  font-size: 14px;
  background: none;
  border: 1px solid #838d78;
  color: #838d78;
  width: 48%;
}

.address-card .btn.btn-primary {
  height: 48px;
  font-size: 14px;
  width: 48%;
}

.my-addess-btn {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
}

.my-addess-btn .btn {
  height: 48px;
  width: 100%;
  font-size: 14px;
}

/********** My Address page End *********/

/********** Refer Friend page Start *********/

div#main {
  height: 100%;
}

.refer-frnd-main {
  height: 100%;
}

.refer-frnd-main .row {
  height: 100%;
}

.refer-frnd-banner {
  height: 235px;
  background: url(./assets/refer_friend.png) no-repeat;
  background-size: cover;
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px;
}

.refer-frnd-banner p {
  font-size: 25px;
  font-family: 'new_york_extra_largemedium';
  line-height: 30px;
  color: #ffffff;
}

.refer-proccess {
  padding-bottom: 100px;
}

.refer-proccess li {
  list-style: none;
  margin-bottom: 25px;
}

.refer-proccess li .nmbr {
  background: #838d78;
  height: 24px;
  width: 24px;
  text-align: center;
  color: #ffffff;
  border-radius: 100px;
  float: left;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
}

.refer-proccess li:not(:last-child) .nmbr:before {
  content: '';
  position: absolute;
  top: 20px;
  left: 11px;
  height: 50px;
  width: 2px;
  background: #838d78;
  z-index: -1;
}

.refer-proccess li p {
  font-size: 14px;
  padding: 0;
  margin: 0;
  color: #b25661;
}

.invitecode {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
}

.invitecode span {
  color: #838d78;
  font-size: 18px;
}

.invitecode div {
  color: #b25661;
  font-size: 18px;
}

.invitecode .btn {
  height: 48px;
  width: 100%;
  margin-top: 10px;
}

.invitecode .two-btn button:first-child {
  margin-left: 0 !important;
}

.invitecode .two-btn button:last-child {
  margin-right: 0 !important;
}

.switch-sommelier {
  position: absolute;
  bottom: 0;
}

/********** Refer Friend page End *********/

/********** Order History page Start *********/

.ordr_hsty-card .card {
  border: 0;
  margin-bottom: 20px;
}

.ordr_hsty-card .card-title {
  font-size: 16px;
  /* color: #000000; */
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.ordr_hsty-card .card-title h5 {
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
}

.ordr_hsty-card .card-body {
  padding: 17px;
  border-radius: 8px;
}

.ordr_hsty-card .oh-date-div {
  font-size: 14px;
  color: #b25661;
  margin-bottom: 10px;
}

.ordr_hsty-card .card-title a.btn {
  background: #f0c787;
  color: #000000;
  height: 30px;
  width: 70px;
  font-size: 12px;
  padding: 4px;
  border-radius: 2px;
}

.ordr_hsty-card .card-title a.btn-green {
  background: #58b100;
  color: #fff;
  height: 30px;
  width: 70px;
  font-size: 12px;
  padding: 4px;
  border-radius: 2px;
}

.ordr_hsty-card .card-title .item-price {
  top: 0;
  margin-top: 4px;
}

.ordr_hsty-card .cart-items .item-img {
  width: 60px;
  height: 60px;
}

.ordr_hsty-card .cart-buttons {
  margin-top: 15px;
  margin-left: -8px;
  margin-right: -8px;
}

.ordr_hsty-card .cart-buttons .btn {
  /* color: #F0C787; */
  margin: 0 8px;
}

.ordr_hsty-card .cart-buttons .btn-default {
  color: #838d78;
}

/********** Order History page End *********/

/********** Profile page Start *********/

.profile-div {
  margin-bottom: 20px;
}

.profile-div .profile-pic {
  align-items: center;
}

.profile-div .profile-img {
  margin-right: 20px;
}

.profile-div .profile-pic h2 {
  margin-bottom: 5px;
  font-family: 'sf_pro_displayregular';
  font-size: 25px;
}

.profile-div .profile-pic span {
  font-size: 14px;
  font-family: 'sf_pro_displayregular';
  line-height: 18px;
  display: block;
  color: #b25661;
}

.profile-tabs {
  margin-top: 10px;
}

.profile-tabs a {
  padding: 12px 0;
  text-decoration: none;
  font-size: 16px;
  color: #000 !important;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in;
  font-family: 'new_york_extra_largeregular';
  font-size: 20px;
  position: relative;
}

.profile-tabs a:after {
  content: '';
  border: solid #838d78;
  border-width: 0 2px 2px 0;
  padding: 4px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  content: '';
  float: right;
  top: 50%;
  position: absolute;
  right: 15px;
}

.profile-tabs a.login:after {
  display: none;
}

.profile-tabs span {
  height: 40px;
  width: 40px;
  background: #f7f7f7;
  display: flex;
  margin-right: 40px;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  padding: 9px;
}

.profile-tabs li {
  list-style: none;
  width: 100%;
}

/********** Profile page End *********/

/********** Choose location page Start *********/

.map-div {
  background: url(./assets/map-bg.png) no-repeat center center;
  background-size: cover;
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px;
  height: 100vh;
  margin-bottom: -15px;
}

.map-address-details {
  background: #fff;
  padding: 15px;
}

.address-input {
  display: flex;
  align-items: center;
}

.address-input input.form-control {
  border: 0;
}

.address-input input.form-control:focus {
  box-shadow: none;
}

.search-icon {
  width: 22px;
  border: 0;
  background: no-repeat;
  padding: 0;
}

.location-round-icon {
  width: 24px;
}

.map-address-details p,
.map-address-details p a {
  font-size: 14px;
  /* color: #999999; */
  margin-top: 23px;
  margin-bottom: 5px;
}

.map-top {
  margin-bottom: 20px;
}

.location-icon-div {
  position: absolute;
  z-index: 999;
  width: 26px;
  bottom: 30px;
  margin-left: 30px;
  left: 50%;
}

/********** Choose location page End *********/

/********** Add address page Start *********/

.address-main,
.address-main .row {
  height: 100%;
}

.add-address-form {
  padding: 15px;
  background: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  right: 0;
}

.add-address {
  margin-top: -75px;
  position: relative;
  z-index: -1;
}

.add-address-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.add-address-title a {
  font-size: 16px;
}

.add-address-title h2 {
  font-size: 25px;
  font-family: 'new_york_extra_largemedium';
  margin-bottom: 0;
}

.add-address-title .title-inner span {
  font-size: 16px;
  /* color: #acacac; */
  font-family: 'new_york_extra_largemedium';
}

.add-address-form form .form-control {
  height: 48px;
  background: #f7f7f7;
  border: 0;
  margin-bottom: 15px;
  font-size: 18px;
  font-family: 'sf_pro_displayregular';
}

/* .add-address-form form .form-control::placeholder {
    color: #acacac;
    opacity: 1;
}
.add-address-form form .form-control:-ms-input-placeholder {
    color: #acacac;
}
.add-address-form form .form-control::placeholder {
    color: #acacac;
} */

.add-address-form .checkmark {
  left: auto;
  right: 0;
}

.add-address-form .customcheckbox {
  padding-left: 0;
  font-size: 16px;
  /* color: #666666; */
  margin-top: 20px;
}

.add-address-form .buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.add-address-form .buttons .btn {
  height: 48px;
  font-size: 16px;
  margin: 0 8px;
  width: 50%;
}

.add-address-form .buttons .btn-default {
  color: #acacac;
}

/********** Add address page End *********/

/********** My Collection Options page Start *********/

/* .my-collection .top-rated-img-cont a {
    background: #000;
    color: #F0C787;
} */

/* .my-collection .card-body {
    color: #000;
} */

.my-collection .card {
  border: 0;
}

.my-collection .page-title h2 {
  margin-bottom: 6px;
}

.section-title.with-potions {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}

.section-title.with-potions .left a img {
  width: 16px;
}

.section-title.with-potions h3 {
  margin-bottom: 0;
  margin-right: 10px;
}

.section-title.with-potions .left a {
  position: relative;
  top: -3px;
}

.section-title.with-potions .left {
  display: flex;
  align-items: center;
}

.toggle-menus {
  position: relative;
  top: -3px;
}

.toggle-menus .dropdown-toggle::after,
.toggle-menus .dropdown-toggle::before {
  display: none;
}

.toggle-menus a.dropdown-toggle {
  width: 30px;
  display: block;
  text-align: right;
}

.toggle-menus .dropdown-menu {
  margin-right: -10px;
  border: 0;
  font-size: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  min-width: 140px;
}

.toggle-menus .dropdown-menu a {
  color: #838d78;
}

.toggle-menus .dropdown-menu .delete {
  color: #ff0000;
}

.info-section div {
  margin-right: 10px;
  font-size: 12px;
  font-family: 'sf_pro_displaymedium';
  color: #b25661;
}

.wine-categorires.wshare .top-rated-img-cont a {
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
}

.wine-categorires.wshare .top-rated-img-cont a img {
  width: 16px;
  height: 16px;
}

.badges {
  position: absolute;
  right: 0;
  top: 0;
}

.badges img {
  width: 100%;
}

.fixed-btn {
  position: fixed;
  left: 15px;
  right: 15px;
  bottom: 15px;
  z-index: 1;
}

/********** My Collection Options page End *********/

/********** Search Wines page Start *********/

.search-box {
  background: #f7f7f7;
  border-radius: 4px;
  padding: 8px 15px;
  margin-bottom: 15px;
}

.search-box input {
  width: calc(100% - 25px);
  border: 0;
  background: none;
  font-family: 'sf_pro_displayregular';
  font-size: 18px;
  color: #b25661;
}

.search-box .btn {
  padding: 0;
  background: none;
}

/********** Search Wines page End *********/

/********** Register Optional Filled Out page Start *********/

.progress {
  height: 4px;
  background: #cccccc;
}

.progress-bar {
  background: #f0c787;
}

/********** Register Optional Filled Out page Start *********/

.addmore-link {
  font-size: 14px;
  position: relative;
  top: 5px;
  cursor: pointer;
}

.common-prompt {
  background: #fff;
  padding: 15px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 400px;
  border-radius: 4px;
}
@media screen and (max-width: 769px) {
  .common-prompt {
    padding: 10px !important;
    width: 280px !important;
  }
}

.common-prompt .lb-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.common-prompt .lb-title a {
  font-size: 16px;
}

.common-prompt .lb-title h5 {
  font-size: 25px;
  font-family: 'new_york_extra_largemedium';
  margin-bottom: 0;
}

.common-prompt .btn {
  height: 48px;
  width: 100%;
  font-size: 14px;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
}

.common-prompt p {
  font-size: 14px;
}

.payout-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
}

/*** Refer a Sommelier ****/

.refer-sommlier-banner {
  height: 235px;
  background: url(./assets/invite.png) no-repeat;
  background-size: cover;
  margin-left: -15px;
  margin-right: -15px;
  padding: 15px;
  position: relative;
  background-position: center;
}

.refer-sommlier-banner p {
  font-size: 25px;
  font-family: 'new_york_extra_largemedium';
  line-height: 30px;
  color: #fff;
  position: absolute;
  bottom: 0;
}

/***My Collection Empty Start****/

.empty-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: calc(100vh - 165px);
  margin-top: 40px;
}

.empty-div h3 {
  margin-bottom: 3px;
  margin-top: 10px;
}

.empty-div p {
  color: #b25661;
  padding: 0 30px;
  line-height: 22px;
}

/***My Collection Empty End****/

/***Create New Collection Start****/

.wborder .lb-title {
  border-bottom: 1px solid #ddd;
  padding: 0 15px 10px 15px;
  margin-left: -15px;
  margin-right: -15px;
}

.txt-area .form-control {
  height: auto;
}

.two-btn .btn {
  width: 50%;
  height: 48px;
  margin: 0 8px !important;
}

.light-btn {
  color: #838d78;
}

.twolinecheck label.customcheckbox {
  font-size: 16px;
  font-family: 'new_york_extra_largemedium';
}

.twolinecheck span {
  font-size: 12px;
  font-family: 'sf_pro_displayregular';
  color: #b25661;
  display: block;
}

.cnc {
  font-size: 14px;
}

/***Create New Collection End****/

/***Filter Start****/

.tabs-section.vertical-tabs {
  overflow: hidden;
  margin-top: -15px;
  margin-bottom: -30px;
}

.tabs-section.vertical-tabs .tab-content {
  width: Calc(100% - 150px);
  padding-top: 11px;
}

.tabs-section.vertical-tabs .nav-tabs {
  border: 0;
  border-right: 1px solid #ddd;
  margin-right: 15px;
  width: 120px;
}

.tabs-section.vertical-tabs .nav-link {
  border: 0;
  padding: 11px 15px !important;
  transition: 0.3s;
  border-radius: 0;
}

.tabs-section .nav-link:hover {
  color: #b25661;
}

.tabs-section .nav-link.active {
  color: #b25661;
}

.tabs-section .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.tabs-section .details h3 {
  font-size: 26px;
  color: #000;
}

.tabs-section .details p {
  color: #000;
}

/***Filter End****/

/***Sommelier Profile Start****/

.sommelier-details-v1 .p-box .profile-box .left img {
  border-radius: 100px;
}

.sommelier-details-v1 .p-box .profile-box .right {
  width: 265px;
}

/***Sommelier Profile End****/

.create-post-main {
  height: calc(100% - 65px);
}

.create-post-main .row {
  height: 100%;
}

.create-post-main .two-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.create-post-main .wine-block {
  margin-bottom: 100px;
}

/* .create-post-main .two-btn .light-btn {
    color: #F0C787;
} */

.blog-icons {
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 15px 0;
  border-top: 1px solid #e8e8e8;
}

.blog-icons .icons-left li {
  display: block;
  /* color: #999999; */
  float: left;
  padding-right: 30px;
  font-size: 14px;
}

.blog-icons .icons-left li img {
  width: 20px;
  opacity: 0.25;
  margin-right: 10px;
}

.publish-date {
  /* color: #b2b2b2; */
  margin-bottom: 5px;
  font-size: 14px;
}

.stats-title {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: space-between;
}

.stats-title span {
  /* color: #999999; */
  font-family: 'new_york_extra_largeregular';
  font-size: 14px;
}

.custom-select-box-main {
  position: relative;
  font-size: 14px;
}

.custom-select-box-main select {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  color: #b25661;
}

.custom-select-box {
  padding-right: 15px;
}

.custom-select-box:after {
  content: '';
  position: absolute;
  right: 0;
  top: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #b25661;
}

.stats-div {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.stats-div .card {
  width: 100%;
}

.stats-div .card {
  width: 100%;
}

.stats-div .card:first-child {
  margin-right: 5px;
}

.stats-div .card:last-child {
  margin-left: 5px;
}

.stats-div .card span {
  font-size: 12px;
  color: #b25661;
  display: block;
  margin-bottom: 8px;
}

.stats-div .card b {
  font-size: 20px;
  /* color: #000; */
  font-weight: normal;
}

.stats-li {
  padding: 0;
  margin: 0;
}

.stats-li li {
  display: block;
  float: left;
  padding: 0 10px;
}

.stats-li li:first-child {
  padding-left: 0;
}

.stats-li li:last-child {
  padding-right: 0;
}

.stats-li li a img {
  width: 18px;
  opacity: 0.3;
}

.stats-li li:last-child a img {
  width: 14px;
}

.stats-li.icons {
  position: relative;
  left: -40px;
}

.stats-cont {
  display: flex;
  margin: 20px 0;
}

.stats-cont > div {
  width: 60%;
}

.stats-cont span {
  display: block;
  color: #b25661;
  font-size: 12px;
}

.stats-cont .wine-block {
  padding: 0;
  background: none;
}

.stats-cont .wine-block p {
  font-size: 12px;
}

.stats-cont .wine-block b {
  font-size: 14px;
  /* color: #000000; */
  font-weight: normal;
}

.stats-cont .wine-block-img {
  width: 70px;
  height: 70px;
}

.top-blog-title {
  /* color: #999999; */
  font-family: 'new_york_extra_largeregular';
  margin-bottom: 15px;
}

.top-blog .wine-block {
  background: none;
}

.top-blog .wine-block-img {
  width: 80px;
  height: 80px;
}

.top-blog .wine-block p {
  line-height: 16px;
  font-size: 14px;
}

.top-blog .blog-icons {
  border-top: 0;
  padding-top: 5px;
}

.top-blog .blog-icons .icons-left li {
  padding-right: 25px;
  color: #000000;
}

.top-blog .wine-block-cont-title {
  font-family: 'new_york_extra_largemedium';
}

.sommelier-details-v1 .p-box .follower-box .numbers h5 {
  font-family: 'new_york_extra_largeregular';
  font-size: 12px;
}

.two-btn.wfollowing {
  margin: 15px -8px -14px;
}

/* .two-btn.wfollowing .btn
 {
     color: #F0C787;
 }
.two-btn.wfollowing .btn.btn-primary {
    background: #000000;
    border: 0;
    
} */

.search_sommelier_filter .fiter .content ul li span .fa {
  left: 4px;
  top: 2px;
  position: relative;
}

.person-img-collection {
  border-radius: 100px;
}

.name-trnd-collection {
  position: relative;
  top: 2px;
}

.wine-tips-box .content .desc p {
  font-size: 15px;
  color: #b25661;
  opacity: 1;
}

.wine-tips-box .content .desc h4 {
  font-family: 'sf_pro_displaymedium';
  font-size: 17px;
}

.my-collection-sommeliers-details .winetips .header .left {
  width: auto;
}

.my-collection-sommeliers-details .winetips .header h2 {
  font-size: 20px;
}

.my-collection-sommeliers-details .winetips .header {
  position: relative;
  display: block;
  padding: 0 0 0 75px;
  margin-top: 25px;
}

.my-collection-sommeliers-details .winetips .header img {
  position: absolute;
  left: 0;
  top: -12px;
}

.my-collection-sommeliers-details .winetips .header p {
  margin-bottom: 0;
}

.img-container {
  border: 0px;
}

.winter-special-owl .img-container {
  border: 1px solid #d0d0c1;
  border-bottom: 0;
}

.cart-fixed {
  bottom: 0;
  z-index: 1;
}

.cntct-msg {
  text-align: center;
  font-size: 16px;
  /* color: #7F7F7F; */
  padding: 45px 0;
}

.top-rated-text-container h4 {
  padding-top: 0;
}

.common-page-title.with-des .page-title h2 {
  margin-bottom: 6px;
}

.name-trnd-collection {
  font-size: inherit;
  font-weight: initial;
}

.nav-link {
  line-height: inherit;
}

.tabs-section.vertical-tabs.search-sommelier .nav-tabs {
  width: 150px;
}

.tabs-section.vertical-tabs.search-sommelier .tab-content {
  width: Calc(100% - 190px);
}

.banner-bottom-items .item h4 {
  font-size: 16px;
  line-height: inherit;
  margin-bottom: 0;
}

.banner-bottom-items .item p {
  color: #b25661;
  font-size: 12px;
  opacity: 1;
  line-height: inherit;
}

.banner-bottom-items .item {
  margin-bottom: 0;
}

.sommelier_explore {
  padding: 0;
}

.follow-icon {
  position: absolute;
  height: 16px;
  width: 16px;
  right: 0;
  top: -12px;
}

.bell-span {
  background: #f0c787;
  color: #000;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 6px;
  left: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
}

.common-tabs #featured_top {
  margin: -10px -15px 15px;
}

.banner-bottom-items {
  padding: 0;
}

.sommelier_explore .profile .tab2 h4 {
  font-family: 'sf_pro_displaymedium';
  font-size: 16px;
  margin-bottom: 3px;
}

.sommelier_explore .profile .tab2 p {
  font-size: 12px;
  color: #b25661;
  opacity: 1;
}

.sommelier_explore .profile .tab2 a {
  color: #838d78;
}

.sommelier_explore .profile .tab3 h4 {
  font-size: 14px;
  opacity: 1;
  font-family: 'sf_pro_displayregular';
}

.sommelier_explore .profile .tab2 {
  width: 66vw;
}

.sommelier_explore .profile .tab3 h4 {
  color: #838d78;
}

.sommelier_explore .product-desc h4 {
  font-size: 16px;
  color: #838d78;
  font-family: 'sf_pro_displayregular';
}

.sommelier_explore .product-desc {
  padding: 15px 0;
}

.sommelier_explore .post h4 {
  font-family: 'sf_pro_displaymedium';
  font-size: 16px;
  margin-bottom: 5px;
}

.sommelier_explore .post p {
  color: #b25661;
  opacity: 1;
}

.search_sommelier .banner-bottom-items {
  padding-left: 15px;
  padding-right: 15px;
}

.sommelier-details-v1 .p-box .profile-box .right h4 {
  color: #838d78;
}

.sommelier-details-v1 .p-box .profile-box .right p {
  color: #b25661;
  opacity: 1;
}

.sommelier-details-v1 .p-box .btn-follow {
  background: #838d78;
  color: #ffffff;
}

.sommelier-details-v1 .about p {
  color: #b25661;
  opacity: 1;
}

.sommelier-details-v1 .tags .btn-light {
  color: #838d78;
}

.terroir-text-container {
  color: #b25661;
}

.terroir-text-container h4 {
  color: #b25661;
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
}

.sommelier .two-tab ul li a.active {
  border-color: #b25661;
}

.sommelier-details-content h2 {
  margin-bottom: 0;
  font-size: 20px;
}

.sommelier .two-tab ul {
  padding: 0;
}

.sommelier .two-tab ul li a {
  padding: 10px 15px;
}

.sommelier-details-content .padding-sides {
  padding: 15px;
}

.sommelier .two-tab ul li a {
  opacity: 1;
  color: #cdd1c9;
}

.sommelier .two-tab ul li a.active {
  color: #838d78;
}

.common-tabs.my-top-wine-tabs .nav-tabs .nav-link {
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
}

.common-tabs.my-top-wine-tabs .padding-sides {
  padding: 0;
}

.sommelier-details-v1 .about h4 {
  font-size: 20px;
}

.my-collection-sommeliers-details .header p {
  color: #b25661;
}

.my-collection-sommeliers-details .header-2 h4 {
  font-size: 20px;
}

.my-collection-sommeliers-details .header-2 span {
  color: #b25661;
}

.my-collection-sommeliers-details .header-2 span {
  font-size: 12px;
}

.winter-special-owl .owl-carousel .owl-nav button span {
  /* top: -3px; */
  top: -2px;
  left: 1px;
}

.my-collection-sommeliers-details.mt-3 .header-2 h4 {
  margin-bottom: 0;
}

.winter-special-owl .wine-categorires .top-rated-img-cont a,
.common-tabs.my-top-wine-tabs .wine-categorires .top-rated-img-cont a {
  font-size: 14px;
}

.product-sceen .description p {
  color: #b25661;
}

.product-sceen .heart {
  background: rgb(255, 255, 255, 0.2);
  width: 40px;
  height: 40px;
  font-size: 22px;
}

.product-sceen .description h2 {
  font-family: 'sf_pro_displayregular';
  font-size: 20px;
}

#size-list li a.active {
  background: #838d78 !important;
  color: #ffffff !important;
}

.tablerounededCorner h4 {
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
}

.tablerounededCorner .table.roundedTable tr:first-child td {
  border-top: 0;
}

.tablerounededCorner .table.roundedTable tr td:first-child {
  border-right: 1px solid #dee2e6;
}

.tablerounededCorner .table.roundedTable tr:last-child td h4 {
  color: #b25661;
}

.tablerounededCorner .table {
  background: #fafafa;
}

.product-sceen .tasting-note h4 {
  color: #b25661;
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
}

.tasting-note p {
  margin-bottom: 10px;
  color: #838d78;
}

.tablerounededCorner p,
.peragraph-p {
  color: #838d78;
}

.recmmended-product {
  background: #838d78;
  color: #fff;
}

.product-sceen .size {
  padding-left: 0;
  padding-right: 0;
}

.product-sceen .size .value-button {
  position: relative;
  background: #e8e8e8;
  border: 0;
}

.value-button.icon-minus:before {
  content: '';
  height: 2px;
  width: 12px;
  background: #ffffff;
  position: absolute;
  top: 11px;
  left: 6px;
}

.value-button.icon-plus:before {
  content: '';
  height: 1px;
  width: 11px;
  background: #ffffff;
  position: absolute;
  top: 12px;
  left: 8px;
}

.value-button.icon-plus:after {
  content: '';
  height: 11px;
  width: 1px;
  background: #ffffff;
  position: absolute;
  left: 12px;
  top: 8px;
}

.product-sceen .size form #increase {
  background: #838d78;
  border: 0;
}

.add-cart-btn,
.go_to_cart {
  background: #838d78;
  color: #ffffff;
  margin-top: 3px;
}

.tabs-section.vertical-tabs .checkActive {
  color: #b25661;
}

.sommelier .banner-bottom-items {
  padding: 15px;
}

.search_sommelier_filter .fiter .header a {
  color: #838d78;
}

.product-recommended .flex .right h4 {
  font-family: 'sf_pro_displaymedium';
}

.blog-post-unique {
  padding-bottom: 70px;
}

.datepicker-dropdown,
.liked-wine-title {
  color: #838d78;
}

.liked-wine-price h5 {
  font-size: 14px;
  font-family: 'sf_pro_displayregular';
  padding-top: 5px;
}

.datepicker .datepicker-switch,
.datepicker tfoot tr th.clear {
  color: #b25661;
}

.explore-wine-page {
  padding-bottom: 70px;
}

.add-cart-black {
  background: #838d78;
  font-family: 'sf_pro_displayregular';
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
}

.top-rated-text-container h4 {
  font-family: 'sf_pro_displayregular';
  font-size: 16px;
}

.top-rated-desc {
  font-size: 12px;
  margin-top: 2px;
  color: #b25661;
}

.my-collection-options-page {
  padding-bottom: 70px;
}

.create-new-collection-page {
  padding-bottom: 70px;
}

.winter-special-owl .owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  background: #838d78;
  color: #ffffff;
}

#main ul.nav .logo .nav-link {
  padding: 0;
}

.product-recommended .flex .right p {
  color: #b25661;
  opacity: 1;
}

.banner-bottom-items .item img {
  margin-bottom: 0;
}

.sommeliers-details-banner {
  margin: 0 -15px;
}

.product-recommended .flex .right span {
  position: relative;
  top: -2px;
}

.delivery-address-area {
  display: none;
}

.sidenav-brand a {
  padding: 0;
}

.wine-tips-box {
  padding-left: 0;
  padding-right: 0;
}

.winter-special-owl .owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  right: -12px;
  position: relative;
}

.mr-minus {
  margin-right: -15px;
}

.rmvpr .owl-stage-outer {
  margin-right: -15px;
}

#homeAddress,
#officeAddress {
  margin-bottom: 10px;
}

#homeAddress:read-only,
#officeAddress:read-only {
  border: 0;
  background: #ffffff;
  padding-left: 0;
}

.saveOne,
.saveTwo {
  display: none;
}

.nav-user-icon {
  // display: none;
}

footer {
  background: #000;
  // padding: 50px 0;
  text-align: center;
  margin-top: 4vh;
  display: block;
}

.collectin-pic .user-date-div .user-img {
  border-radius: 0;
  height: 25px;
  width: 23px;
}

.collectin-pic .user-date-div .user-date img {
  height: 13px;
  width: 14px;
}

.desktop-page-title {
  display: none;
}

.pcol-xs-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.ppr-2 {
  padding-right: 8px;
}

.ppl-2 {
  padding-left: 8px;
}

.get-started-box .close-btn {
  display: none;
}

.desktop-select-main {
  display: none;
}

/****************************** Desktop Css ******************************/

.home-hero-slider .owl-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.home-hero-slider.owl-theme .owl-dots .owl-dot span {
  width: 5px;
  height: 5px;
  background: #787878;
  position: relative;
  bottom: 5px;
  margin: 5px 3px;
}

.home-hero-slider.owl-theme .owl-dots .owl-dot.active span {
  background: #ffffff;
  width: 8px;
  height: 8px;
  position: relative;
  top: -4px;
}

.desktop-search-bar-main {
  display: flex;
  justify-content: right;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.desktop-search-bar {
  height: 58px;
  width: 430px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.desktop-search-bar img {
  width: 20px;
}

.desktop-search-bar input[type='text'] {
  width: calc(100% - 18px);
  // margin-left: 25px;
  border: 0;
  font-size: 20px;
  font-family: 'sf_pro_displaymedium';
  background: transparent;
}

.desktop-search-bar input[type='text']::placeholder {
  color: #707070 !important;
  opacity: 1;
}

@media (min-width: 992px) {
  #main ul.nav.nav-menu {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 991px) {
  ::placeholder {
    color: #949494;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #949494;
    opacity: 1;
  }
  ::placeholder {
    color: #949494;
    opacity: 1;
  }
  input[type='email'].form-control::placeholder,
  .form-control::placeholder,
  .form-control::focus,
  textarea::placeholder {
    color: #949494 !important;
    opacity: 1;
  }
  .ppr-2 {
    padding-right: 15px;
  }
  .ppl-2 {
    padding-left: 15px;
  }
  .form-control::placeholder {
    color: #949494 !important;
    opacity: 1;
  }
  .hamburger-icon {
    display: none;
  }
  .desktop-search-bar-main {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
  #main ul.nav.nav-menu {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 720px;
    // margin: 25px auto;
  }
  #main ul.nav.nav-menu .nav-link {
    padding-left: 16px;
    padding-right: 16px;
    color: #000;
    font-size: 14px;
    letter-spacing: 0.75px;
  }
  .nav-user-icon {
    display: block;
  }
  #main ul.nav .nav-item.text-link {
    // padding-left: 20px;
    // padding-right: 20px;
  }
  #main ul.nav li.logo a.nav-link {
    padding-left: 0;
  }
  #main ul.nav li.logo a.nav-link img {
    width: auto;
    height: 50px;
    object-fit: contain;
  }
  #main ul.nav li:nth-last-child(2) a.nav-link {
    padding-right: 0;
  }
  #main ul.nav .nav-link img {
    width: 24px;
    height: 24px;
  }
  #main ul.nav .notification-icon .nav-link img {
    width: 21px;
  }
  .experts-div .card-bottom p {
    font-size: 15px;
  }
  .trending-collection-inner {
    height: 140px;
  }
  // .explore-top-sommelier {
  //   margin-top: 92px !important;
  // }
  // .trending-collection {
  //   margin-top: 73px !important;
  // }
  .ct-right span {
    font-size: 20px;
  }
  .trending-collection-inner .ct-content h4 {
    font-size: 23px;
    font-family: 'new_york_extra_largeregular';
  }
  .trending-collection-inner > img {
    height: 140px;
  }
  .wine-categorires .card-body h5 {
    font-size: 18px;
  }
  .wine-categorires .card-body h5:last-child {
    font-size: 16px;
  }
  .explore-wines .box-title {
    font-size: 21px;
  }
  .top-rated-main {
    margin-top: 50px !important;
  }
  .explore-wines {
    margin-top: 60px !important;
  }
  .wine-tips .section-title span {
    font-size: 20px;
  }
  .explore-sommelier .owl-nav,
  .top-rated .owl-nav,
  .wine-mood .owl-nav {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
  }
  .explore-sommelier .owl-nav .owl-next,
  .top-rated .owl-nav .owl-next,
  .wine-mood .owl-nav .owl-next {
    position: absolute;
    right: -5px;
    background: #ffffff !important;
    height: 110px !important;
    font-size: 70px !important;
    color: #000 !important;
    width: 60px;
    border-radius: 100px 0 0 100px !important;
    box-shadow: -3px 0 5px rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .experts-div .card {
    padding: 0 0 10px 5px;
  }
  .explore-sommelier .owl-nav .owl-next i.fa,
  .top-rated .owl-nav .owl-next i.fa,
  .wine-mood .owl-nav .owl-next i.fa {
    position: relative;
    left: 10px;
  }
  .explore-sommelier .owl-nav .owl-prev,
  .top-rated .owl-nav .owl-prev,
  .wine-mood .owl-nav .owl-prev,
  .wine-tips .owl-nav .owl-prev {
    display: none !important;
  }
  .wine-tips .owl-nav {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
  }
  .wine-tips .owl-nav .owl-next {
    position: absolute;
    right: 10px;
    background: #000 !important;
    height: 40px !important;
    font-size: 55px !important;
    color: #f0c787 !important;
    width: 40px;
    border-radius: 100px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wine-tips .owl-nav .owl-next span {
    top: -6px;
    position: relative;
    left: 1px;
  }
  .wine-tips .owl-nav .owl-next i.fa {
    position: relative;
    left: 10px;
  }
  .top-rated .owl-nav {
    top: 80px;
    display: block !important;
  }
  .wine-mood .owl-nav {
    top: 35px;
    display: block !important;
  }
  .wine-tips .owl-nav {
    top: -110px;
    /* display: block !important; */
  }
  .wine-categorires .top-rated-img-cont a {
    font-size: 18px;
    padding: 11px 8px;
  }
  .wine-categorires .card-body .cart-title a.btn {
    height: 26px;
    width: 26px;
    font-size: 25px;
  }
  .wine-tips {
    margin-top: 80px !important;
  }
  .wine-tips .blog-card h5.card-title {
    font-size: 20px;
  }
  .blog-card p.card-text {
    line-height: 22px;
    font-size: 18px;
  }
  .wine-categorires .card-body span {
    font-size: 14px;
  }
  .blog-card .user-date-div .user {
    font-size: 17px;
  }
  .blog-card .user-date {
    font-size: 14px;
  }
  .user-img {
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
  html,
  body,
  div#main {
    height: auto;
  }
  body {
    padding-bottom: 0;
  }
  footer {
    background: #000;
    // padding: 50px 0;
    text-align: center;
    margin-top: 4vh;
    display: block;
  }
  .footer-social {
    margin: 20px 0;
  }
  .footer-social li {
    display: inline-block;
    padding: 0 20px;
  }
  .footer-social li a {
    color: #ffffff;
    font-size: 28px;
  }
  .footer-links {
    margin: 10px 0;
    padding: 0;
  }
  .footer-links li {
    display: inline-block;
    padding: 10px 18px;
  }
  .footer-links li a {
    color: #ffffff;
    font-size: 20px;
  }
  .copy-right {
    font-size: 15px;
    color: #ffffff;
  }
  .intro {
    font-size: 26px;
    padding: 50px 0;
    line-height: 35px;
  }
  .collectin-pic .user-date-div {
    font-size: 26px;
    margin-bottom: 30px;
  }
  .collectin-pic .user-date-div .user-img {
    border-radius: 0;
    height: 50px;
    width: 43px;
  }
  .collectin-pic .user-date-div .user-date img {
    height: 22px;
    width: 26px;
  }
  .collectin-pic .col-xs-6:nth-child(even) {
    padding-right: 15px;
  }
  .collectin-pic .col-xs-6:nth-child(odd) {
    padding-left: 15px;
  }
  .sommelier_explore .col-xs-6:nth-child(even) {
    padding-left: 15px;
  }
  .sommelier_explore .col-xs-6:nth-child(odd) {
    padding-right: 15px;
  }
  /* .nav-link {
        padding:0 18px !important;
    } */
  .sommelier_explore .profile .tab1 {
    width: 50px;
    margin-right: 20px;
  }
  .sommelier_explore .profile .tab2 h4 {
    font-size: 26px;
  }
  .sommelier_explore .profile .tab2 p {
    font-size: 20px;
  }
  .sommelier_explore .profile {
    justify-content: start;
    align-items: center;
    position: relative;
    margin-top: 35px;
    margin-bottom: 15px;
  }
  .sommelier_explore .profile .tab3 {
    position: absolute;
    right: 0;
  }
  .sommelier_explore .profile .tab3 h4 {
    font-size: 24px;
  }
  .common-tabs.blog-tabs .nav-link {
    font-size: 25px;
  }
  .common-tabs .nav-tabs {
    margin-left: 0;
    margin-right: 0;
  }
  .desktop-page-title h1 {
    font-size: 32px;
    margin-top: 45px;
    margin-bottom: 44px;
    font-weight: bold;
    color: #838d78;
  }
  .sommelier_explore .product-desc h4 {
    font-size: 24px;
  }
  .sommelier_explore .product-desc {
    padding: 15px 0 35px;
  }
  .desktop-page-title {
    display: block;
  }
  .sommelier_explore .post h4 {
    font-size: 24px;
    padding: 0 30px;
    margin-top: 25px;
  }
  .sommelier_explore .post p {
    font-size: 20px;
    padding: 0 30px;
    margin-bottom: 25px;
  }
  .sommelier_explore .post {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 15px 15px 0 0;
    overflow: hidden;
  }
  .sommelier_explore .post img {
    border: 0;
    margin: 0;
  }
  .common-tabs #featured_top {
    margin: 25px 0 50px;
  }
  .banner-bottom-items .item p {
    font-size: 14px;
    margin-top: 10px;
  }
  .banner-bottom-items .item {
    margin-bottom: 25px;
  }
  .follow-icon {
    position: absolute;
    height: 26px;
    width: 26px;
  }
  .follow-icon img {
    width: 26px;
  }
  .blog-card {
    margin-top: 15px;
  }
  .common-tabs .nav-tabs {
    margin-left: 0;
    margin-right: 0;
  }
  .page-title h2 {
    font-size: 32px;
    margin-top: 40px;
    margin-bottom: 32px;
    font-weight: bold;
    color: #838d78;
  }
  .ordr_hsty-card .oh-date-div,
  .ordr_hsty-card .card-title h5 {
    font-size: 20px;
  }
  .ordr_hsty-card .card-title .item-price {
    font-size: 24px;
    margin-top: 20px;
  }
  .ordr_hsty-card .card-body {
    padding: 25px;
  }
  .ordr_hsty-card .oh-date-div {
    margin-bottom: 20px;
  }
  .ordr_hsty-card .card-title a.btn {
    height: 40px;
    width: 120px;
    font-size: 18px;
  }
  .ordr_hsty-card .cart-items .item-img {
    width: 110px;
    height: 110px;
  }
  .btn {
    font-size: 20px;
  }
  .ordr_hsty-card .cart-buttons {
    margin-top: 20px;
  }
  .address-card .card-title h5 {
    font-size: 24px;
  }
  .address-card .card-title a {
    font-size: 20px;
  }
  #homeAddress,
  #officeAddress {
    font-size: 24px;
  }
  .address-card .btn.btn-default,
  .address-card .btn.btn-primary {
    height: 48px;
    font-size: 24px;
  }
  .address-main,
  .address-main .row {
    height: auto;
  }
  .my-addess-btn {
    position: relative;
    max-width: 625px;
    margin: 0 auto;
    top: -45px;
    bottom: 0;
  }
  .my-addess-btn .btn {
    height: 80px;
    width: 100%;
    font-size: 24px;
  }
  .profile-pic div {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .profile-img {
    width: 100px;
    height: 100px;
  }
  .common-form label {
    font-size: 20px;
  }
  .common-form button.btn-primary,
  .common-form .form-control {
    height: 48px;
  }
  .common-form .form-control {
    font-size: 20px;
    height: 56px;
  }
  .height-inherit {
    height: inherit !important;
  }
  .form-group {
    margin-bottom: 16px;
  }
  .profile-div .profile-pic h2 {
    font-size: 32px;
  }
  .profile-div .profile-pic span {
    font-size: 20px;
  }
  .profile-pic div {
    max-width: 350px;
  }
  .get-started-box {
    padding: 40px 50px;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 10px;
    top: 50%;
    bottom: auto;
    margin-top: -200px;
  }
  .get-started-box .lb-title h5 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  .get-started-box .lb-title {
    display: block;
    text-align: center;
  }
  .get-started-box .lb-title a,
  .desktop-or {
    font-size: 20px;
  }
  .get-started-box .btn {
    height: 70px;
    font-size: 20px;
    border-radius: 10px;
  }
  // .get-started-box .btn img {
  //   display: none;
  // }
  .get-started-box .guest-checkout-btn a.btn.btn-primary {
    color: #ffffff;
  }
  .get-started-box .close-btn {
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background: #000000;
    position: absolute;
    right: -20px;
    top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .get-started-box .close-btn img {
    width: 12px;
  }

  .subscribe-popup .close-btn {
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background: #000000;
    position: absolute;
    right: -20px;
    top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .subscribe-popup .close-btn img {
    width: 12px;
  }
  .cart-buttons .btn.btn-primary {
    font-size: 14px;
  }
  .blog-card-inside h5.card-title {
    font-size: 36px;
  }
  .blog-card-inside p.card-text {
    font-size: 26px;
    line-height: 34px;
  }
  .wine-block-cont-title {
    font-size: 20px;
  }
  .wine-block-cont h5 {
    font-size: 18px;
  }
  .wine-block-img {
    width: 110px;
    height: 110px;
  }
  .blog-card-inside-banner {
    margin-bottom: 40px;
    height: auto;
    margin-left: 0;
    margin-right: 0;
  }
  .sommelier-details-v1 .p-box .bg {
    height: 290px;
    margin: 0;
    padding: 0;
  }
  .sommelier-details-v1 .p-box .profile-box .left {
    width: 200px;
    height: 200px;
    float: left;
  }
  .sommelier-details-v1 .p-box .profile-box .left img {
    //   width: auto;
    height: auto;
  }
  .sommelier-details-v1 .p-box .profile-box .right h4 {
    font-size: 36px;
  }
  .sommelier-details-v1 .p-box .profile-box .right p {
    font-size: 20px;
  }
  .sommelier-details-v1 .p-box .profile-box .right {
    padding-top: 94px;
    width: 700px;
    padding-left: 25px;
    float: left;
  }

  .sommelier-details-v1 .p-box .btn-follow {
    font-size: 30px;
    width: 100%;
    display: block;
    padding: 17px;
    text-align: center;
    margin-top: 0;
    border-radius: 10px;
    height: 80px;
    float: right;
    margin-top: -18px;
  }
  .sommelier-details-v1 .p-box .follower-box {
    box-shadow: none;
    border: 0;
    padding-left: 0;
  }
  .follwor-box-main {
    margin-top: 115px;
  }
  .sommelier-details-v1 .p-box .follower-box .numbers h5,
  .sommelier-details-v1 .p-box .follower-box .numbers h4 {
    font-size: 20px;
  }
  .sommelier-details-v1 .p-box .follower-box .numbers {
    padding: 0;
  }
  .sommelier-details-v1 .about h4 {
    font-size: 36px;
  }
  .sommelier-details-v1 .about p {
    font-size: 20px;
    line-height: 50px;
    margin-bottom: 40px;
  }
  .sommelier-details-v1 .about {
    padding-top: 80px;
  }
  .sommelier-details-v1 .tags .btn-light {
    font-size: 20px;
    border-radius: 8px;
    height: 85px;
    width: 100%;
    text-align: left;
    padding: 0 20px;
    white-space: normal;
    padding-left: 70px;
    position: relative;
  }
  .sommelier-details-v1 .tags {
    padding: 0;
  }
  .sommelier-details-v1 .tags .btn.btn-light img {
    margin-right: 10px;
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -12px;
  }
  .sommelier-details-v1 .tags .btn.btn-light.location img {
    width: 20px;
  }
  .sommelier-details-v1 .tags .btn.btn-light.university img {
    width: 36px;
    margin-top: -8px;
  }
  .sommelier-details-v1 .tags .btn.btn-light.medal img {
    width: 33px;
  }
  .sommelier-details-v1 .tags .btn-light.location {
    padding-left: 55px;
  }
  .sommelier-details-v1 .social {
    padding: 0;
    padding-top: 50px;
  }
  .sommelier-details-v1 .social .social-icons img {
    width: 54px;
  }
  .sommelier-details-v1 .social .social-icons {
    padding-right: 60px;
    display: flex;
    align-items: center;
  }
  .sommelier-details-content h2 {
    margin-bottom: 15px;
    font-size: 36px;
  }
  .sommelier-details-content .explore-wines {
    margin-bottom: 70px !important;
  }
  .common-tabs.my-top-wine-tabs .nav-tabs .nav-link {
    font-size: 20px;
  }
  .common-tabs .nav-tabs {
    margin-bottom: 40px;
  }
  .winter-special-owl .wine-categorires .top-rated-img-cont a,
  .common-tabs.my-top-wine-tabs .wine-categorires .top-rated-img-cont a {
    font-size: 18px;
  }
  .my-collection-sommeliers-details .header h2 {
    font-size: 36px;
  }
  .my-collection-sommeliers-details .header p {
    font-size: 20px;
  }
  .my-collection-sommeliers-details .header .left {
    width: 30px;
    margin-right: 30px;
  }
  .my-collection-sommeliers-details .header {
    padding: 0;
    padding-top: 80px;
  }
  .my-collection-sommeliers-details .header-2 {
    padding: 0;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .my-collection-sommeliers-details .header-2 h4 {
    font-size: 32px;
  }
  .my-collection-sommeliers-details .header-2 span {
    font-size: 20px;
  }
  .my-collection-sommeliers-details .header-2 span img {
    width: 30px;
    margin-right: 15px;
  }
  .my-collection-sommeliers-details.mt-3 .header-2 h4 {
    margin-bottom: 10px;
  }
  .sommelier-details-content .padding-sides {
    padding: 0px;
  }
  .sommeliers-details-banner {
    margin-top: 45px;
  }
  .winter-special-owl .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
  }
  .winter-special-owl .owl-theme .owl-nav {
    display: block;
    left: 0;
    top: -126px;
  }
  .winter-special-owl .owl-carousel .owl-nav button span {
    top: -5px;
    font-size: 45px !important;
  }
}

@media (min-width: 992px) {
  .collectin-pic .col-xs-6,
  .sommelier_explore .col-xs-6,
  .banner-bottom-items .col-xs-6 {
    max-width: 25%;
  }
  #main ul.nav.nav-menu {
    max-width: 1140px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  #main ul.nav.nav-menu .nav-link {
    padding-left: 16px;
    padding-right: 16px;
    letter-spacing: 1px;
  }
  #main ul.nav .nav-link img {
    width: 24px;
    height: 24px;
  }
  #main ul.nav .notification-icon .nav-link img {
    width: 23px;
  }
  .desktop-search-bar-main {
    max-width: 1140px;
  }
  .profile-tabs li {
    width: 50%;
    float: left;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 58px;
  }
  .profile-tabs li a {
    box-shadow: 0 0 6px rgb(0, 0, 0, 0.2);
    border-radius: 8px;
    font-size: 24px;
    height: 80px;
    padding: 0 30px;
  }
  .profile-tabs a:after {
    padding: 5px;
    right: 30px;
    margin-top: -5px;
    top: 50%;
  }
  .profile-tabs span {
    background: none;
    height: 30px;
    width: 30px;
    padding: 0;
  }
  .cart-card .card-body {
    padding: 25px 30px;
  }
  .cart-buttons button {
    max-width: 100%;
    width: 100%;
    height: 60px;
  }
  .cart-buttons {
    margin-top: 0;
  }
  .cart-card .card {
    margin-top: 0 !important;
    margin-bottom: 30px;
  }
  .cart-card .delivery-address a {
    font-size: 20px;
  }
  .desktop-cart-left.cart-card .card-body {
    padding: 0;
  }
  .desktop-cart-left.cart-card .card {
    box-shadow: none !important;
  }
  .cart-card .card-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .prices div,
  .gtotal div,
  .delivery-address-cont {
    font-size: 20px;
  }
  .item-img {
    height: 150px;
    width: 160px;
    border-radius: 8px;
    margin-right: 15px;
  }
  .item-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .item-content span {
    font-size: 20px;
  }
  .item-price {
    position: absolute;
    left: 174px;
    bottom: 0;
    top: auto;
    font-size: 24px;
  }
  .desktop-cart-left {
    border-right: 1px solid #e5e5e5;
    height: 100%;
  }
  .desktop-select {
    background: #000000;
    height: 50px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  .desktop-select select {
    background: #000000;
    color: #fff;
    border: 0;
    font-size: 20px;
  }
  .desktop-select span {
    color: #fff;
  }
  .item-title-sub {
    font-size: 20px;
  }
  .desktop-select-main {
    margin-top: 10px;
    display: flex;
  }
  .cart-buttons .btn.btn-primary {
    font-size: 20px;
  }
  .sommelier-details-v1 .p-box .follower-box {
    padding-left: 270px;
  }
  .sommelier-details-v1 .p-box .btn-follow {
    width: 240px;
  }
}

.DynamicForm-module_alignItems__UlYHn {
  background-color: transparent !important;
}
#dynamicForm h2 {
  color: #000 !important;
}

.text-popup-color {
  color: #b25661;
}

// @media (min-width: 1400px) {
//   .container,
//   #main ul.nav.nav-menu,
//   .desktop-search-bar-main {
//     max-width: 1280px;
//   }
//   .experts-div h5 {
//     font-size: 20px;
//   }
//   .trending-collection-inner .ct-content h4 {
//     font-size: 32px;
//   }
//   .wine-tips .blog-card h5.card-title {
//     font-size: 22px;
//   }
//   .footer-links li {
//     padding: 0 35px;
//   }
//   #main ul.nav.nav-menu .nav-link {
//     padding-left: 10px;
//     padding-right: 10px;
//   }
//   .blog-card h5.card-title {
//     font-size: 20px;
//   }
//   .blog-card .user-date-div .user {
//     font-size: 18px;
//   }
//   .blog-card .user-date {
//     font-size: 16px;
//   }
//   .desktop-select {
//     margin-right: 30px;
//   }
//   .item-img {
//     height: 200px;
//     width: 200px;
//     border-radius: 8px;
//     margin-right: 30px;
//   }
//   .desktop-select {
//     background: #000000;
//     height: 50px;
//     padding: 8px 20px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-right: 15px;
//   }
//   .item-title {
//     font-size: 24px;
//     margin-bottom: 20px;
//   }
//   .desktop-select-main {
//     margin-top: 20px;
//     display: flex;
//   }
//   .item-price {
//     position: absolute;
//     left: 229px;
//     bottom: 0;
//     top: auto;
//     font-size: 24px;
//   }
//   .sommelier-details-v1 .p-box .follower-box {
//     padding-left: 290px;
//   }
// }

// @media (max-width: 991px) {
//   #main ul.nav .nav-item.text-link {
//     padding-left: 0;
//     padding-right: 0;
//   }
//   /*  */
//   .wine-mood .owl-nav {
//     top: 30px;
//   }
// }

// @media (max-width: 1199px) {
//   .explore-sommelier .owl-nav {
//     top: 38px;
//   }
//   .top-rated .owl-nav {
//     top: 60px;
//   }
//   .wine-mood .owl-nav {
//     top: 50%;
//   }
// }

.subscribe-popup .close-btn {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background: #000000;
  position: absolute;
  right: -20px;
  top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.subscribe-popup .close-btn img {
  width: 12px;
}
