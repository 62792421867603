.card_address {
    --elevation: 1;
    --epx: calc(var(--elevation) * 1px);
    
    /* these 2 shadows serve as a border for  0-1 elevation    */
    --shadow1: 0 0 1px rgba(135, 72, 72, 0.1);
    --shadow2: 0 1px 2px rgba(149, 73, 73, 0.08);
    
    --offset-y: calc(var(--epx) + 1px);
    --blur: calc(var(--epx) * 2);
    --spread: calc(var(--epx) * .3);
    --shadow3: 
        0 var(--offset-y) 
        var(--blur) 
        var(--spread) 
        rgba(126, 63, 63, 0.2);
    
    box-shadow: 
      var(--shadow1),
      var(--shadow2),
      var(--shadow3);

  }
  
   .card_address {
    background: white;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
  }

  .address_font_size {
    font-size: 14px !important;
    color: #555555 !important;
    font-weight: 400;
  }
  .address_font_size_tilte{
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 500;
  }
  
  .cart__title_add_address{
    background: #f0c787;     
color: black !important;    
padding: 8px 6px;   
border-radius: 
2px
;    
font-size: 14px !important;
font-weight: 500;
cursor: pointer;


  }


  /* Define the CSS class for the fade-in and fade-out animation */
.fade-in-out {
  animation: fade 2s ease-in-out forwards;
  /* You can adjust the duration (2s) and timing function (ease-in-out) as needed */
  opacity: 0;
}

/* Keyframes for the fade animation */
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
