.footer .marginSpace {
  margin: 15px 0px;
  font-family: sf_pro_text_light !important;
}

.footer .logoImg {
  margin-top: 15px;
}
@media (max-width: 786px) {
  .mobile .social ul {
    padding: 0px;
    list-style: none;
    display: inline-flex;
  }
  .mobile ul {
    padding: 0px;
    list-style: none;
  }
  .mobile li {
    margin: 5px 15px;
  }
}
