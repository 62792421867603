/********** View Collection CSS ************/

.viewCollection {
  padding: 0px;
}

.viewCollection .title {
  color: #000;
  font-size: 36px;
  /* margin: 20px 0px; */
}

.viewCollection .subTitle {
  font-family: 'sf_pro_displayregular';
  color: #000;
  opacity: 0.4;
  font-size: 20px;
  /* margin: 0px 0px 60px 0px; */
}

.shareBtn {
  margin: auto;
  margin-top: 40px;
  width: 50%;
  background-color: #838d78;
  padding: 16px;
  /* height: 80px; */
  /* width: 300px; */
  text-align: center;
  border-radius: 4px;
}

.shareBtn .text {
  font-family: 'sf_pro_displayregular';
  font-size: 24;
  color: #fff;
}
.userDiv{
  cursor: pointer;
}
.userDiv img{
  width: 40px !important;
  height:  40px !important;
  border-radius: 20px !important;
  margin: auto;
}

.userDiv h5{
  font-family: 'sf_pro_displayregular' !important;
}

.likedWines {
  font-family: 'sf_pro_displayregular' !important;
}

@media screen and (max-width: 700px) {
  .shareBtn {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .userDiv img{
    width: 20px !important;
    height:  20px !important;
    border-radius: 10px !important;
  }
}
