.container-list-main {
  display: flex;
  width: 100%;
  align-items: center;
  // padding: 8px 0px;
  .list-container {
    padding-left: 2px;
    display: flex;
    align-items: center;
    list-style: none;
    max-width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      background: transparent; /* make scrollbar transparent */
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }
    .list-item {
      border-radius: 4px;
      white-space: nowrap;
      margin-right: 20px;
    }
    .list-item:last-child {
      margin-right: 0px;
    }
  }

  button {
    border: 0;
    color: #777;
    font-size: 24px;
    margin: 0 8px;
    cursor: pointer;
  }
  .__arrow__next {
    z-index: 10 !important;
    position: absolute;
    right: 7px !important;
    background: #ffffff !important;
    height: 80px !important;
    color: #000 !important;
    width: 40px;
    border-radius: 100px 0 0 100px !important;
    box-shadow: 0px 0px 6px #00000029;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .__arrow__prev {
    z-index: 10 !important;
    position: absolute;
    left: 7px !important;
    background: #ffffff !important;
    height: 80px !important;
    color: #000 !important;
    width: 40px;
    border-radius: 0 100px 100px 0px !important;
    box-shadow: 0px 0px 6px #00000029;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 480px) {
  /* STYLES HERE for BROWSER WINDOWS with a max-width of 480px. 
     This will work on desktops when the window is narrowed.  */
  .container-list-main {
    button {
      display: none !important;
    }
    .__arrow__next,
    .__arrow__prev {
      display: none !important;
    }
  }
}
