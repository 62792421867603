@mixin card-wine-by-moment-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin card-wine-by-moment-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin card-wine-by-moment-md {
  @media screen and (max-width: 1399px) {
    @content;
  }
}
@mixin card-wine-by-moment-lg {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

@mixin card-img-sx {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin card-img-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
@mixin card-img-md {
  @media screen and (max-width: 1399px) {
    @content;
  }
}
@mixin card-img-lg {
  @media screen and (min-width: 1400px) {
    @content;
  }
}
.card-wine-by-moment {
  @include card-wine-by-moment-lg {
    width: 240px !important;
  }
  @include card-wine-by-moment-md {
    width: 240px !important;
  }
  @include card-wine-by-moment-sm {
    width: 240px !important;
  }

  @include card-wine-by-moment-sx {
    width: 220px !important;
  }
}

.card-wine-by-moment-skeleton {
  @include card-wine-by-moment-lg {
    width: 240px !important;
  }
  @include card-wine-by-moment-md {
    width: 240px !important;
  }
  @include card-wine-by-moment-sm {
    width: 240px !important;
  }

  @include card-wine-by-moment-sx {
    width: 220px !important;
  }
}

.card-wine-by-moment {
  .icon {
    @include card-img-lg {
      width: 240px !important;
      height: 184px !important;
      border-radius: 8px;
    }
    @include card-img-md {
      width: 240px !important;
      height: 184px !important;
      border-radius: 8px;
    }
    @include card-img-sm {
      width: 240px !important;
      height: 180px !important;
      border-radius: 8px;
    }
    @include card-img-sx {
      width: 220px !important;
      height: 160px !important;
      border-radius: 8px;
    }
  }
}
